
import { FaSort } from "react-icons/fa";
import useTopProgramList from "./useTopProgramList";
import ProgramDetails from "./ProgramDetails";
import Style from "../../HomeDashboard/HomeDashboardListing/dashboardListing.module.scss";
import { Table } from "../Table";
import { OffCanvasLayout } from "./OffCanvasLayout";

const TopProgramList = () => {
  const {
    isFetching,
    handleSort,
    getRow,
    tableFieldsProgram,
    filteredData,
    showDetailModal,
    programDetailData,
    refetch,
    closeModal,
    setActiveTab,
    isSelectedAll,
    isSelectedVideos,
    isSelectedPodcast,
    programDetailsData
  } = useTopProgramList();

  

  return (
    programDetailsData?.data &&
    <>
      <div className="pro-pb-3  no-tb-edit table-tab-wrap">
      <div className="pro-d-flex pro-bg-white pro-items-center pro-justify-between pro-gap-2 pro-p-3 table-tab-title-wrap">
          <div className="pro-d-flex pro-gap-5 pro-mb-2 pro-items-center ">
            <h6 className="pro-mb-0 h4 pro-ttl">Top Programs</h6>
          </div>
          <div className="pro-d-flex pro-gap-2 pro-justify-between pro-items-center">
            <ul className="pro-d-flex pro-gap-5 pro-mb-3">
              <li
                className={`${Style.tab_btn} ${isSelectedAll && Style.active}`}
                onClick={() => {
                  setActiveTab("All")
                }}
              >
                All
              </li>
              <li
                className={`${Style.tab_btn} ${isSelectedVideos && Style.active}`}
                onClick={() => {
                  setActiveTab("Video")
                }}
              >
                Videos
              </li>
              <li
                className={`${Style.tab_btn} ${isSelectedPodcast && Style.active}`}
                onClick={() => {
                  setActiveTab("Podcast")
                }}
              >
                Podcasts
              </li>
            </ul>
          </div>
        </div>

        <Table
          data={filteredData || []}
          uniqueID={"_id"}
          fields={tableFieldsProgram}
          SortIcon={<FaSort />}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          assignable={false}
          multiSelect={false}
          editable={false}
          deletable={false}
        />
      </div>

      <OffCanvasLayout
        show={showDetailModal}
        handleClose={closeModal}
        title={`${programDetailsData?.data?.sectionDetails?.name}`}
        showContent= {programDetailsData?.data?.sectionDetails}
        closeIcon={
          <span className="material-symbols-outlined"> close </span>
        }
        // backdrop={"static"}
        className={"offcanvas_w_80"}
      >
        <ProgramDetails refetch={refetch} programDetailData={programDetailsData} />
      </OffCanvasLayout>
    </>
  );
};

export default TopProgramList;
