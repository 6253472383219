import {
  Button,
  IconText,
  Image,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import Style from "../podcastUpdationForm.module.scss";
import { limitStrLength } from "../../../../utils/functions/table";
import useUploads from "./useUploads";
import { ProfileCard } from "../../../Global/ProfileCard";
import { useState } from "react";

const Uploads = ({
  formik,
  getFieldError,
  isEditData,
  isLoading,
  profilefileInputRef,
  handleListThumbnailImage,
  handleTitleImage,
  handleVerticalImage,
  handleBannerImage,
  imageThumbnailPreview,
  imageTitleImagePreview,
  imageVerticalThumbnailPreview,
  imageBannerPreview,
  handleSong,
  handlevideo,
  currentFormData,
  show,
  closeModal,
  fieldName,
  setShow,
  setImageTitleImagePreview,
  setImageVerticalThumbnailPreview,
  setImageBannerPreview,
  setImageThumbnailPreview,
  cropData,
  formikImage,
  setFormikImage,
  handleClick,
}) => {
  const { handleCloseModal } = useUploads({
    formik,
  });

  return (
    <div className={`pro-w-100`}>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Title Image [3:2]*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".png"
            ref={profilefileInputRef}
            className={`pro-input lg ${
              getFieldError("title_image") && " error"
            }`}
            id="title_image"
            name="title_image"
            onBlur={formik.handleBlur("title_image")}
            onChange={(e) => handleTitleImage(e)}
            onClick={handleClick}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.title_image?.name ||
                (formik?.values?.title_image?.[0]?.name !== undefined &&
                  isEditData &&
                  formik?.values?.title_image?.name) ||
                formik?.values?.title_image?.[0]?.name
                  ? limitStrLength(
                      formik?.values?.title_image?.name ||
                        formik?.values?.title_image?.[0]?.name,
                      30
                    )
                  : isEditData && formik?.values?.title_image
                  ? limitStrLength(formik?.values?.title_image ?? "", 30)
                  : (!isEditData && formik?.values?.title_image?.name) ||
                    formik?.values?.title_image?.[0]?.name
                  ? limitStrLength(
                      (formik?.values?.title_image?.name ||
                        formik?.values?.title_image?.[0]?.name) ??
                        "",
                      30
                    )
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {getFieldError("title_image") && (
            <span className="error-text">{getFieldError("title_image")}</span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs pro-mt-1">(file of type: png.)</p>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageTitleImagePreview}
            alt={`title_image`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Banner Image [4:5]*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png, .gif"
            className={`pro-input lg ${
              getFieldError("banner_image") && " error"
            }`}
            id="banner_image"
            name="banner_image"
            onBlur={formik.handleBlur("banner_image")}
            onChange={(e) => handleBannerImage(e)}
            onClick={handleClick}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.banner_image?.name !== undefined &&
                isEditData &&
                formik?.values?.banner_image?.name
                  ? limitStrLength(formik?.values?.banner_image?.name, 30)
                  : isEditData && formik?.values?.banner_image
                  ? limitStrLength(formik?.values?.banner_image ?? "", 30)
                  : !isEditData && formik?.values?.banner_image?.name
                  ? limitStrLength(formik?.values?.banner_image?.name ?? "", 30)
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {getFieldError("banner_image") && (
            <span className="error-text">{getFieldError("banner_image")}</span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs pro-mt-1">
          (file of type: jpeg, png, jpg, gif.)
        </p>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageBannerPreview}
            alt={`banner_image`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Basic Thumbnail Image [1:1]*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png, .gif"
            className={`pro-input lg ${getFieldError("thumbnail") && " error"}`}
            id="thumbnail"
            name="thumbnail"
            onBlur={formik.handleBlur("thumbnail")}
            onChange={(e) => handleListThumbnailImage(e)}
            onClick={handleClick}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.thumbnail?.name !== undefined &&
                isEditData &&
                formik?.values?.thumbnail?.name
                  ? limitStrLength(formik?.values?.thumbnail?.name, 30)
                  : isEditData && formik?.values?.thumbnail
                  ? limitStrLength(formik?.values?.thumbnail ?? "", 30)
                  : !isEditData && formik?.values?.thumbnail?.name
                  ? limitStrLength(formik?.values?.thumbnail?.name ?? "", 30)
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {getFieldError("thumbnail") && (
            <span className="error-text">{getFieldError("thumbnail")}</span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs pro-mt-1">
          (file of type: jpeg, png, jpg, gif.)
        </p>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageThumbnailPreview}
            alt={`thumbnail`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Vertical Thumbnail [3:4]*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            ref={profilefileInputRef}
            className={`pro-input lg ${
              getFieldError("vertical_thumbnail") && " error"
            }`}
            id="vertical_thumbnail"
            name="vertical_thumbnail"
            onBlur={formik.handleBlur("vertical_thumbnail")}
            onChange={(e) => handleVerticalImage(e)}
            onClick={handleClick}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.vertical_thumbnail?.name ||
                (formik?.values?.vertical_thumbnail?.[0]?.name !== undefined &&
                  isEditData &&
                  formik?.values?.vertical_thumbnail?.name) ||
                formik?.values?.vertical_thumbnail?.[0]?.name
                  ? limitStrLength(
                      formik?.values?.vertical_thumbnail?.name ||
                        formik?.values?.vertical_thumbnail?.[0]?.name,
                      30
                    )
                  : isEditData && formik?.values?.vertical_thumbnail
                  ? limitStrLength(formik?.values?.vertical_thumbnail ?? "", 30)
                  : (!isEditData && formik?.values?.vertical_thumbnail?.name) ||
                    formik?.values?.vertical_thumbnail?.[0]?.name
                  ? limitStrLength(
                      (formik?.values?.vertical_thumbnail?.name ||
                        formik?.values?.vertical_thumbnail?.[0]?.name) ??
                        "",
                      30
                    )
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {getFieldError("vertical_thumbnail") && (
            <span className="error-text">{getFieldError("vertical_thumbnail")}</span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs pro-mt-1">(file of type: jpeg, png, jpg, gif.)</p>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageVerticalThumbnailPreview}
            alt={`vertical_thumbnail`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Audio*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".mp3, .wav"
            className={`pro-input lg ${getFieldError("audio") && " error"}`}
            id="audio"
            name="audio"
            onBlur={formik.handleBlur("audio")}
            onChange={(e) => handleSong(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                isEditData && currentFormData?.is_converted !== 1 ? (
                  <span className="pro-text-primary">Processing</span>
                ) : formik?.values?.audio?.[0]?.name !== undefined &&
                  isEditData &&
                  formik?.values?.audio?.[0]?.name ? (
                  limitStrLength(formik?.values?.audio?.[0]?.name, 30)
                ) : isEditData && formik?.values?.audio ? (
                  limitStrLength(formik?.values?.audio ?? "", 30)
                ) : !isEditData && formik?.values?.audio?.[0]?.name ? (
                  limitStrLength(formik?.values?.audio?.[0]?.name ?? "", 30)
                ) : (
                  "Drop files to attach or browse"
                )
              }
            />
          </span>
          {getFieldError("audio") && (
            <span className="error-text">{getFieldError("audio")}</span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs pro-mt-1">
          (file of type: mp3, wav)
        </p>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Video
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".mp4"
            className={`pro-input ${
              formik.errors.video && formik.touched.video && " error"
            }`}
            id="video"
            name="video"
            onBlur={formik.handleBlur("video")}
            onChange={(e) => handlevideo(e)}
          />
          <span className="input-drag-box">
            <IconText
              title={
                isEditData && currentFormData?.is_converted !== 1 ? (
                  <span className="pro-text-primary">Processing</span>
                ) : formik?.values?.video?.[0]?.name !== undefined &&
                  isEditData &&
                  formik?.values?.video?.[0]?.name ? (
                  limitStrLength(formik?.values?.video?.[0]?.name, 30)
                ) : isEditData && formik?.values?.video ? (
                  limitStrLength(formik?.values?.video ?? "", 30)
                ) : !isEditData && formik?.values?.video?.[0]?.name ? (
                  limitStrLength(formik?.values?.video?.[0]?.name ?? "", 30)
                ) : (
                  "Drop files to attach or browse"
                )
              }
            />
          </span>
          {formik.touched.video && formik.errors.video && (
            <span className="error-text">{formik.errors.video}</span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs pro-mt-1">
          (file of type: mp4, ogx, oga, ogv, ogg, webm, mkv, mov.)
        </p>
      </div>

      {formikImage && (
        <ModalLayout backdrop="static" show={show} handleClose={closeModal}>
          <ProfileCard
            setShow={setShow}
            fieldName={fieldName}
            formik={formik}
            data={formikImage}
            handleClose={closeModal}
            setImageTitleImagePreview={setImageTitleImagePreview}
            setImageBannerPreview={setImageBannerPreview}
            setImageVerticalThumbnailPreview={setImageVerticalThumbnailPreview}
            setImageThumbnailPreview={setImageThumbnailPreview}
            cropData={cropData}
            setFormikImage={setFormikImage}
          />
        </ModalLayout>
      )}

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          type="submit"
          disabled={isLoading}
        >
          {isEditData ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};
export default Uploads;
