import {
  HeadingGroup,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
  ConfirmationBox,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import UpdateCampaign from "../UpdateCampaign";
import Style from "../campaign.module.scss";
import useCampaignList from "./useCampaignList";
import Filter from "../Filter";

const CampaignList = () => {
  const {
    campaign,
    campaignState,
    activeId,
    currentPage,
    activeFilter,
    showCreateModal,
    paginationOptions,
    isFetching,
    isLoading,
    showDeleteModal,
    isEditData,
    filterShow,
    setFilterShow,
    handleClearClick,
    handleEditModal,
    handleCreateClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    refetch,
    handleSort,
    handleSearch,
    handleCampaignDataEditClick,
    closeModal,
    deleteCampaignDataClick,
    getRow,
    deleteCampaign,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  } = useCampaignList();

  return (
   
        <>
          <HeadingGroup
            title={"Campaign"}
            buttonTitle={hasCreatePermission ?? false ? "Add New" : ""}
             extraClassName={`pro-mb-4`}
            handleClick={handleCreateClick}
          />
            <div className="col-12 pro-pb-6">
              <SearchFilters
                activeFilter={activeFilter}
                showFilters={true}
                onSearchInput={handleSearch}
                loading={isLoading}
                showActions={false}
                handleActionClick={handleEditModal}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                showDateRange={false}
                handleClear={handleClearClick}
                extraFilters={
                  <div className="col-auto">
                    <button
                    className={`pro-btn pro-btn-outline pro-h-100 pro-items-center`}
                    onClick={() => setFilterShow(true)}
                    >
                      <span className="material-symbols-outlined">tune</span>
                      <span>Filter</span>
                    </button>
                  </div>
                }
              />
            <Table
              data={campaign?.data?.original?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              showCheckBox={
                hasEditPermission || hasDeletePermission ? true : false
              }
              SortIcon={<FaSort />}
              handleEdit={handleCampaignDataEditClick}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              handleDelete={deleteCampaignDataClick}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={campaignState.currentPageSize}
              assignable={false}
              multiSelect={false}
              clear={campaignState.clearSelection}
              editable={hasEditPermission ? true : false}
              deletable={hasDeletePermission ? true : false}
            />
            {campaign?.data?.original?.data?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalPageCount={campaign?.data?.original?.last_page_number}
                onPageChange={handlePagination}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
              />
            )}
            </div>
            <ModalLayout
              centered
              show={showDeleteModal}
              handleClose={closeDeleteModal}
            >
              <div className="pro-m-5">
                <ConfirmationBox
                  title={`Are you sure you want to delete?`}
                  isRight={true}
                  cancelText={`Cancel`}
                  submitText={`Delete`}
                  cancelAction={closeDeleteModal}
                  submitAction={deleteCampaign}
                >
                  ConfirmationBox
                </ConfirmationBox>
              </div>
            </ModalLayout>

            <OffCanvasLayout
              show={showCreateModal}
              handleClose={closeModal}
              title={`${
                isEditData ? "Update Campaign Details" : "Create Campaign"
              }`}
              closeIcon={
                <span className="material-symbols-outlined"> close </span>
              }
              backdrop={"static"}
            >
              <UpdateCampaign
                activeId={activeId}
                refetch={refetch}
                isEditData={isEditData}
              />
            </OffCanvasLayout>

            <OffCanvasLayout
              centered={true}
              show={filterShow}
              handleClose={() => {
                setFilterShow(false);
              }}
              title={"Filter"}
              closeIcon={
                <span className="material-symbols-outlined">close</span>
              }
            >
              <Filter setShowform={setFilterShow} refetch={refetch}/>
            </OffCanvasLayout>
        </>
  );
};

export default CampaignList;
