import useFilter from "./useFilter";

function Filter({ setShowform,refetch }) {
  const { renderTabContent } = useFilter({setShowform,refetch});

  return (
    <div>
      <form action="javascript:void(0)">{renderTabContent()}</form>
    </div>
  );
}

export default Filter;
 