import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PodcastFilterForm from "./PodcastFilterForm";
import {
  resetExtraFilters,
  setStoreFormValues,
} from "../../../store/slices/Podcast/podcastSlice";
import {
  useCategoryDataListQuery,
  // useProgramDataListQuery,
} from "../../../store/queries/podcast";
import { programDataList } from "../updatePodcast/api";
import { updateConfig } from "../../../store/slices/Podcast/podcastSlice";

const useFilter = ({ setShowform, refetch }) => {
  const dispatch = useDispatch();
  const [programList, setProgramList] = useState([]);
  const { data: basicData = {} } = useCategoryDataListQuery();
  // const { data: programData = {} } = useProgramDataListQuery();
  const state = useSelector((state) => state.podcast);

  const validationSchema = Yup.object({
    status: Yup.string().nullable(),
    category_id: Yup.array().nullable(),
    rj_id: Yup.array().nullable(),
    type: Yup.string().nullable(),
    programe_id: Yup.array().nullable(),
    from_date: Yup.date().nullable(),
    to_date: Yup.date().nullable(),
  });

  const initialValues = {
    status: state.status,
    category_id: state.category_id,
    rj_id: state.rj_id,
    type: state.type,
    programe_id: state?.programe_id,
    from_date: state.from_date,
    to_date: state.to_date,
  };

  // const [queryParams, setQueryParams] = useState({
  //   type: "",
  // });

  // useEffect(() => {
  //   if(queryParams?.type !== '') {
  //     programDataList(queryParams).then((response) => {
  //       setProgramList(response?.data?.data);
  //     });
  //   }
    
  // }, [queryParams]);
  // useEffect(() => {
  //   programDataList().then((response) => {
  //     setProgramList(response?.data?.data);
  //   }); 
  // }, []);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const {
        status,
        category_id,
        rj_id,
        type,
        programe_id,
        from_date,
        to_date,
      } = values;
      if (
        status === "" &&
        category_id?.length === 0 &&
        rj_id?.length === 0 &&
        type === "" &&
        programe_id?.length === 0 &&
        from_date === "" &&
        to_date === ""
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
      }
      let data = {
        status,
        category_id,
        rj_id,
        type,
        programe_id,
        from_date,
        to_date,
      };
      if (values) {
        dispatch(setStoreFormValues(values));
      }
    },
  });

  useEffect(() => {
    if (state?.type !== '') {
      setTypeValue(state?.type);
    }
  }, [state?.type]);

  useEffect(() => {
    if (formik.values.status !== '') {
      refetch();
    }
  }, [formik.values.status, refetch]);

  const renderTabContent = () => {
    return (
      <PodcastFilterForm
        formik={formik}
        handleReset={handleReset}
        categoryList={basicData?.data?.category}
        rjList={basicData?.data?.rjs}
        podcastType={basicData?.data?.podcast_type}
        programList={programList}
        setTypeValue={setTypeValue}
      />
    );
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  const setTypeValue = (value) => {
    formik.setFieldValue("type", value?._id ?? value)
    programDataList({type: "2"}).then((response) => {
      setProgramList(response?.data?.data);
    });
    
  }
  return {
    renderTabContent,
  };
};

export default useFilter;
