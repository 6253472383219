import {
  HeadingGroup,
  ModalLayout,
  Pagination,
  SearchFilters,
  Table,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import useRoleList from "./useRoleList";

const RoleList = () => {
  const {
    roleState,
    tableFields,
    roleList,
    isLoading,
    isFetching,
    paginationOptions,
    showDeleteModal,
    closeDeleteModal,
    getRow,
    handleSort,
    handlePagination,
    handleCreateClick,
    handleEditAction,
    handleSearch,
    handleClearClick,
    handlePageSize,
    deleteRoleDataClick,
    deleteRole,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  } = useRoleList();
  return (
    <div>
      
          <HeadingGroup
            title={"All Roles"}
             extraClassName={`pro-mb-4`}
            buttonTitle={hasCreatePermission ?? false ? "Add New" : ""}
            handleClick={handleCreateClick}
          />
          <div className="col-6 pro-pt-3 pro-pb-6">
            <SearchFilters
              onSearchInput={handleSearch}
              loading={isLoading}
              showActions={false}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters={false}
              handleClear={handleClearClick}
              searchInputProps={{ value: roleState?.search }}
            />
            <Table
              multiSelect={false}
              data={roleList?.data?.original?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={roleState?.currentPageSize}
              assignable={false}
              handleEdit={handleEditAction}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleDelete={deleteRoleDataClick}
              showCheckBox={
                hasEditPermission || hasDeletePermission ? true : false
              }
              clear={roleState.clearSelection}
              editable={hasEditPermission ? true : false}
              deletable={hasDeletePermission ? true : false}
            />
            {roleList?.data?.data?.length > 0 && (
              <Pagination
                currentPage={roleState?.currentPage}
                totalPageCount={Math.ceil(
                  roleList.data.total_count / roleState.currentPageSize
                )}
                onPageChange={handlePagination}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
              />
            )}

            <ModalLayout
              centered
              show={showDeleteModal}
              handleClose={closeDeleteModal}
            >
              <div className="pro-m-5">
                <ConfirmationBox
                  title={`Are you sure you want to delete?`}
                  isRight={true}
                  cancelText={`Cancel`}
                  submitText={`Delete`}
                  cancelAction={closeDeleteModal}
                  submitAction={deleteRole}
                >
                  ConfirmationBox
                </ConfirmationBox>
              </div>
            </ModalLayout>
          </div>
       
    </div>
  );
};

export default RoleList;
