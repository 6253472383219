import { Input, Button } from "@wac-ui-dashboard/wac_component_library";
import useDetails from "./useDetails";
import Select from "react-select";

const Details = ({
  formik,
  categoryList,
  getFieldError,
  isEditData,
  currentFormData,
}) => {
  const { handleCloseModal, handleNext } = useDetails({
    formik,
  });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Title*"}
        type="text"
        id="title"
        name="title"
        className={`pro-input lg ${getFieldError("title") && " error"}`}
        {...formik.getFieldProps("title")}
        error={getFieldError("title")}
        errorMessage={getFieldError("title")}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Description*
        </label>
        <textarea
          id="description"
          rows={4}
          name="description"
          className={`pro-input lg ${
            formik.touched.description && formik.errors.description && " error"
          }`}
          {...formik.getFieldProps("description")}
        ></textarea>
        {formik.touched.description && formik.errors.description && (
          <span className="error-text">{formik.errors.description}</span>
        )}
      </div>

      <Input
        label={"Sub Title*"}
        type="text"
        id="sub_title"
        name="sub_title"
        className={`pro-input lg ${getFieldError("sub_title") && " error"}`}
        {...formik.getFieldProps("sub_title")}
        error={getFieldError("sub_title")}
        errorMessage={getFieldError("sub_title")}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Date and Time*
        </label>
        <Input
          value={formik.values.date || ""}
          type={"datetime-local"}
          id="date"
          name="date"
          min={new Date().toISOString().split("T")[0]}
          className={`pro-input lg ${getFieldError("date") && " error"}`}
          onChange={(e) => formik.setFieldValue("date", e.target.value)}
          onBlur={formik.handleBlur("date")}
          error={getFieldError("date")}
          errorMessage={getFieldError("date")}
        />
        {/* {getFieldError("date") && (
          <span className="error-text">{getFieldError("date")}</span>
        )} */}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Category*
        </label>
        <Select
          id="category"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg ${getFieldError("category") && " error"}`}
          classNamePrefix="pro-input"
          options={categoryList}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={categoryList?.filter(
            (m) => formik.values.category?._id === m?._id
          )}
          onBlur={formik.handleBlur("category")}
          onChange={(value) => {
            formik.setFieldValue("category", value || null);
          }}
        />
        {getFieldError("category") && (
          <span className="error-text">{getFieldError("category")}</span>
        )}
      </div>

      {isEditData && currentFormData?.is_converted === 1 ? (
        <div className="input-wrap pro-mb-4 pro-flex-row pro-items-center">
          <div className="form-check form-switch pro-mb-0">
            <input
              type="checkbox"
              className="form-check-input cursor-pointer"
              id="status"
              name="status"
              checked={Boolean(formik.values?.status) ?? false}
              onChange={(e) => {
                formik.setFieldValue(
                  "status",
                  !Boolean(formik.values?.status) ?? false
                );
              }}
            />
          </div>
          <label htmlFor="" className="pro-font-sm pro-lh-base pro-fw-medium">
            Status
          </label>
        </div>
      ) : (
        ""
      )}

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
          type="button"
        >
          Cancel
        </Button>

        <Button className={"pro-btn-primary lg pro-mx-3"} onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  );
};
export default Details;
