import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";

const usePrivacyAndTerms = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const drawerMenu = [
    {
      title: "App Settings",
      link: "/settings",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/settings", true),
    },
    {
      title: "Banner Settings",
      link: "/settings/banner",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/settings/banner", true),
    },
  ];

  return {
    drawerMenu,
  };
};

export default usePrivacyAndTerms;
