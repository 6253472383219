import { getAxiosInstance } from "../../api";

export const getAllPermissions = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`/admin/role/basic-data`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const AddRole = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/admin/role/save`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const updateRole = async (body) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`/admin/role/update`, body);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const approveRequest = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(`/roles/approve`, params);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
