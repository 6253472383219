import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useUpdateUsersListDataMutation } from "../../../store/queries/users";
import { updateConfig } from "../../../store/slices/Users/usersSlice";
import { toast } from "react-toastify";
import { emailRegExp, phoneRegExp } from "../../../utils/functions/table";
import { getDataById } from "./api";

const useUpdateUser = ({ activeId, refetch }) => {
  const dispatch = useDispatch();
  const [updateUser] = useUpdateUsersListDataMutation();

  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentFormData, setCurrentFormData] = useState([]);
  const nameRegExp = /^[^\s].*$/;

  useEffect(() => {
    if (activeId) {
      getDataById(activeId).then((response) => {
        formik.setErrors("");
        setErrors("");

        if (response?.data?.status_code === 200) {
          setCurrentFormData(response?.data?.data);
        } else if (response?.data?.error_code === 403) {
          formik.setErrors(response?.data?.errors);
        }
      });
    }
  }, []);

  let usersVal = Yup.object({
    name: Yup.string().required("Name is required")
    .matches(nameRegExp, "Leading space is not allowed"),
    country_code: Yup.string().required("Country Code is Required"),
    mobile: Yup.string()
      .matches(phoneRegExp, "Enter a valid mobile number")
      .min(5, "Mobile number must be greater than 5 digits ")
      .required("Mobile Number is Required"),
      email: Yup.string()
      .matches(emailRegExp, "Enter a valid email")
      .required("Email is Required"),
    dob: Yup.string().required("Date of Birth is Required"),
  });

  const formik = useFormik({
    initialValues: {
      _id: activeId,
      name: null,
      country_code: null,
      mobile: null,
      email: null,
      dob: null,
    },
    validationSchema: usersVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        let obj = {
          ...values,
          status: +Boolean(values.status),
        };

        updateUser(obj).then((response) => {
          formik.setErrors("");
          setErrors("");

          if (response?.data?.status_code === 200) {
            setIsLoading(false);
            refetch();
            resetForm();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
            toast.success("Successfully udpated");
          } else if (response?.data?.error_code === 403) {
            setIsLoading(false);
            formik.setErrors(response?.data?.errors);
          } else if (response?.status_code === 500) {
            setIsLoading(false);

            toast.error("Something Went Wrong");
          } else {
            setIsLoading(false);
          }
        });
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  let maxDate = new Date();
  maxDate.getFullYear();
  const reverseDateString = (dateString) => {
    if (dateString) {
      const parts = dateString.split("-");
      const reversedDateString = parts[2] + "-" + parts[1] + "-" + parts[0];
      return reversedDateString;
    }
  };
  let dateStr = reverseDateString(formik.values.dob);

  const today = new Date();
  today.setDate(today.getDate() - 1); // Calculate tomorrow's date
  const minDate = today.toISOString().split("T")[0];

  useEffect(() => {
    if (currentFormData) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
    }

    // eslint-disable-next-line
  }, [currentFormData]);

  const handleCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: 0,
        };
        state.activeTab = "Basic";
      })
    );
  };

  return {
    formik,
    errors,
    handleCloseModal,
    currentFormData,
    isLoading,
    dateStr,
    minDate,
  };
};

export default useUpdateUser;
