import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key] === "status") {
        return `${key}=${parseInt(params[key])}&`;
      } else if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return ""; 
      }
    };
    try {
      const response = await api[method](
        `${endpoint}?${getParams("_id")}${getParams("from_date")}${getParams(
          "to_date"
        )}${getParams("status")}${getParams(
          "sort_by"
        )}sort_order=${params?.sort_order || ""}&page_size=${
          params?.page_size || "10"
        }&${getParams("search")}&page=${params?.page || 1}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err.response?.data || err.message,
      };
    }
  };

export const topPrograms = createApi({
  reducerPath: "topProgramsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["topPrograms"],
  endpoints: (builder) => ({

    getTopProgramsListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/dashboard/analytics-list-type-sort`,
      }),
      provideTags: ["topPrograms"],
    }),

    getEpisodeListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `admin/dashboard/analytics-episode-list`,
      }),
      provideTags: ["topPrograms"],
    }),
  }),
});

export const {
  useGetTopProgramsListDataQuery,
  useGetEpisodeListDataQuery
} = topPrograms;
