import {
  FormTabs,
} from "@wac-ui-dashboard/wac_component_library";
import Style from "./programmeUpdationForm.module.scss";
import useUpdateProgramme from "./useUpdateProgramme";

const UpdateProgramme = ({ activeId, refetch, isEditData }) => {
  const {
    tabs,
    activeTab,
    handleTabClick,
    handleMoveToErrorTab,
    renderTabContent,
    formik,
  } = useUpdateProgramme({
    activeId,
    refetch,
    isEditData,
  });

  return (
    <>
    <FormTabs
      propStyle={{ root: Style.FormTabs_root }}
      tabs={tabs}
      active={activeTab}
      handleClick={handleTabClick}
    />
    <form onSubmit={(e) => {formik.handleSubmit(e); handleMoveToErrorTab();}}>{renderTabContent()}</form>
    </>
  );
};
export default UpdateProgramme;
