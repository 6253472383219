import style from "./dashboardCard.module.scss";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const DashboardCard = ({ title, data, icon, active }) => {

  return (
    <div className={`${style.root}  ${active ? `active` : ""}`}>
      <div
        className={`${style.inner} ${style.card_v2}  pro-flex-column pro-w-100`}
      >
        <div
          className={`${style.body} pro-d-flex pro-justify-center pro-items-center pro-w-100  pro-flex-row`}
        >
          <div className="title-wrap pro-w-100">
            <h3 className="pro-ttl h3 pro-mb-2 pro-fw-medium">
              {title !== "Total Plays" ? data?.current : data?.total}
            </h3>
            <div className={`${style.growth_status} pro-d-flex pro-w-100`}>
              {title === "Total Users" ? (
                <div className="pro-pe-1">
                  <OverlayTrigger
                    placement="bottom"
                    // delay={{ show: 250, hide: 50 }}
                    overlay={
                      <Tooltip className="custom-tooltip">
                        <strong>New Registered Users:</strong>

                        <p>{data?.tool_tip?.new_reg_users}</p>
                      </Tooltip>
                    }
                  >
                    <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
                      New Registered Users
                    </h6>
                  </OverlayTrigger>
                </div>
              ) : title === "Active Users" ? (
                <div className="pro-pe-1">
                  <OverlayTrigger
                    placement="bottom"
                    // delay={{ show: 250, hide: 50 }}
                    overlay={
                      <Tooltip className="custom-tooltip">
                        <strong>{title}:</strong>

                        <p>{data?.tool_tip?.active_users}</p>
                      </Tooltip>
                    }
                  >
                    <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
                      {title}
                    </h6>
                  </OverlayTrigger>
                </div>
              ) : title === "Total Plays" ? (
                <div className="pro-pe-1">
                  <OverlayTrigger
                    placement="bottom"
                    // delay={{ show: 250, hide: 50 }}
                    overlay={
                      <Tooltip className="custom-tooltip">
                        <strong>{title}:</strong>

                        <p>{data?.tool_tip?.total_plays}</p>
                      </Tooltip>
                    }
                  >
                    <h6 className="pro-ttl h6 pro-mb-0 pro-fw-medium">
                      {title}
                    </h6>
                  </OverlayTrigger>
                </div>
              ) : <></>}

              <div className="pro-ps-1">
                <OverlayTrigger
                  placement="right"
                  // delay={{ show: 250, hide: 5000 }}
                  overlay={
                    <Tooltip className="custom-tooltip">
                      <strong>Growth:</strong>
                      <p>{data?.tool_tip?.growth}</p>
                    </Tooltip>
                  }
                >
                  <span
                    className={`${
                      data?.growth.includes("-")
                        ? style.decrement
                        : style.increment
                    } ${
                      style.growth_percentage
                    } pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
                  >
                    <span
                      className={`material-symbols-outlined ${style.arrow}`}
                    >
                      arrow_outward
                    </span>
                    <span>{data?.growth}</span>
                  </span>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          {/* <div className={`${style.icon}`}>
            <img src={icon} alt={title} />
          </div> */}
        </div>
        {title !== "Total Plays" && (
          <div
            className={`pro-d-flex pro-items-center pro-pt-4 pro-mt-auto pro-gap-3 pro-flex-wrap`}
          >
            {data?.hasOwnProperty('total') && (
              <div>
                Total Registered Users - <strong>{data?.total !== 0 ? data?.total : 0}</strong>
              </div>
            )}
            {data?.hasOwnProperty('deleted') && (
              <div>
                Deleted Users - <strong>{data?.deleted !== 0 ? data?.deleted : 0}</strong>
              </div>
            )}
            {data?.hasOwnProperty('returning') && (
              <div>
                Returning Users - <strong>{data?.returning !== 0 ? data?.returning : 0}</strong>
              </div>
            )}
            {/* <p className="pro-mb-0 pro-fw-medium">{`Previous : ${data?.previous}`}</p> */}
          </div>
        )}
        {title === "Total Plays" && (
          <div
            className={`pro-d-flex  pro-items-center pro-pt-4 pro-mt-auto pro-gap-3`}
          >
            {/* <span
              className={`${
                data?.growth.includes("-") ? style.decrement : style.increment
              } ${
                style.growth_percentage
              } pro-align-self-center pro-font-xs pro-fw-medium pro-badge`}
            >
              <span className={`material-symbols-outlined ${style.arrow}`}>
                arrow_outward
              </span>
              <span>{data?.growth}</span>
            </span> */}
            {/* <div className="pro-d-flex pro-items-center pro-pt-4 pro-mt-auto pro-gap-3"> */}
            {data?.data?.map((item) => (
              <div>
                {item?.labels} - <strong>{item?.value}</strong>
              </div>
            ))}
            {/* </div> */}
            {/* <p className="pro-mb-0 pro-fw-medium">{`Previous : ${data?.previous}`}</p> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardCard;
