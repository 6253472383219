import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteScheduleDataMutation,
  useGetScheduleListDataQuery,
} from "../../../store/queries/schedule";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Schedule/scheduleSlice";
import { getFormatedDate } from "../../../utils/functions/table";
import { toast } from "react-toastify";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
// import { statusChange } from "../UpdateClient/api";

const useScheduleList = () => {
  const scheduleState = useSelector((state) => state.schedule);
  const { showCreateModal } = useSelector((state) => state.schedule);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [scheduleDelete] = useDeleteScheduleDataMutation();
  const [activeId, setActiveId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUpdateRows, setSelectedUpdatedRows] = useState([]);
  
  const { isEditData } = useSelector((state) => state.schedule);
  const [filterShow, setFilterShow] = useState(false);
  const dispatch = useDispatch();

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: schedule = {},
    isFetching,
    isLoading,
    isSuccess,
    refetch,
  } = useGetScheduleListDataQuery({
    length: scheduleState.currentPageSize,
    page: scheduleState.currentPage,
    search: scheduleState.search,
    sort_by: scheduleState.sortBy,
    sort_order: scheduleState.sortOrder,
    status: scheduleState.status,
  });

  const { data: formData, isSuccess: isUpdateDataSuccess } =
    useGetScheduleListDataQuery(
      { skip: isEditData === false }
    );

  const filters = useMemo(() => {
    if (schedule?.data?.filters) {
      const buttonGroups = schedule?.data?.filters?.filter((d) => !d?.type);
      return [{ type: "buttonGroup", buttons: buttonGroups }];
    } else {
      return [];
    }
    // eslint-disable-next-line
  }, [isLoading]);

  const hasCreatePermission = useMemo(() => {
    let permission = schedule?.data?.original?.permissions?.create_permission
    return permission ?? false;
  }, [schedule]);

  const hasEditPermission = useMemo(() => {
    let permission = schedule?.data?.original?.permissions?.edit_permission
    return permission ?? false;
  }, [schedule]);
  const hasViewPermission = useMemo(() => {
    let permission = schedule?.data?.original?.permissions?.list_permission
    return permission ?? false;
  }, [schedule]);
  const hasDeletePermission = useMemo(() => {
    let permission = schedule?.data?.original?.permissions?.delete_permission
    return permission ?? false;
  }, [schedule]);


  const tableHeading = {
    name: { label: "Name", sortable: true },
    title: { label: "Title", sortable: true },
    time: { label: "Time", sortable : true },
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableHeading;
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refetch();
  }, [scheduleState.status]);

  const deleteScheduleDataClick = (item) => {
    setShowDeleteModal(true);
    setSelectedUpdatedRows(item);
  };

  useEffect(() => {
    if (scheduleState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [scheduleState.clearSelection]);

  const deleteSchedule = () => {
    scheduleDelete({
      _id: selectedUpdateRows,
    }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
        setShowDeleteModal(false);
        toast.success("Successfully Deleted");
      } else if (response?.error?.status === 422) {
        toast.success("Something went wrong");
      }
    });
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (isEditData && isUpdateDataSuccess) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {
            ...formData?.data?.data,
          };
        })
      );
    }
    // eslint-disable-next-line
  }, [isEditData, isUpdateDataSuccess, formData]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleFilter = (filter) => {
    if (filter.value === "all") {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.startDate = null;
          state.endDate = null;
          state.sortBy = "";
          state.sortOrder = "";
          state.currentPage = 1;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentFilter = filter.value;
          state.currentPage = 1;
        })
      );
    }
  };
  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentFilter = null;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
      })
    );
  };

  const handleDateChange = (range) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handleSort = (label) => {
    if (scheduleState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = scheduleState.sortOrder === "0" ? "1" : "0";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "0";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
  };
  const handleEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleScheduleDataEditClick = (id) => {
    setActiveId(id[0]);
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.currentFormData = schedule?.data?.original?.data?.id;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.isEditData = true;
        state.activeId = id?.[0];
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const handleToggle = (e, data) => {
    let params = {
      _id: data?._id,
    };
    // statusChange(params).then((response) => {
    //   if (response?.data?.status_code === 200) {
    //     refetch();
    //   }
    // });
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field] ? `${data[field]}` : "-"}</p>,
      // status_label: (field, data) => {
      //   const isChecked = data?.status_label?.name === "Active";
      //   return (
      //     // <div className="form-check form-switch ">
      //     <div className={`pro-mb-0 ${(hasEditPermission || hasDeletePermission) ? "form-check form-switch" : ""} `}>
      //       <input
      //         className="form-check-input cursor-pointer"
      //         type="checkbox"
      //         role="switch"
      //         id="flexSwitchCheckDefault"
      //         checked={isChecked}
      //         onChange={(e) => handleToggle(e, data)}
      //       />
      //     </div>
      //   );
      // },
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  return {
    schedule,
    activeId,
    scheduleState,
    filters,
    currentPage: scheduleState.currentPage,
    activeFilter: scheduleState.currentFilter,
    showCreateModal,
    showEditModal,
    paginationOptions,
    isFetching,
    isLoading,
    isSuccess,
    totalRecords: scheduleState.recordsTotal,
    showDeleteModal,
    isUpdateDataSuccess,
    isEditData,
    filterShow,
    setFilterShow,
    handleClearClick,
    handleEditModal,
    handleCreateClick,
    deleteScheduleDataClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    handleFilter,
    handleDateChange,
    handleSort,
    handleSearch,
    handleScheduleDataEditClick,
    closeEditModal,
    closeModal,
    getRow,
    deleteSchedule,
    refetch,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  };
};

export default useScheduleList;
