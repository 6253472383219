import { createSlice } from "@reduxjs/toolkit";
import { subDays } from "date-fns";
import { getAxiosInstance } from "../../../api";
import dashboardSlice from "../Dashboard/dashboardSlice";

const today = new Date();
  const dayOfWeek = today.getDay(); // Sunday - Saturday : 0 - 6
  const startOfWeek = new Date(today); // Create a new Date object for the start of the week

  // Set the start date to the previous Monday
  startOfWeek.setDate(today.getDate() - (dayOfWeek + 6) % 7);


const initialState = {
  activeTab: "today",
  // date_period: "",
  // ad_campaign_id: [],
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  sortBy: "",
  sortOrder: "",
  search: "",
  showDetailModal: false,
  activeId: null,
  currentFormData: [null],
  isEditData: false,
  status: "",
  clearSelection: false,
  startDate: startOfWeek, 
  endDate: new Date(),
  category_id: "",
  program_id: "",
  table: "",
  media_type: "",
  dashboard_type: "",
};

export const exportAllData = async (data) => {
  const api = await getAxiosInstance();
  
  // Define the base payload
  let payload = {
    from_date: data?.from_date,
    to_date: data?.to_date,
    dashboardSlice: data?.dashboard_type
  };

  // Add dashboard_type conditionally
  if (data.dashboard_type == 2) {
    payload.dashboard_type = data.dashboard_type;
    payload.program_sort_by = data.program_sort_by;
        payload.program_sort_order = data.program_sort_order;
        payload.category_sort_by = data.category_sort_by;
        payload.category_sort_order = data.category_sort_order
        payload.program_media_type = data.program_media_type;
        payload.category_media_type = data.category_media_type;
  }

  try {
    const response = await api.post(
      `/admin/dashboard/analytics-export`,
      payload,
      {
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};


const homeDashboardSlice = createSlice({
  name: "homeDashboard",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
});

export const { updateConfig } = homeDashboardSlice.actions;

export default homeDashboardSlice.reducer;
