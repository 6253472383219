import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ProtectRoute from "../utils/components/ProtectRoute";
import useRoutes from "../routes/useRoutes";

const Index = () => {
  const navigate = useNavigate();
  const {
    allowedUsersComponents,
    allowedRjComponents,
    allowedMediaComponents,
    allowedAdvertisementComponents,
    allowedProgrammeComponents,
    allowedCategoryComponents,
    allowedNewsCategoryComponents,
    allowedRoleComponents,
    allowedSubAdminComponents,
    allowedScheduleComponents,
    allowedPrivacyComponents,
    allowedDashboardComponents
  } = useRoutes();

  useEffect(() => {
    if (!localStorage.getItem("USER_ACCESS_TOKEN")) {
      navigate("/login");
    } else {
      if (allowedUsersComponents?.length > 0) {
        navigate(`/${allowedUsersComponents?.map((item) => item?.path)?.[0]}`);
      } 
      else if (allowedDashboardComponents?.length > 0) {
        navigate(
          `/${allowedDashboardComponents?.map((item) => item?.path)?.[0]}`
        );
      }
      else if (allowedMediaComponents?.length > 0) {
        navigate(`/${allowedMediaComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedAdvertisementComponents?.length > 0) {
        navigate(
          `/${allowedAdvertisementComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedRjComponents?.length > 0) {
        navigate(`/${allowedRjComponents?.map((item) => item?.path)?.[0]}`);
      } else if (allowedProgrammeComponents?.length > 0) {
        navigate(
          `/${allowedProgrammeComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedCategoryComponents?.length > 0) {
        navigate(
          `/${allowedCategoryComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedNewsCategoryComponents?.length > 0) {
        navigate(
          `/${allowedNewsCategoryComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedSubAdminComponents?.length > 0) {
        navigate(
          `/${allowedSubAdminComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedScheduleComponents?.length > 0) {
        navigate(
          `/${allowedScheduleComponents?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedRoleComponents?.length > 0) {
        navigate(`${allowedRoleComponents?.map((item) => item?.path)?.[0]}`);
      } else {
        navigate(
          `/${allowedPrivacyComponents?.map((item) => item?.path)?.[0]}`
        );
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ProtectRoute>
      <Outlet />
    </ProtectRoute>
  );
};

export default Index;
