import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/MediaNews/medianewsSlice";
import {
  useDeleteMediaNewsDataMutation,
  useGetMediaNewsListDataQuery,
  useStatusChangeMutation,
} from "../../../store/queries/medianews";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { statusChange } from "../UpdateMediaNews/api";
import { current } from "@reduxjs/toolkit";
import { medianews as medianewsQuery } from "../../../store/queries/medianews";

const useMediaNewsList = () => {
  const dispatch = useDispatch();
  const medianewsState = useSelector((state) => state.medianews);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [medianewsDelete] = useDeleteMediaNewsDataMutation();
  const {
    isEditData,
    activeId,
    selectedUpdateRows,
    audioData,
    imageData,
    typeData,
    basiThumbnailImage,
    dataTitle,
    dataId,
  } = useSelector((state) => state.medianews);
  const [statusChange] = useStatusChangeMutation();

  const [filterShow, setFilterShow] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showBasicThumbnailImageModal, setShowBasicThumbnailImageModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [dataFileIndex, setDataFileIndex] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const title = searchParams.get("title");
    if (title) {
      const searchTitle = title.replace(/%/g, " ");
      dispatch(
        updateConfig((state) => {
          state.search = searchTitle;
        })
      );
      handleSearch(searchTitle);
    }
    else{
      dispatch(
        updateConfig((state) => {
          state.search = "";
        })
      );
    }
  }, []);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: medianews = {},
    isFetching,
    isLoading,
    isSuccess,
    refetch,
  } = useGetMediaNewsListDataQuery({
    length: medianewsState.currentPageSize,
    page: medianewsState.currentPage,
    search: medianewsState.search,
    sort_by: medianewsState.sortBy,
    sort_order: medianewsState.sortOrder,
    status: medianewsState.status,
    category_id: medianewsState.category_id,
    from_date: medianewsState.from_date,
    to_date: medianewsState.to_date,
  });

  // const { data: formData, isSuccess: isUpdateDataSuccess } =
  //   useGetMediaNewsListDataQuery({ skip: isEditData === false });

  const hasCreatePermission = useMemo(() => {
    let permission = medianews?.data?.original?.permissions?.create_permission;
    return permission ?? false;
  }, [medianews]);

  const hasEditPermission = useMemo(() => {
    let permission = medianews?.data?.original?.permissions?.edit_permission;
    return permission ?? false;
  }, [medianews]);
  const hasViewPermission = useMemo(() => {
    let permission = medianews?.data?.original?.permissions?.list_permission;
    return permission ?? false;
  }, [medianews]);
  const hasDeletePermission = useMemo(() => {
    let permission = medianews?.data?.original?.permissions?.delete_permission;
    return permission ?? false;
  }, [medianews]);

  const tableHeading = {
    title: { label: "Title", sortable: true },
    date: { label: "Date", sortable: true },
    category: { label: "Category", sortable: true },
    list_thumbnail: { label: "List Thumbnail", sortable: true },
    basic_thumbnail: { label: "Basic Thumbnail", sortable: true },
    audio_response: { label: "Audio", sortable: true },
    audio_duration: { label: "Audio Duration", sortable: true },
    status_label: { label: "Status", sortable: true },
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableHeading;
      })
    );
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   refetch();
  // }, [
  //   medianewsState.status,
  //   medianewsState.category_id,
  //   medianewsState.from_date,
  //   medianewsState.to_date,
  // ]);

  const deleteMedianewsDataClick = (item) => {
    setShowDeleteModal(true);
    dispatch(
      updateConfig((state) => {
        state.selectedUpdateRows = item;
      })
    );
  };

  const deleteMedianews = () => {
    medianewsDelete({
      _id: selectedUpdateRows,
    }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );

        setShowDeleteModal(false);
        toast.success("Successfully Deleted");
      } else if (response?.error?.status === 422) {
        toast.success("Something went wrong");
      }
    });
  };

  useEffect(() => {
    if (medianewsState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [medianewsState.clearSelection]);

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // useEffect(() => {
  //   if (isEditData && isUpdateDataSuccess) {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.currentFormData = {
  //           ...formData?.data?.data,
  //         };
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [isEditData, isUpdateDataSuccess, formData]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (medianewsState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = medianewsState.sortOrder === "0" ? "1" : "0";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "0";
        })
      );
    }
  };

  // const handleSearch = (e) => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.search = e.target.value;
  //       state.currentPage = 1;
  //     })
  //   );
  // };
  const handleSearch = (e) => {
    if (typeof e === "string") {
      // If 'e' is a string (title from URL), update search directly
      dispatch(
        updateConfig((state) => {
          state.search = e;
          state.currentPage = 1;
        })
      );
    } else {
      // If 'e' is an event (input change), update search from input value
      dispatch(
        updateConfig((state) => {
          state.search = e.target.value;
          state.currentPage = 1;
        })
      );
    }
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = "";
      })
    );
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Details";
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
  };

  const handleEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleMedianewsDataEditClick = (id) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = id[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.currentFormData = medianews?.data?.original?.data?.id;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.isEditData = true;
        state.activeId = id?.[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Details";
      })
    );
  };

  const handleViewAudio = (data, title, id, type) => {
    let dataList = medianews?.data?.original?.data;
    let index = dataList.findIndex((item, index) => item?._id === id);
    setDataFileIndex(index);
    setShowAudioModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.audioData = data;
        state.typeData = type;
        state.dataTitle = title;
        state.dataId = id;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
    setShowAudioModal(() => false);
    setShowImageModal(() => false);
    setShowBasicThumbnailImageModal(() => false);
    dispatch(
      updateConfig((state) => {
        state.audioData = "";
        state.imageData = "";
        state.basiThumbnailImage = "";
      })
    );
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.imageData = data;
      })
    );
  };
  const handleViewBasicThumbnailImage = (data) => {
    setShowBasicThumbnailImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.basiThumbnailImage = data;
      })
    );
  };

  const handleToggle = (e, data) => {
    let params = {
      _id: data?._id,
    };
    statusChange(params).then((response) => {
      if (response?.data?.status_code == 400) {
        toast.error(response?.data?.message);
      }
    });
    dispatch(
      medianewsQuery.util.updateQueryData(
        "getMediaNewsListData",
        {
          length: medianewsState.currentPageSize,
          page: medianewsState.currentPage,
          search: medianewsState.search,
          sort_by: medianewsState.sortBy,
          sort_order: medianewsState.sortOrder,
          status: medianewsState.status,
          category_id: medianewsState.category_id,
          from_date: medianewsState.from_date,
          to_date: medianewsState.to_date,
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.original?.data?.map((res) =>
            res?._id === data?._id
              ? {
                  ...res,
                  status: data?.status === 0 ? 1 : 0,
                }
              : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              original: {
                ...updatedCachedData.data.original,
                data: newResult,
              },
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => (
        <p className="pro-mb-0">{data[feild] ? data[feild] : "-"}</p>
      ),
      date: (feild, data) => (
        <p className="pro-mb-0 pro-nowrap">{data[feild] ? data[feild] : "-"}</p>
      ),
      title: (feild, data) => (
        <p className="pro-mb-0 pro-min-lg">{data[feild] ? data[feild] : "-"}</p>
      ),
      audio_response: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={<span className="material-symbols-outlined">audio_file</span>}
            title={`${"Play Audio"}`}
            onClick={() =>
              handleViewAudio(data?.[feild], data?.title, data?._id, "audio")
            }
          />
          </div>
        ) : (
          <>-</>
        ),
      list_thumbnail: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
      basic_thumbnail: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewBasicThumbnailImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
      status_label: (field, data) => {
        const isChecked = data?.status === 1;
        return (
          <>
            {hasEditPermission ? (
              <div className="form-check form-switch ">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={isChecked}
                  onChange={(e) => handleToggle(e, data)}
                />
              </div>
            ) : (
              data?.status_label?.name
            )}
          </>
        );
      },
    };
    if (feild === "audio_duration") {
      return data.is_converted === 1 ? (
        rows?.[feild]?.(feild, data) ?? rows["common"](feild, data)
      ) : (
        <div className="loader" title="Processing">
          <div className="loader-inner ball-pulse">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    medianews,
    activeId,
    medianewsState,
    currentPage: medianewsState.currentPage,
    activeFilter: medianewsState.currentFilter,
    showEditModal,
    paginationOptions,
    isFetching,
    isLoading,
    isSuccess,
    totalRecords: medianewsState.recordsTotal,
    showDeleteModal,
    typeData,
    // isUpdateDataSuccess,
    isEditData,
    audioData,
    dataTitle,
    dataId,
    showAudioModal,
    filterShow,
    dataFileIndex,
    imageData,
    showImageModal,
    basiThumbnailImage,
    showBasicThumbnailImageModal,
    setDataFileIndex,
    setFilterShow,
    setShowAudioModal,
    handleEditModal,
    handleCreateClick,
    deleteMedianewsDataClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    handleMedianewsDataEditClick,
    closeEditModal,
    closeModal,
    getRow,
    deleteMedianews,
    refetch,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  };
};

export default useMediaNewsList;
