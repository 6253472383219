import { Outlet } from "react-router-dom";
import CommonLayout from "../../pages/Layouts/CommonLayout";
import useSchedule from "./useSchedule";

const Schedule = () => {
  const { drawerMenu } = useSchedule();

  return (
    <CommonLayout drawerMenu={drawerMenu}>
      <Outlet />
    </CommonLayout>
  );
};

export default Schedule;
