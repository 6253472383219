import { getAxiosInstance } from "../../../api";

export const categoryDataList = async () => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("admin/advertisement/basic-data");
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const createNew = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/advertisement/store",data);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const getDataById = async (id) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/advertisement/edit?_id=${id}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const placementTypeList = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/advertisement/placement-types?type=${data?._id}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const clientList = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/advertisement/client-list?search`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const campaignList = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/advertisement/campaign-list?client_id=${data}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
 