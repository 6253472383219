import React from "react";
import {
  BrowserRouter,
  Route,
  Routes as BrowserRoutes,
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import Index from "../pages";
import AuthModule from "../components/Auth";
import EmailOrPhone from "../components/Auth/EmailOrPhone";
import ForgotPassword from "../components/Auth/ForgotPassword";
import Login from "../components/Auth/Login";
import ResetPassword from "../components/Auth/ResetPassword";
import UsersList from "../components/Users/UsersList";
import Users from "../components/Users";
import Rj from "../components/Rj";
import RjList from "../components/Rj/RjList";
// import Podcast from "../components/Podcast";
import PodcastList from "../components/Podcast/PodcastList";
import MediaVideo from "../components/MediaVideo";
import MediaVideoList from "../components/MediaVideo/MediaVideoList";
import MediaShortsList from "../components/MediaShorts/MediaShortsList";
import MediaNewsList from "../components/MediaNews/MediaNewsList";
import Advertisement from "../components/Advertisement";
import AdvertisementList from "../components/Advertisement/AdvertisementList";
import Programme from "../components/Programme";
import ProgrammeList from "../components/Programme/ProgrammeList";
import Category from "../components/Category";
import CategoryList from "../components/Category/CategoryList";
import NewsCategory from "../components/NewsCategory";
import NewsCategoryList from "../components/NewsCategory/NewsCategoryLst";
import ClientList from "../components/Client/ClientList";
import CampaignList from "../components/Campaign/CampaignList";
import SubAdmin from "../components/SubAdmin";
import SubAdminList from "../components/SubAdmin/SubAdminList";
// import SubAdminList from "../components/SubAdmin/SubAdminList";
import Schedule from "../components/Schedule";
import ScheduleList from "../components/Schedule/ScheduleList";
import Role from "../components/Role";
import RoleList from "../components/Role/RoleList";
import AddRole from "../components/Role/AddRole";
import DashboardList from "../components/Dashboard/DashboardList";
import Dashboard from "../components/Dashboard";
import PrivacyList from "../components/PrivacyAndTerms/PrivacyList"
import PrivacyAndTerms from "../components/PrivacyAndTerms";
import BannerList from "../components/BannerSettings/BannerList";
import HomeDashboard from "../components/HomeDashboard";
import HomeDashboardListing from "../components/HomeDashboard/HomeDashboardListing";


const Routes = () => {
  return (
    <BrowserRouter>
      <BrowserRoutes>
        <Route path="/" element={<Index />} />
        <Route path="/home-dashboard" element={<HomeDashboard />}>
          <Route path="" element={<HomeDashboardListing />} />
        </Route>
        <Route path="/users" element={<Users />}>
          <Route path="" element={<UsersList />}></Route>
        </Route>
        <Route path="/rj" element={<Rj />}>
          <Route path="" element={<RjList />}></Route>
        </Route>
        {/* <Route path="/podcast" element={<Podcast />} >
          <Route path="" element={<PodcastList />}></Route>
        </Route> */}
        <Route path="/media" element={<MediaVideo />}>
          <Route path="" element={<PodcastList />}></Route>
          <Route path="video" element={<MediaVideoList />}></Route>
          <Route path="shorts" element={<MediaShortsList />}></Route>
          <Route path="news" element={<MediaNewsList />}></Route>
        </Route>
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="" element={<DashboardList />}></Route>
          <Route path="clients" element={<ClientList />}></Route>
          <Route path="campaign" element={<CampaignList />}></Route>
          <Route path="advertisement" element={<AdvertisementList />}></Route>
        </Route>
        <Route path="/programme" element={<Programme />}>
          <Route path="" element={<ProgrammeList />}></Route>
        </Route>
        <Route path="/category" element={<Category />}>
          <Route path="" element={<CategoryList />}></Route>
        </Route>
        <Route path="/news-category" element={<NewsCategory />}>
          <Route path="" element={<NewsCategoryList />}></Route>
        </Route>
        <Route path="/subadmin" element={<SubAdmin />}>
          <Route path="" element={<SubAdminList />}></Route>
        </Route>
        <Route path="/schedule" element={<Schedule />}>
          <Route path="" element={<ScheduleList />}></Route>
        </Route>
        <Route path="/role" element={<Role />}>
          <Route path="" element={<RoleList />}></Route>
          <Route path="update" element={<AddRole />}></Route>
        </Route>
        <Route path="/settings" element={<PrivacyAndTerms />}>
          <Route path="" element={<PrivacyList />}></Route>
          <Route path="banner" element={<BannerList />}></Route>
        </Route>
        <Route path="/login" element={<AuthModule />}>
          <Route path="" element={<Login />}></Route>
          <Route path="forgot-password" element={<EmailOrPhone />}></Route>
          <Route
            path="forgot-password/otp"
            element={<ForgotPassword />}
          ></Route>
          <Route path="reset-password" element={<ResetPassword />}></Route>
        </Route>
        <Route path={"/*"} element={<Navigate to={"/users"} />} />
      </BrowserRoutes>
    </BrowserRouter>
  );
};

export default Routes;
