import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/Advertisement/advertisementSlice";
import {
  useDeleteAdvertisementDataMutation,
  useGetAdvertisementListDataQuery,
  useStatusChangeMutation,
} from "../../../store/queries/advertisement";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { current } from "@reduxjs/toolkit";
import {
  advertisement as advertisementQuery
} from "../../../store/queries/advertisement";

const useAdvertisementList = () => {
  const dispatch = useDispatch();
  const advertisementState = useSelector((state) => state.advertisement);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const {
    isEditData,
    activeId,
    selectedUpdateRows,
    imageData,
    videoData,
    audioData,
    audioDataTitle,
    typeData,
    audioDataId,
    dataTitle,
    dataId,
  } = useSelector((state) => state.advertisement);
  const [advertisementDelete] = useDeleteAdvertisementDataMutation();
  const [statusChange] = useStatusChangeMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [dataFileIndex, setDataFileIndex] = useState("");

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: advertisement = {},
    isFetching,
    isLoading,
    isSuccess,
    refetch,
  } = useGetAdvertisementListDataQuery({
    length: advertisementState.currentPageSize,
    page: advertisementState.currentPage,
    search: advertisementState.search,
    sort_by: advertisementState.sortBy,
    sort_order: advertisementState.sortOrder,
    status: advertisementState.status,
    category_id: advertisementState.category_id,
    type: advertisementState.type,
  });
  const { data: formData, isSuccess: isUpdateDataSuccess } =
    useGetAdvertisementListDataQuery({ skip: isEditData === false });

  const hasCreatePermission = useMemo(() => {
    let permission =
      advertisement?.data?.original?.permissions?.create_permission;
    return permission ?? false;
  }, [advertisement]);

  const hasEditPermission = useMemo(() => {
    let permission =
      advertisement?.data?.original?.permissions?.edit_permission;
    return permission ?? false;
  }, [advertisement]);
  const hasViewPermission = useMemo(() => {
    let permission =
      advertisement?.data?.original?.permissions?.list_permission;
    return permission ?? false;
  }, [advertisement]);
  const hasDeletePermission = useMemo(() => {
    let permission =
      advertisement?.data?.original?.permissions?.delete_permission;
    return permission ?? false;
  }, [advertisement]);

  const tableHeading = {
    name: { label: "Name", sortable: true },
    title: { label: "Title", sortable: true },
    type: { label: "Type", sortable: true },
    basic_thumbnail: { label: "Basic Thumbnail", sortable: true },
    list_thumbnail: { label: "List Thumbnail", sortable: true },
    ad_campaign: { label: "Ad Campaign", sortable: true },
    media: { label: "Media", sortable: true },
    duration: { label: "Duration", sortable: true },
    status_label: { label: "Status", sortable: true },
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableHeading;
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refetch();
  }, [
    advertisementState.status,
    advertisementState.category_id,
    advertisementState.type,
  ]);

  const deleteAdvertisementDataClick = (item) => {
    setShowDeleteModal(true);
    dispatch(
      updateConfig((state) => {
        state.selectedUpdateRows = item;
      })
    );
  };

  useEffect(() => {
    if (advertisementState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [advertisementState.clearSelection]);

  const deleteAdvertisement = () => {
    advertisementDelete({
      _id: selectedUpdateRows,
    }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
        setShowDeleteModal(false);
        toast.success("Successfully Deleted");
      } else if (response?.error?.status === 422) {
        toast.success("Something went wrong");
      }
    });
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (isEditData && isUpdateDataSuccess) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {
            ...formData?.data?.data,
          };
        })
      );
    }
    // eslint-disable-next-line
  }, [isEditData, isUpdateDataSuccess, formData]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (advertisementState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = advertisementState.sortOrder === "0" ? "1" : "0";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "0";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = ""
      })
    );
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Details";
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
  };

  const handleEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleAdvertisementDataEditClick = (id) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = id[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.currentFormData = advertisement?.data?.original?.data?.id;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.isEditData = true;
        state.activeId = id?.[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Details";
      })
    );
  };

  const handleViewAudio = (data, title, id, type) => {
    let dataList = advertisement?.data?.original?.data;
    let index = dataList.findIndex((item, index) => item?._id === id);
    setDataFileIndex(index);
    setShowAudioModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.audioData = data;
        state.audioDataTitle = title;
        state.typeData = type;
        state.audioDataId = id;
      })
    );
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.imageData = data;
      })
    );
  };

  const handleViewVideo = (data, title, id) => {
    let dataList = advertisement?.data?.original?.data;
    let index = dataList.findIndex((item, index) => item?._id === id);
    setDataFileIndex(index);
    setShowVideoModal(() => true);
    dispatch(
      updateConfig((state) => {
        state.videoData = data;
        state.dataTitle = title;
        state.dataId = id;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
    setShowImageModal(() => false);
    dispatch(
      updateConfig((state) => {
        state.imageData = "";
        state.videoData = "";
        state.audioData = "";
      })
    );
    setShowVideoModal(() => false);
    setShowAudioModal(() => false);
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const handleToggle = (e, data) => {
    let params = {
      _id: data?._id,
    };
    statusChange(params).then((response) => {
      if (response?.data?.status_code == 400) {
        toast.error(response?.data?.message);
      }
    });
    dispatch(
      advertisementQuery.util.updateQueryData(
        "getAdvertisementListData",
        {  
          length: advertisementState.currentPageSize,
          page: advertisementState.currentPage,
          search: advertisementState.search,
          sort_by: advertisementState.sortBy,
          sort_order: advertisementState.sortOrder,
          status: advertisementState.status,
          category_id: advertisementState.category_id,
          type: advertisementState.type,
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.original?.data?.map((res) =>
          res?._id === data?._id
          ? {
            ...res,
            status:data?.status === 0 ? 1 : 0,
          }
          : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              original:{
                ...updatedCachedData.data.original,
                data:newResult
              }
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => (
        <p className="pro-mb-0">{data[feild] ? data[feild] : "-"}</p>
      ),
      title: (feild, data) => (
        <p className="pro-mb-0 pro-min-sm">{data[feild] ? data[feild] : "-"}</p>
      ),
      name: (feild, data) => (
        <p className="pro-mb-0 pro-min-sm">{data[feild] ? data[feild] : "-"}</p>
      ),
      ad_campaign: (feild, data) => (
        <p className="pro-mb-0 pro-min-sm">{data[feild] ? data[feild] : "-"}</p>
      ),
      ad_placement_type: (feild, data) => (
        <p className="pro-mb-0">{data[feild]?.name ? data[feild]?.name : "-"}</p>
      ),
      media: (feild, data) =>
        data?.type === "Image" ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title="View Image"
            onClick={() => handleViewImage(data?.image)}
          />
          </div>
        ) : data?.type === "Video" ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="11.8544" cy="12.0001" r="11.5372" fill="#FA6B07"/>
              <path d="M16.9648 11.1905C17.6315 11.5754 17.6315 12.5377 16.9648 12.9226L9.66422 17.1376C8.99755 17.5225 8.16421 17.0414 8.16421 16.2716L8.16421 7.84152C8.16422 7.07172 8.99755 6.59059 9.66422 6.97549L16.9648 11.1905Z" fill="white"/>
              </svg>
            }
            title="Play Video"
            onClick={() => {
              handleViewVideo(data?.video_response, data?.title, data?._id);
              setShowAudioModal(false);
            }}
          />
          </div>
        ) : data?.type === "Audio" ? (
          <div className="pro-nowrap">
          <IconText
            icon={<span className="material-symbols-outlined">audio_file</span>}
            title="Play Audio"
            onClick={() =>
              handleViewAudio(
                data?.audio_response,
                data?.title,
                data?._id,
                "audio"
              )
            }
          />
          </div>
        ) : null,

      basic_thumbnail: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
      list_thumbnail: (feild, data) =>
        data?.[feild] ? (
          <div className="pro-nowrap">
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Image"}`}
            onClick={() => handleViewImage(data?.[feild])}
          />
          </div>
        ) : (
          <>-</>
        ),
      status_label: (field, data) => {
        const isChecked = data?.status === 1;
        return (
          <>
            {hasEditPermission ? (
              <div className="form-check form-switch ">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={isChecked}
                  onChange={(e) => handleToggle(e, data)}
                />
              </div>
            ) : (
              data?.status_label?.name
            )}
          </>
        );
      },
    };
   
    if (feild === "duration") {
      // Check if the type is "image"
      if (data.type === "Image") {
        // If the type is "image", render "-"
        return "-";
      } else {
        // If the type is not "image", render the loader or common feild
        return data.is_converted === 1
          ? rows?.[feild]?.(feild, data) ?? rows["common"](feild, data)
          : (
            <div className="loader" title="Processing">
              <div className="loader-inner ball-pulse">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          );
      }
    }
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    advertisement,
    activeId,
    advertisementState,
    currentPage: advertisementState.currentPage,
    activeFilter: advertisementState.currentFilter,
    showEditModal,
    paginationOptions,
    isFetching,
    isLoading,
    isSuccess,
    totalRecords: advertisementState.recordsTotal,
    showDeleteModal,
    imageData,
    showImageModal,
    videoData,
    showVideoModal,
    isUpdateDataSuccess,
    isEditData,
    audioData,
    typeData,
    audioDataTitle,
    audioDataId,
    showAudioModal,
    filterShow,
    dataFileIndex,
    setDataFileIndex,
    dataTitle,
    dataId,
    setFilterShow,
    setShowAudioModal,
    handleEditModal,
    handleCreateClick,
    deleteAdvertisementDataClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    handleAdvertisementDataEditClick,
    closeEditModal,
    closeModal,
    getRow,
    deleteAdvertisement,
    refetch,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  };
};

export default useAdvertisementList;
