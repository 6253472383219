import { combineReducers } from "@reduxjs/toolkit";
import { users } from "./queries/users";
import usersSlice from "./slices/Users/usersSlice";
import globalSlice from "./slices/Global";
import rjSlice from "./slices/Rj/rjSlice";
import { rj } from "./queries/rj";
import podcastSlice from "./slices/Podcast/podcastSlice";
import { podcast } from "./queries/podcast";
import mediavideoSlice from "./slices/MediaVideo/mediavideoSlice";
import { mediavideo } from "./queries/mediavideo";
import mediashortsSlice from "./slices/MediaShorts/mediashortsSlice";
// import mediashortsSlice from "./slices/MediaShorts/mediashortsSlice";
import { mediashorts } from "./queries/mediashorts";
import medianewsSlice from "./slices/MediaNews/medianewsSlice";
import { medianews } from "./queries/medianews";
import { advertisement } from "./queries/advertisement";
import advertisementSlice from "./slices/Advertisement/advertisementSlice";
// import { programme } from "./queries/programme";
// import programmeSlice from "./slices/Programme/programmeSlice";
import { programme } from "./queries/programme";
import programmeSlice from "./slices/Programme/programmeSlice"
import { category } from "./queries/category";
import categorySlice from "./slices/Category/categorySlice";
import { newscategory } from "./queries/newscategory";
import newscategorySlice from "./slices/NewsCategory/newscategorySlice";
import { client } from "./queries/client";
import clientSlice from "./slices/Clients/clientSlice";
import { campaign } from "./queries/campaign";
import campaignSlice from "./slices/Campaign/campaignSlice";
import { subadmin } from "./queries/subAdmin";
import subadminSlice from "./slices/SubAdmin/subadminSlice";
import { schedule } from "./queries/schedule";
import scheduleSlice from "./slices/Schedule/scheduleSlice"
import { role } from "./queries/role";
import roleSlice from "./slices/Role/roleSlice"
import { dashboard } from "./queries/dashboard";
import dashboardSlice from "./slices/Dashboard/dashboardSlice";
import { privacy } from "./queries/privacy";
import privacySlice from "./slices/Privacy/privacySlice";
import bannerSlice from "./slices/Banner/bannerSlice";
import { banner } from "./queries/banner";
import { homeDashboard } from "./queries/homeDashboard";
import homeDashboardSlice from "./slices/HomeDashboard/homeDashboardSlice";
import { topPrograms } from "./queries/topPrograms";
import topProgramsSlice from "./slices/TopPrograms/topProgramsSlice";
import { topGenres } from "./queries/topGenres";
import topGenresSlice from "./slices/TopGenres/topGenresSlice";

const appReducer = combineReducers({
  [users.reducerPath]: users.reducer,
  [rj.reducerPath]: rj.reducer,
  [podcast.reducerPath]: podcast.reducer,
  [mediavideo.reducerPath]: mediavideo.reducer,
  [mediashorts.reducerPath]: mediashorts.reducer,
  [medianews.reducerPath]: medianews.reducer,
  [advertisement.reducerPath]: advertisement.reducer,
  [programme.reducerPath]: programme.reducer,
  [category.reducerPath]: category.reducer,
  [newscategory.reducerPath]: newscategory.reducer,
  [client.reducerPath]: client.reducer,
  [campaign.reducerPath]: campaign.reducer,
  [subadmin.reducerPath]: subadmin.reducer,
  [schedule.reducerPath]: schedule.reducer,
  [role.reducerPath]: role.reducer,
  [dashboard.reducerPath]: dashboard.reducer,
  [privacy.reducerPath]: privacy.reducer,
  [banner.reducerPath]: banner.reducer,
  [homeDashboard.reducerPath]: homeDashboard.reducer,
  [topPrograms.reducerPath]: topPrograms.reducer,
  [topGenres.reducerPath]: topGenres.reducer,
  topGenres: topGenresSlice,
  topPrograms: topProgramsSlice,
  homeDashboard: homeDashboardSlice,
  users: usersSlice,
  global: globalSlice,
  rj: rjSlice,
  podcast: podcastSlice,
  mediavideo: mediavideoSlice,
  mediashorts: mediashortsSlice,
  medianews: medianewsSlice,
  advertisement: advertisementSlice,
  programme: programmeSlice,
  category: categorySlice,
  newscategory: newscategorySlice,
  client: clientSlice,
  campaign: campaignSlice,
  subadmin: subadminSlice,
  schedule: scheduleSlice,
  role: roleSlice,
  dashboard: dashboardSlice,
  privacy: privacySlice,
  banner: bannerSlice
});

export default appReducer;
