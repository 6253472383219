import { Pagination } from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import useEpisodeList from "./useEpisodeList";
import Style from "./episodeList.module.scss";
import { useEffect, useRef, useState } from "react";
import TooltipCard from "../../../../Global/TooltipCard";
import { Table } from "../../../Table";


const EpisodeList = () => {
  const {
    isFetching,
    handleSort,
    getRow,
    tableEpisodes,
    filteredData,
    paginationOptions,
    handlePagination,
    handlePageSize,
    topEpisodeState,
    episodeListData,
    currentPage,
    showTooltip,
    tooltipPosition,
    graphData
  } = useEpisodeList();

  const avatarListRef = useRef(null);
  const [avatarListHeight, setAvatarListHeight] = useState();
  useEffect(() => {
    const height = avatarListRef?.current?.getBoundingClientRect()?.height;
    setAvatarListHeight(height);
  }, [showTooltip]);


  return (
    <>
      <div className="pro-pb-3 ">
        <div className="pro-d-flex pro-gap-5 pro-mb-2 pro-items-center">
          <h6 className="pro-mb-0 h4 pro-ttl">Episodes</h6>
        </div>

        <div className="col-12 pro-pb-6">
        <Table
          data={filteredData || []}
          uniqueID={"_id"}
          fields={tableEpisodes}
          SortIcon={<FaSort />}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          assignable={false}
          multiSelect={false}
          editable={false}
          deletable={false}
        />
        {episodeListData?.data?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalPageCount={Math.ceil(
                  episodeListData?.recordsTotal /
                  topEpisodeState?.episodeCurrentPageSize
                )}
                onPageChange={handlePagination}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
                actionIsSearchable = {false}
              />
            )}
            </div>
      </div>
      <div
              className={`
                ${Style.avatar_list_container}  
                ${showTooltip ? Style.active : ""} 
                ${Style.top}
              `}
              ref={avatarListRef}
              style={{
                "--top": tooltipPosition.y,
                "--left": tooltipPosition.x,
                "--card-height": avatarListHeight,
              }}
            >
              <TooltipCard graphData={graphData}/>
            </div>
    </>
  );
};

export default EpisodeList;
