import { Image } from "@wac-ui-dashboard/wac_component_library";
import style from "./emptyData.module.scss";
// import Assets from "../../../assets";
const EmptyData = () => {
  return (
    <div className={style.root}>
      <div className="pro-text-center">
        <div className={style.image_inner}>
          {/* <Image src={Assets.NO_DATA} width={65} height={65} /> */}
          <Image width={65} height={65} />
        </div>
        <h5 className={`pro-ttl h3 ${style.title}`}>No Data Found</h5>
        <p className={`pro-mb-0 ${style.subtitle}`}>
          Sorry we haven’t found any data for you.
        </p>
      </div>
    </div>
  );
};

export default EmptyData;
