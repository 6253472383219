import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CampaignFilterForm from "./CampaignFilterForm";
import {
  resetExtraFilters,
  setStoreFormValues,
} from "../../../store/slices/Campaign/campaignSlice";

const useFilter = ({setShowform,refetch}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.campaign);
 
  const validationSchema = Yup.object({
    status: Yup.string().nullable(),
    start_date: Yup.date().nullable(),
    end_date: Yup.date().nullable(),
  });

  const initialValues = {
    status: state.status,
    start_date: state.start_date,
    end_date: state.end_date,
  };
 
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { status, start_date, end_date  } = values;
      if (
        status === "" &&
        start_date === "" &&
        end_date === "" 
      ) {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
      }
      let data = {
        status,
        start_date,
        end_date,
      };
      if (values) {
        dispatch(setStoreFormValues(values))
      }
    },
  });

  useEffect(() => {
    // Check if status is not empty before refetching
    if (formik.values.status !== '') {
      refetch();
    }
  }, [formik.values.status, refetch]);

  const renderTabContent = () => {
    return (
      <CampaignFilterForm
        formik={formik}
        handleReset={handleReset}
      />
    );
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  return {
    renderTabContent,
  };
};

export default useFilter;
