import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/MediaVideo/mediavideoSlice";
import {
  useCategoryDataListQuery,
  useProgramDataListQuery,
  useUpdateMediaVideoListDataMutation,
} from "../../../store/queries/mediavideo";
import { createNew, getDataById } from "./api";
import { useSelector } from "react-redux";
import Details from "./Details";
import Uploads from "./Uploads";
import {
  contentRatingList,
  programDataList,
} from "../../Podcast/updatePodcast/api";
import { clearConfig } from "dompurify";

const useUpdateMediaVideo = ({ activeId, refetch, isEditData }) => {
  const dispatch = useDispatch();
  const { activeTab, currentFormData, fieldName, cropData } = useSelector(
    (state) => state.mediavideo
  );

  const { data: basicData = {} } = useCategoryDataListQuery();
  const profilefileInputRef = useRef(null);
  const [updateMediaVideo] = useUpdateMediaVideoListDataMutation();
  const nameRegExp = /^[^\s].*$/;
  const maxLength = 300;

  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [moveToTab, setMoveToTab] = useState(false);
  const [show, setShow] = useState(false);
  const [formikImage, setFormikImage] = useState("");
  const [ratingList, setRatingList] = useState([]);
  const [programData, setProgramData] = useState([]);

  const [imageCoverPreview, setImageCoverPreview] = useState(
    currentFormData !== "" ? currentFormData?.list_thumbnail : ""
  );
  const [imageTitleImagePreview, setImageTitleImagePreview] = useState(
    currentFormData !== "" ? currentFormData?.title_image : ""
  );
  const [imageVerticalThumbnailPreview, setImageVerticalThumbnailPreview] = useState(
    currentFormData !== "" ? currentFormData?.vertical_thumbnail : ""
  );
  const [imageBannerPreview, setImageBannerPreview] = useState(
    currentFormData !== "" ? currentFormData?.banner_image : ""
  );
  const [basicThumbnailImageCoverPreview, setBasicThumbnailImageCoverPreview] =
    useState(currentFormData !== "" ? currentFormData?.basic_thumbnail : "");

  useEffect(() => {
    if (currentFormData !== "" && isEditData) {
      setImageCoverPreview(currentFormData?.list_thumbnail ?? "");
      setImageVerticalThumbnailPreview(currentFormData?.vertical_thumbnail ?? "")
      setImageTitleImagePreview(currentFormData?.title_image ?? "");
      setImageBannerPreview(currentFormData?.banner_image ?? "");
      setBasicThumbnailImageCoverPreview(
        currentFormData?.basic_thumbnail ?? ""
      );
      if(currentFormData?.type?._id === 2){
        formik?.setFieldValue("episode", currentFormData?.program_details?.episode_number)
      }
    }
  }, [currentFormData]);

  const [queryParams, setQueryParams] = useState({
    media_id: isEditData ? activeId : "",
    search: "",
    type: "1",
    is_update:
      currentFormData?.type?._id !== undefined &&
      (currentFormData?.type?._id === 1 ? 0 : 1),
  });

  useEffect(() => {
    programDataList(queryParams).then((response) => {
      setProgramData(response?.data);
    });
  }, [queryParams]);

  useEffect(() => {
    if (activeId) {
      getDataById(activeId).then((response) => {
        formik.setErrors("");
        setErrors("");

        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.currentFormData = response?.data?.data;
            })
          );
        } else if (response?.data?.error_code === 403) {
          formik.setErrors(response?.data?.errors);
        }
      });
    }
  }, []);

  useEffect(() => {
    contentRatingList().then((response) => {
      setRatingList(response?.data?.data);
    });
  }, []);

  const defaultRating = ratingList.find((item) => item.code === "unrated");
  const initialContentRating = defaultRating ? [defaultRating] : [];

  let mediavideoVal = Yup.object({
    category: Yup.mixed().required("Category is Required"),
    rj: Yup.mixed().required("Rj is Required"),
    type: Yup.mixed().required("Type is Required"),
    content_rating: Yup.mixed().required("Content Rating is Required"),
    list_thumbnail: Yup.string().required("List Thumbnail is Required"),
    title_image: Yup.string().required("Title Image is Required"),
    vertical_thumbnail: Yup.string().required("Vertical Image is Required"),
    basic_thumbnail: Yup.string().required("Basic Thumbnail is Required"),
    banner_image: Yup.string().required("Banner Image is Required"),
    video: Yup.string().required("Video is Required"),
    title: Yup.string()
      .required("Title is Required")
      .matches(nameRegExp, "Leading space is not allowed"),
    description: Yup.string()
      .required("Description is Required")
      .matches(nameRegExp, "Leading space is not allowed"),
      // description_mal: Yup.string()
      // .required("Description is Required")
      // .matches(nameRegExp, "Leading space is not allowed"),
  });

  let mediavideoUpdateVal = Yup.object({
    category: Yup.mixed().required("Category is Required"),
    rj: Yup.mixed().required("Rj is Required"),
    content_rating: Yup.mixed().required("Content Rating is Required"),
    type: Yup.mixed().required("Type is Required"),
    title: Yup.string().required("Title is Required"),
    description: Yup.string().required("Description is Required"),
    // description_mal: Yup.string().required("Description is Required"),
  });

  const formik = useFormik({
    initialValues: {
      _id: activeId,
      title: null,
      description: null,
      description_mal: null,
      category: null,
      rj: null,
      content_rating: null,
      type: null,
      list_thumbnail: null,
      title_image: null,
      vertical_thumbnail: null,
      banner_image: null,
      video: null,
      basic_thumbnail: null,
      program_details: null,
      episode: null,
    },
    validationSchema: isEditData ? mediavideoUpdateVal : mediavideoVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        let obj = {
          title: values.title,
          description: values.description,
          description_mal: values.description_mal,
          "category[id]": values.category?._id,
          "category[name]": values.category?.name,
          "rj[id]": values.rj?._id,
          "rj[name]": values.rj?.name,
          "content_rating[0][name]": isEditData
            ? values.content_rating?.[0]?.name || values.content_rating?.name
            : values.content_rating?.name,
          "content_rating[0][code]": isEditData
            ? values.content_rating?.[0]?.code || values.content_rating?.code
            : values.content_rating?.code,
          type: values.type?._id,
          status: +Boolean(values.status),
          is_ad_enabled: +Boolean(values.is_ad_enabled),
          is_trending: +Boolean(values.is_trending),
          show_on_banner: +Boolean(values.show_on_banner),
        };

        if (isEditData) {
          obj._id = values._id;
        }
        if (values?.type?._id === 2) {
          obj["program[id]"] = values.program_details?._id;
          obj["program[name]"] = values.program_details?.name;

          const selectedProgram = programData?.data?.find(
            (program) => program._id === values.program_details?._id
          );

          const episodeCount = selectedProgram?.count;
          obj["program[episode_number]"] = isEditData
            ? (currentFormData?.program_details?.episode_number === undefined
              ? values.program_details?.count
              : values.episode?.value ??
                currentFormData?.program_details?.episode_number)
            : values.episode?.value || episodeCount;
        }
        if (
          values.list_thumbnail?.name ||
          values.list_thumbnail?.length === 0
        ) {
          obj.list_thumbnail = values.list_thumbnail;
        }
        if (values.title_image?.name || values.title_image?.length === 0) {
          obj.title_image = values.title_image;
        }
        if (values.vertical_thumbnail?.name || values.vertical_thumbnail?.length === 0) {
          obj.vertical_thumbnail = values.vertical_thumbnail;
        }
        if (values.banner_image?.name || values.banner_image?.length === 0) {
          obj.banner_image = values.banner_image;
        }
        if (
          values.basic_thumbnail?.name ||
          values.basic_thumbnail?.length === 0
        ) {
          obj.basic_thumbnail = values.basic_thumbnail;
        }
        if (
          !isEditData &&
          (values.video?.[0]?.name || values.video?.length === 0)
        ) {
          obj.video = values.video?.[0];
        }
        if (
          isEditData &&
          currentFormData?.is_converted == 1 &&
          !values.video?.[0]?.name
        ) {
          delete obj.video;
        } else if (isEditData && currentFormData?.is_converted == 0) {
          obj.video = "";
        } else if (isEditData && currentFormData?.is_converted == 1) {
          if (values.video?.[0]?.name) {
            obj.video = values.video?.[0];
          }
        }
        // if (values.content_rating) {
        //   values.content_rating.forEach((rating, index) => {
        //     obj[`content_rating[${index}][name]`] = rating.name;
        //     obj[`content_rating[${index}][code]`] = rating.code;
        //   });
        // }

        let formData = new FormData();
        delete obj.category;
        delete obj.rj;
        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });
        if (isEditData === true) {
          updateMediaVideo(formData).then((response) => {
            formik.setErrors("");
            setErrors("");
            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              toast.success("Successfully udpated");
            } else if (response?.data?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.data?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        } else {
          createNew(formData).then((response) => {
            formik.setErrors("");
            setErrors("");
            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              toast.success("Successfully Added");
            } else if (response?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });
  const handleRemovePhotoImage = (e) => {
    formik.setFieldValue("photo", "");
  };

  useEffect(() => {
    // on Edit
    let updateValue = 0;
    if (isEditData && currentFormData?.type?._id === 2) {
      updateValue = 1;
    } else if (isEditData && currentFormData?.type?._id === 1) {
      updateValue = 0;
    }
    setQueryParams((prevParams) => ({
      ...prevParams,
      is_update: updateValue,
    }));
  }, [isEditData, currentFormData]);

  useEffect(() => {
    if (isEditData) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
    }
    // eslint-disable-next-line
  }, [currentFormData, activeId]);

  const closeModal = () => {
    setShow(false);
  };

  const handleCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Details: 0,
        };
        state.activeTab = "Details";
      })
    );
    setShow((state) => (state = false));
  };

  const handleListThumbnailImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "list_thumbnail";
            state.cropData = {
              unit: "px",
              width: 1600,
              height: 900,
              aspect: 16 / 9,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  const handleTitleImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase(); // Get the file extension
      if (extension === "png") {
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "title_image";
            state.cropData = {
              unit: "px",
              width: 300,
              height: 200,
              aspect: 3 / 2,
            };
          })
        );

        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "title_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Please select a PNG file.");
        formik.setFieldError("title_image", "Please select a PNG file.");
      }
    }
  };

  const handleVerticalImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "vertical_thumbnail";
            state.cropData = {
              unit: "px",
              width: 375,
              height: 500,
              aspect: 3 / 4,
            };
          })
        );

        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "vertical_thumbnail",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Please select a file.");
        formik.setFieldError("vertical_thumbnail", "Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  const handleBannerImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "banner_image";
            state.cropData = {
              unit: "px",
              width: 748,
              height: 818,
              aspect: 374 / 409,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  const handleBasicThumnailImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "basic_thumbnail";
            state.cropData = {
              unit: "px",
              width: 600,
              height: 600,
              aspect: 1/1,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "basic_thumbnail",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  // const handlevideo = (e) => {
  //   formik.setFieldValue("video", e?.target?.files);
  // };
  const handlevideo = (e) => {
    const selectedFile = e?.target?.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileType = fileName
        .slice(fileName.lastIndexOf(".") + 1)
        .toLowerCase();
      const allowedExtensions = [
        "mp4",
        "ogx",
        "oga",
        "ogv",
        "ogg",
        "webm",
        "mkv",
        "mov",
      ];
      if (allowedExtensions.includes(fileType)) {
        formik.setFieldValue("video", e?.target?.files);
      } else {
        // Display an error message or handle the case when a file with an invalid extension is selected
        // For example:
        toast.error("Please select a valid video file.");
        // You might want to clear the input in case of an invalid file selection
        e.target.value = "";
      }
    }
  };

  const tabs = [
    {
      label: "Details",
      completed: calculateCompletionStatus([
        "title",
        "description",
        "description_mal",
        // "sub_title",
        "category",
        "rj",
        "type",
        // "status",
        // "program_details",
        ...(formik.values.type?.name === "episode" ? ["program_details"] : []),
      ]),
    },
    {
      label: "Uploads",
      completed: calculateCompletionStatus([
        // "original_thumbnail",
        "list_thumbnail",
        "title_image",
        "vertical_thumbnail",
        "banner_image",
        "video",
        "basic_thumbnail",
      ]),
    },
  ];

  const tabsAndFields = [
    {
      label: "Details",
      fields: [
        "title",
        "description",
        "description_mal",
        "sub_title",
        "category",
        "rj",
        "type",
        "status",
      ],
    },
    {
      label: "Uploads",
      fields: [
        // "original_thumbnail",
        "list_thumbnail",
        "title_image",
        "vertical_thumbnail",
        "banner_image",
        "video",
        "basic_thumbnail",
      ],
    },
  ];

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.errors, moveToTab]);

  function calculateCompletionStatus(fields) {
    let finalArray = [];
    finalArray = fields;
    // eslint-disable-next-line array-callback-return
    var completedFields = finalArray?.filter((field) => {
      if (field == "program_details") {
        return formik.values.type?.name === "episode" && !!formik.values[field];
      }
      return !!formik?.values[field];
    });

    return (completedFields.length / fields.length) * 100;
  }

  const handleTabClick = (tab) => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = tab?.label;
      })
    );
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const generateEpisodeOptions = (count) => {
    const options = [];
    for (let i = 1; i <= count; i++) {
      options.push({ value: i, label: i.toString() });
    }

    return options;
  };

  const handleProgramChange = (value) => {
    let updateValue = 0;
    if (
      isEditData &&
      currentFormData?.program_details &&
      currentFormData?.program_details?._id !== value?._id
    ) {
      updateValue = 0;
    } else if (
      isEditData &&
      currentFormData?.program_details &&
      currentFormData?.program_details?._id == value?._id
    ) {
      updateValue = 1;
    }

    programDataList({ ...queryParams, is_update: updateValue }).then(
      (response) => {
        setProgramData(response?.data);
        const targetObject = response?.data?.data?.find(
          (obj) => obj?._id === value?._id
        );
        formik.setFieldValue("program_details", targetObject || null);
        const count = targetObject?.count || targetObject?.episode_number; // Get the count or episode_number of the selected program
        const episodeOptions = generateEpisodeOptions(count);
        const episodeOptionsLength = episodeOptions?.length;
        formik.setFieldValue(
          "episode",
          episodeOptions[episodeOptionsLength - 1]
        ); // Reset the selected episode
        formik.setFieldTouched("episode", false); // Mark episode field as untouched
      }
    );

    // formik.setFieldValue("program_details", value || null);
    // const count = value?.count || value?.episode_number; // Get the count or episode_number of the selected program
    // const episodeOptions = generateEpisodeOptions(count);
    // const episodeOptionsLength = episodeOptions?.length;
    // formik.setFieldValue("episode", episodeOptions[episodeOptionsLength - 1]); // Reset the selected episode
    // formik.setFieldTouched("episode", false); // Mark episode field as untouched
  };
  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (newText?.length <= 300) {
      formik.setFieldValue("description", newText);
    }
  };

  const handleMalayalamTextChange = (e) => {
    const newText = e.target.value;
    if (newText?.length <= 300) {
      formik.setFieldValue("description_mal", newText);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Details":
        return (
          <Details
            formik={formik}
            tabs={tabs}
            getFieldError={getFieldError}
            categoryList={basicData?.data?.category}
            rjList={basicData?.data?.rjs}
            ratingList={ratingList}
            programList={programData?.data}
            videoType={basicData?.data?.video_type}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
            currentFormData={currentFormData}
            generateEpisodeOptions={generateEpisodeOptions}
            handleProgramChange={handleProgramChange}
            maxLength={maxLength}
            handleTextChange={handleTextChange}
            handleMalayalamTextChange={handleMalayalamTextChange}
            handleChange={handleChange}
          />
        );
      case "Uploads":
        return (
          <Uploads
            formik={formik}
            tabs={tabs}
            isLoading={isLoading}
            getFieldError={getFieldError}
            imageCoverPreview={imageCoverPreview}
            imageTitleImagePreview={imageTitleImagePreview}
            imageBannerPreview={imageBannerPreview}
            basicThumbnailImageCoverPreview={basicThumbnailImageCoverPreview}
            handleListThumbnailImage={handleListThumbnailImage}
            handleTitleImage={handleTitleImage}
            handleBannerImage={handleBannerImage}
            handleBasicThumnailImage={handleBasicThumnailImage}
            imageVerticalThumbnailPreview={imageVerticalThumbnailPreview}
            handleVerticalImage={handleVerticalImage}
            handlevideo={handlevideo}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
            show={show}
            setShow={setShow}
            closeModal={closeModal}
            fieldName={fieldName}
            setImageCoverPreview={setImageCoverPreview}
            setImageTitleImagePreview={setImageTitleImagePreview}
            setImageBannerPreview={setImageBannerPreview}
            setImageVerticalThumbnailPreview={setImageVerticalThumbnailPreview}
            setBasicThumbnailImageCoverPreview={
              setBasicThumbnailImageCoverPreview
            }
            cropData={cropData}
            formikImage={formikImage}
            setFormikImage={setFormikImage}
            currentFormData={currentFormData}
            handleClick={handleClick}
          />
        );
      default:
        return null;
    }
  };

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.activeTab = updatedTab?.label;
      })
    );
  }

  const handleMoveToErrorTab = () => {
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab && firstErrorTab !== activeTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );
      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };
  const handleChange = (value, formik) => {
    let updateValue = 0;
    formik.setFieldValue("type", value || null);
    // Check for type change from single  to episode on edit
    if (isEditData && currentFormData?.type?._id == 1 && value?._id == 2) {
      updateValue = 0;
    }

    // Check for type change from 2 to 1
    if (isEditData && currentFormData?.type?._id == 2 && value?._id == 1) {
      updateValue = null;
    }

    if (!isEditData && value?._id == "1") {
      updateValue = null;
    } else if (!isEditData && value?._id == "2") {
      updateValue = 0;
    }

    // Fetch program data with updated queryParams
    programDataList({ ...queryParams, is_update: updateValue }).then(
      (response) => {
        setProgramData(response?.data);
      }
    );
  };

  return {
    formik,
    errors,
    tabs,
    activeTab,
    isLoading,
    handleMoveToErrorTab,
    handleCloseModal,
    handleRemovePhotoImage,
    handleTabClick,
    renderTabContent,
    handleListThumbnailImage,
    handleTitleImage,
    handleBannerImage,
    handleBasicThumnailImage,
    profilefileInputRef,
    imageCoverPreview,
    imageTitleImagePreview,
    imageBannerPreview,
    basicThumbnailImageCoverPreview,
    handlevideo,
    handleTitleImage,
    imageVerticalThumbnailPreview,
    handleVerticalImage
  };
};

export default useUpdateMediaVideo;
