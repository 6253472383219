import { useState } from "react";
import { MyVerticallyCenteredModal } from "../../Global/MyVerticallyCenteredModal";

const ExportData = ({ dashboardData, adcampaign, activeTab }) => {

  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <button
        className="pro-btn pro-btn-primary lg"
        type="button"
        onClick={() => setModalShow(true)}
      >
        Export Data
      </button>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        adCampaign={adcampaign}
        activeTab={activeTab}
        from_date={dashboardData?.data?.from_date}
        to_date={dashboardData?.data?.to_date}
        // closeModal={closeModal}
      />
    </>
  );
};

export default ExportData;
