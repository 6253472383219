import { getAxiosInstance } from "../../../api";

  export const getDataById = async (id) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/advertisement/campaign/edit?_id=${id}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const createNew = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/advertisement/campaign/store",data);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const countryList = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/advertisement/campaign/countries`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const stateList = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/advertisement/campaign/states-by-country?iso2[0]=${data}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const cityList = async (country,state) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/advertisement/campaign/cities-by-country-state?iso2[0]=${country}&state_code[0]=${state}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };

 