import {
  HeadingGroup,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
  ConfirmationBox,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import useMediaNewsList from "./useMediaNewsList";
import UpdateMediaNews from "../UpdateMediaNews";
import Player from "../../Global/Player";
import Filter from "../Filter";
import Style from "../medianews.module.scss";
import { ImageCard } from "../../Global/ImageCard";

const MediaNewsList = () => {
  const {
    medianews,
    medianewsState,
    filters,
    activeId,
    currentPage,
    activeFilter,
    paginationOptions,
    isFetching,
    isLoading,
    showDeleteModal,
    typeData,
    isEditData,
    audioData,
    dataTitle,
    dataId,
    showAudioModal,
    filterShow,
    dataFileIndex,
    imageData,
    showImageModal,
    basiThumbnailImage,
    showBasicThumbnailImageModal,
    setDataFileIndex,
    setFilterShow,
    handleEditModal,
    handleCreateClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    refetch,
    handleSort,
    handleSearch,
    handleMedianewsDataEditClick,
    closeModal,
    deleteMedianewsDataClick,
    getRow,
    deleteMedianews,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  } = useMediaNewsList();

  return (
    <>
      <HeadingGroup
        title={"News"}
        buttonTitle={hasCreatePermission ?? false ? "Add New" : ""}
        extraClassName={`pro-mb-4`}
        handleClick={handleCreateClick}
      />
      <div className="col-12 pro-pb-6">
        <SearchFilters
          data={filters}
          showDateRange={false}
          activeFilter={activeFilter}
          onSearchInput={handleSearch}
          searchInputProps={{ value: medianewsState?.search }}
          loading={isLoading}
          showActions={false}
          handleActionClick={handleEditModal}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          extraFilters={
            <div className="col-auto">
              <button
                className={`pro-btn pro-btn-outline pro-h-100 pro-items-center`}
                onClick={() => setFilterShow(true)}
              >
                <span className="material-symbols-outlined">tune</span>
                <span>Filter</span>
              </button>
            </div>
          }
        />
        <Table
          data={medianews?.data?.original?.data || []}
          uniqueID={"_id"}
          fields={tableFields}
          showCheckBox={hasEditPermission || hasDeletePermission ? true : false}
          SortIcon={<FaSort />}
          handleEdit={handleMedianewsDataEditClick}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          handleDelete={deleteMedianewsDataClick}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={medianewsState.currentPageSize}
          assignable={false}
          multiSelect={false}
          clear={medianewsState.clearSelection}
          editable={hasEditPermission ? true : false}
          deletable={hasDeletePermission ? true : false}
        />
        {medianews?.data?.original?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              medianews?.data?.original?.recordsTotal /
                medianewsState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      </div>
      <ModalLayout
        centered
        show={showDeleteModal}
        handleClose={closeDeleteModal}
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={`Are you sure you want to delete?`}
            isRight={true}
            cancelText={`Cancel`}
            submitText={`Delete`}
            cancelAction={closeDeleteModal}
            submitAction={deleteMedianews}
          >
            ConfirmationBox
          </ConfirmationBox>
        </div>
      </ModalLayout>

      <OffCanvasLayout
        show={medianewsState.showCreateModal}
        handleClose={closeModal}
        title={`${isEditData ? "Update News Details" : "Create News"}`}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
        backdrop={"static"}
      >
        <UpdateMediaNews
          activeId={activeId}
          refetch={refetch}
          isEditData={isEditData}
        />
      </OffCanvasLayout>

      <OffCanvasLayout
        centered={true}
        show={filterShow}
        handleClose={() => {
          setFilterShow(false);
        }}
        title={"Filter"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <Filter setShowform={setFilterShow} refetch={refetch}/>
      </OffCanvasLayout>
      <ModalLayout show={showImageModal} handleClose={closeModal}>
        <ImageCard data={imageData} handleClose={closeModal} />
      </ModalLayout>
      <ModalLayout show={showBasicThumbnailImageModal} handleClose={closeModal}>
        <ImageCard data={basiThumbnailImage} handleClose={closeModal} />
      </ModalLayout>

      {showAudioModal && (
        <Player
          data={audioData}
          dataFileIndex={dataFileIndex}
          setDataFileIndex={setDataFileIndex}
          typeData={typeData}
          title={dataTitle}
          dataList={medianews?.data?.original?.data}
          dataId={dataId}
          handleClose={closeModal}
        />
      )}
    </>
  );
};

export default MediaNewsList;
