import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  sortBy: "",
  sortOrder: "",
  search: "",
  showCreateModal: false,
  activeId: null,
  currentFormData: [null],
  campaignLists: [null],
  selectedCampaignId: null,
  activeTab: "Details",
  primary_radio_file: "",
  completedTabs: {
    Details: false,
    Uploads: false,
  },
  CompletedPercentage: {
    Details: 0,
    Uploads: 0,
  },
  isEditData: false,
  status: "",
  category_id: "",
  type: "",
  clearSelection: false,
  selectedUpdateRows: [],
  imageData: "",
  videoData: "",
  audioData: "",
  audioDataTitle: "",
  typeData: "",
  audioDataId: "",
  dataTitle: "",
  dataId: "",
  currentFormData:[null],
  fieldName: "",
  cropData: "",
};

const advertisementSlice = createSlice({
  name: "advertisement",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.status = action.payload.status;
      state.category_id = action.payload.category_id;
      state.type = action.payload.type;
    },
    resetExtraFilters: (state, action) => {
      state.status = "";
      state.category_id = "";
      state.type = "";
    },
  },
});

export const { updateConfig, setStoreFormValues, resetExtraFilters } =
  advertisementSlice.actions;

export default advertisementSlice.reducer;
