import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/Advertisement/advertisementSlice";
import {
  useGetAdvertisementFormDataByIdQuery,
  useUpdateAdvertisementListDataMutation,
} from "../../../store/queries/advertisement";
import {
  campaignList,
  categoryDataList,
  clientList,
  createNew,
  getDataById,
  placementTypeList,
} from "./api";
import { useSelector } from "react-redux";
import Details from "./Details";
import Uploads from "./Uploads";

const useUpdateAdvertisement = ({ activeId, refetch, isEditData }) => {

  
  const dispatch = useDispatch();
  const { activeTab, currentFormData, fieldName, cropData } = useSelector(
    (state) => state.advertisement
  );
  const profilefileInputRef = useRef(null);
  const { data: formData = {} } = useGetAdvertisementFormDataByIdQuery();
  const nameRegExp = /^[^\s].*$/;

  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [adType, setAdType] = useState([]);
  const [adPlacementType, setAdPlacementType] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const [campaignsList, setCampaignsList] = useState([]);
  const [moveToTab, setMoveToTab] = useState(false);
  const [show, setShow] = useState(false);
  const [formikImage, setFormikImage] = useState("");

  const [imagePreview, setImagePreview] = useState(
    currentFormData !== "" ? currentFormData?.image : ""
  );

  const [imageCoverPreview, setImageCoverPreview] = useState(
    currentFormData !== "" ? currentFormData?.list_thumbnail : ""
  );
  const [basicThumbnailImageCoverPreview, setBasicThumbnailImageCoverPreview] =
    useState(currentFormData !== "" ? currentFormData?.basic_thumbnail : "");

  useEffect(() => {
    if (currentFormData !== "" && isEditData) {
      setImagePreview(currentFormData?.image ?? "");
      setImageCoverPreview(currentFormData?.list_thumbnail ?? "");
      setBasicThumbnailImageCoverPreview(
        currentFormData?.basic_thumbnail ?? ""
      );
    }
  }, [currentFormData]);

  useEffect(() => {
    if (activeId) {
      getDataById(activeId).then((response) => {
        formik.setErrors("");
        setErrors("");

        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.currentFormData = response?.data?.data;
            })
          );
        } else if (response?.data?.error_code === 403) {
          formik.setErrors(response?.data?.errors);
        }
      });
    }
  }, []);

  const [updateAdvertisement] = useUpdateAdvertisementListDataMutation();

  let medianewsVal = Yup.object({
    type: Yup.mixed().required("Type is Required"),
    ad_campaign: Yup.mixed().required("Campaign is Required"),
    title: Yup.string()
      .required("Title is Required")
      .matches(nameRegExp, "Leading space is not allowed"),
    name: Yup.string()
      .required("Name is Required")
      .matches(nameRegExp, "Leading space is not allowed"),
    // sub_title: Yup.mixed().required("*Required"),
    description: Yup.string()
      .required("Description is Required")
      .matches(nameRegExp, "Leading space is not allowed"),
    // ad_placement_type: Yup.mixed().required("Placement Type is Required66"),
    website_link: Yup.string()
      .required("Website Link is Required")
      .url("Invalid URL format"),
    client: Yup.mixed().required("Client is Required"),
  });

  let medianewsUpdateVal = Yup.object({
    type: Yup.mixed().required("Type is Required"),
    ad_campaign: Yup.mixed().required("Campaign is Required"),
    title: Yup.string()
      .required("Ttile is Required")
      .matches(nameRegExp, "Leading space is not allowed"),
    name: Yup.string()
      .required("Name is Required")
      .matches(nameRegExp, "Leading space is not allowed"),
    // sub_title: Yup.mixed().required("*Required"),
    description: Yup.string()
      .required("Description is Required")
      .matches(nameRegExp, "Leading space is not allowed"),
    // ad_placement_type: Yup.mixed().required("Placement Type is Required"),
    website_link: Yup.string()
      .required("Website Link is Required")
      .url("Invalid URL format"),
    client: Yup.mixed().required("Client is Required"),
  });

  const formik = useFormik({
    initialValues: {
      _id: activeId,
      name: "",
      title: "",
      // sub_title: "",
      description: "",
      type: "",
      ad_campaign: "",
      video: "",
      audio: "",
      image: "",
      list_thumbnail: "",
      basic_thumbnail: "",
      ad_placement_type: "",
      website_link: "",
      client: "",
    },
    enableReinitialize: true,
    validationSchema: isEditData ? medianewsUpdateVal : medianewsVal,

    validate: (values) => {
      let errors = {};
      if (values.ad_placement_type === "") {
        errors.ad_placement_type = "Placement Type is required";
      }
      if (values.ad_placement_type !== "" && values?.type !== "") {
        if(values?.type?._id === 3) {
          if(values?.image === "" && (values?.ad_placement_type?._id === 4 || values?.ad_placement_type?._id === 7)) {
            errors.image = "Image is Required"
          }
          if(values?.list_thumbnail === "" && (values?.ad_placement_type?._id === 7 || values?.ad_placement_type?._id === 3)) {
            errors.list_thumbnail = "List Thumbnail is Required"
          }
          if(values?.basic_thumbnail === "" && (values?.ad_placement_type?._id === 3)) {
            errors.basic_thumbnail = "Basic Thumbnail is Required"
          }
        }
        
      }

      return errors;
    },

    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        let obj = {
          name: values.name,
          title: values.title,
          // sub_title: values.sub_title,
          description: values.description,
          "ad_campaign[id]": values.ad_campaign?._id,
          "ad_campaign[name]": values.ad_campaign?.name,
          type: values.type?._id,
          ad_placement_type: values?.ad_placement_type?._id,
          website_link: values?.website_link,
          client: values?.client?._id,
          status: +Boolean(values.status),
        };

        if (isEditData) {
          obj._id = values._id;
        }
        // if (
        //   values.list_thumbnail?.name ||
        //   values.list_thumbnail?.length === 0
        // ) {
        //   obj.list_thumbnail = values.list_thumbnail;
        // }
        // if (
        //   values.basic_thumbnail?.name ||
        //   values.basic_thumbnail?.length === 0
        // ) {
        //   obj.basic_thumbnail = values.basic_thumbnail;
        // }
        if (
          values?.type?._id === 1 &&
          (values.video?.[0]?.name || values.video?.length === 0)
        ) {
          obj.video = values.video?.[0];
        } else if (
          values?.type?._id === 2 &&
          (values.audio?.[0]?.name || values.audio?.length === 0)
        ) {
          obj.audio = values.audio?.[0];
        }
        //  else if (
        //   values?.type?._id === 3 &&
        //   (values.image?.name || values.image?.length === 0)
        // ) {
        //   obj.image = values.image;
        // }
        else if(values?.type?._id ===3)
        {
          if(formik.values?.ad_placement_type?._id === 7)
          {
            if((values.image?.name || values.image?.length === 0) && (values.list_thumbnail?.name || values.list_thumbnail?.length === 0))
            {
              obj.image = values.image;
              obj.list_thumbnail = values.list_thumbnail;
            }
          } else if(values?.ad_placement_type?._id === 3)
            {
              if((values.list_thumbnail?.name || values.list_thumbnail?.length === 0) && (values.basic_thumbnail?.name || values.basic_thumbnail?.length === 0))
              {
                obj.list_thumbnail = values.list_thumbnail;
                obj.basic_thumbnail = values.basic_thumbnail;
              }
            }
            else if(values?.ad_placement_type?._id === 4)
            {
              if((values.image?.name || values.image?.length === 0))
              {
                obj.image = values.image;
              }
            }
        
          }
        
        // if(currentFormData?.is_converted == 1){
        //   delete obj.video;
        //   delete obj.audio;
        // }else if(isEditData && currentFormData?.is_converted == 0){
        //   obj.video =""
        //   obj.audio =""
        // }

        let formData = new FormData();
        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });
     
        if (isEditData === true) {
          updateAdvertisement(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              toast.success("Successfully updated");
            } else if (response?.data?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.data?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        } else {
          createNew(formData).then((response) => {
            formik.setErrors("");
            setErrors("");
            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              toast.success("Successfully Added");
            } else if (response?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });


  const handleRemovePhotoImage = (e) => {
    formik.setFieldValue("photo", "");
  };

  useEffect(() => {
    categoryDataList().then((response) => {
      setCategoryList(response?.data?.data?.category);
      setAdType(response?.data?.data?.type);
    });
    clientList().then((response) => {
      setClientsList(response?.data?.data);
    });
  }, []);

  useEffect(() => {
    if (formik?.values?.client) {
      campaignList(formik?.values?.client?._id).then((response) => {
        setCampaignsList(response?.data?.data);
      });
    }
  }, [formik?.values?.client?._id]);

  useEffect(() => {
    if (formik?.values?.type?._id) {
      placementTypeList(formik?.values?.type).then((response) => {
        setAdPlacementType(response?.data?.data);
      });
    }
  }, [formik?.values?.type?._id]);

  useEffect(() => {
    if (isEditData) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
    }
    // eslint-disable-next-line
  }, [currentFormData]);

  const closeModal = () => {
    setShow(false);
  };

  const handleCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Details: 0,
        };
        state.activeTab = "Details";
      })
    );
    formik.resetForm();
    setShow((state) => (state = false));
  };

  const handleImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "image";
            state.cropData = {
              unit: "px",
              width: 400,
              height: 500,
              aspect: 4 / 5,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  const handleThumbnail = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "list_thumbnail";
            state.cropData = {
              unit: "px",
              width: 1600,
              height: 900,
              aspect: 16/9,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  const handleBasicThumbnail = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "basic_thumbnail";
            state.cropData = {
              unit: "px",
              width: 100,
              height: 100,
              aspect: 1,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  // const handlevideo = (e) => {
  //   formik.setFieldValue("video", e?.target?.files);
  // };
  const handlevideo = (e) => {
    const selectedFile = e?.target?.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileType = fileName.slice(fileName.lastIndexOf(".") + 1).toLowerCase();
      const allowedExtensions = ["mp4", "ogx", "oga", "ogv", "ogg", "webm", "mkv", "mov"];
      if (allowedExtensions.includes(fileType)) {
        formik.setFieldValue("video", e?.target?.files);
      } else {
        // Display an error message or handle the case when a file with an invalid extension is selected
        // For example:
        toast.error("Please select a valid video file.");
        // You might want to clear the input in case of an invalid file selection
        e.target.value = "";
      }
    }
  };

  // const handleSong = (e) => {
  //   formik.setFieldValue("audio", e?.target?.files);
  // };

  const handleSong = (e) => {
    const selectedFile = e?.target?.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileType = fileName.slice(fileName.lastIndexOf(".") + 1);
      if (fileType.toLowerCase() === "mp3" || fileType.toLowerCase() === "wav") {
        formik.setFieldValue("audio", e?.target?.files);
      } else {
        // Display an error message or handle the case when a file other than MP3 is selected
        // For example:
        toast.error("Please select an MP3 or WAV file.");
        // You might want to clear the input in case of an invalid file selection
        e.target.value = "";
      }
    }
  }; 

  const tabs = [
    {
      label: "Details",
      completed: calculateCompletionStatus([
        "name",
        "title",
        // "sub_title",
        "description",
        "type",
        "ad_campaign",
        "ad_placement_type",
        "website_link",
        // "status",
        "client",
      ]),
    },
    // {
    //   label: "Uploads",
    //   completed: calculateUploadsCompletionStatus(formik?.values?.type?.name),
    // },
  ];
  // if (formik.values?.type?._id) {
  //   tabs.push({
  //     label: "Uploads",
  //     fields: ["image"],
  //   });
  // }

  function calculateUploadsCompletionStatus(Type) {
    switch (Type) {
      case 3:
        if(formik?.values?.ad_placement_type?._id === 4) {
          return calculateCompletionStatus([
            "image"
          ]);
        }
        if(formik?.values?.ad_placement_type?._id === 3) {
          return calculateCompletionStatus([
            "list_thumbnail",
            "basic_thumbnail"
          ]);
        }
        if(formik?.values?.ad_placement_type?._id === 7) {
          return calculateCompletionStatus([
            "image",
            "list_thumbnail"
          ]);
        }
        
        
      case 1:
        return calculateCompletionStatus([
          "video",
          // "list_thumbnail",
          // "basic_thumbnail",
        ]);
      case 2:
        return calculateCompletionStatus([
          "audio",
          "list_thumbnail",
          "basic_thumbnail",
        ]);
      default:
        return 0; // Default completion status if ad_placement_type is not set or unrecognized
    }
  }

  const tabsAndFields = [
    {
      label: "Details",
      fields: [
        "name",
        "title",
        // "sub_title",
        "description",
        "ad_placement_type",
        "website_link",
        "status",
        "client",
        "ad_campaign",
      ],
    },
    // {
    //   label: "Uploads",
    //   fields: ["image", "list_thumbnail", "basic_thumbnail"],
    // },
  ];
  // if (formik.values?.type?._id) {
  //   tabsAndFields.push({
  //     label: "Uploads",
  //     fields: ["image"],
  //   });
  // }

  if (formik.values.type) {
    tabs.push({
      label: "Uploads",
      completed: calculateUploadsCompletionStatus(formik?.values?.type?._id),
    });
  
    tabsAndFields.push({
      label: "Uploads",
      fields: ["image", "list_thumbnail", "basic_thumbnail"],
    });
  }
  

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.errors, moveToTab]);

  function calculateCompletionStatus(fields) {
    let finalArray = [];
    finalArray = fields;
    // eslint-disable-next-line array-callback-return
    var completedFields = finalArray?.filter((field) => {
      return !!formik?.values[field];
    });

    return (completedFields.length / fields.length) * 100;
  }

  const handleTabClick = (tab) => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = tab?.label;
      })
    );
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Details":
        return (
          <Details
            formik={formik}
            tabs={tabs}
            formData={formData?.data}
            getFieldError={getFieldError}
            categoryList={categoryList}
            adType={adType}
            clientsList={clientsList}
            campaignsList={campaignsList}
            adPlacementType={adPlacementType}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
            currentFormData={currentFormData}
            setImagePreview={setImagePreview}
            setImageCoverPreview={setImageCoverPreview}
            setBasicThumbnailImageCoverPreview={
              setBasicThumbnailImageCoverPreview
            }
          />
        );
      case "Uploads":
        // return formik.values?.type?._id ? (
        return (
          <Uploads
            formik={formik}
            formData={formData?.data}
            tabs={tabs}
            isLoading={isLoading}
            getFieldError={getFieldError}
            imagePreview={imagePreview}
            imageCoverPreview={imageCoverPreview}
            handleImage={handleImage}
            handleThumbnail={handleThumbnail}
            handleBasicThumbnail={handleBasicThumbnail}
            basicThumbnailImageCoverPreview={basicThumbnailImageCoverPreview}
            handlevideo={handlevideo}
            handleSong={handleSong}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
            show={show}
            setShow={setShow}
            closeModal={closeModal}
            fieldName={fieldName}
            setImagePreview={setImagePreview}
            setImageCoverPreview={setImageCoverPreview}
            setBasicThumbnailImageCoverPreview={
              setBasicThumbnailImageCoverPreview
            }
            cropData={cropData}
            formikImage={formikImage}
            setFormikImage={setFormikImage}
            currentFormData={currentFormData}
            handleClick={handleClick}
          />
          // ) : null;
        );
      default:
        return null;
    }
  };

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.activeTab = updatedTab?.label;
      })
    );
  }

  const handleMoveToErrorTab = () => {
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab && firstErrorTab !== activeTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );
      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  return {
    formik,
    errors,
    tabs,
    activeTab,
    isLoading,
    handleMoveToErrorTab,
    handleCloseModal,
    handleRemovePhotoImage,
    categoryList,
    handleImage,
    handleThumbnail,
    handleBasicThumbnail,
    handlevideo,
    handleSong,
    handleTabClick,
    renderTabContent,
    imagePreview,
    imageCoverPreview,
    basicThumbnailImageCoverPreview,
    profilefileInputRef,
    currentFormData,
    adType,
    adPlacementType,
    clientsList,
    campaignsList,
  };
};

export default useUpdateAdvertisement;
