import { Input, Button } from "@wac-ui-dashboard/wac_component_library";
import useDetails from "./useDetails";
import Select from "react-select";

const Details = ({
  isEditData,
  formik,
  categoryList,
  rjList,
  programList,
  videoType,
  getFieldError,
  currentFormData,
  generateEpisodeOptions,
  handleProgramChange,
  maxLength,
  handleTextChange,
  handleMalayalamTextChange,
  ratingList,
  handleChange,
}) => {
  const { handleCloseModal, handleNext } = useDetails({
    formik,
  });

  const matchedProgramsCount = programList?.filter(
    (m) => m?._id === formik.values.program_details?._id
  );

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Title (Max 60 Char)*"}
        type="text"
        id="title"
        name="title"
        className={`pro-input lg ${getFieldError("title") && " error"}`}
        {...formik.getFieldProps("title")}
        error={getFieldError("title")}
        errorMessage={getFieldError("title")}
        maxLength={60}
      />

      <div className="input-wrap pro-mb-4">
        <div className="pro-d-flex pro-justify-between pro-mb-1 pro-w-100">
          <label htmlFor="" className="pro-font-sm pro-fw-medium">
            Description*
          </label>
          <span className="pro-font-xs pro-opacity-60">
            {formik.values.description !== null ? (
              <>
                {formik.values.description?.length}/{maxLength}
              </>
            ) : (
              <>
                {0}/{maxLength}
              </>
            )}
          </span>
        </div>
        <textarea
          id="description"
          rows={4}
          name="description"
          className={`pro-input lg ${
            formik.touched.description && formik.errors.description && " error"
          }`}
          {...formik.getFieldProps("description")}
          value={formik.values.description}
          onChange={handleTextChange}
        ></textarea>
        {formik.touched.description && formik.errors.description && (
          <span className="error-text">{formik.errors.description}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <div className="pro-d-flex pro-justify-between pro-mb-1 pro-w-100">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Description in Malayalam
          </label>
          <span className="pro-font-xs pro-opacity-60">
            {formik.values.description_mal !== null ? (
              <>
                {formik.values.description_mal?.length}/{maxLength}
              </>
            ) : (
              <>
                {0}/{maxLength}
              </>
            )}
          </span>
        </div>
        <textarea
          id="description_mal"
          rows={4}
          name="description_mal"
          className={`pro-input lg ${
            formik.touched.description_mal && formik.errors.description_mal && " error"
          }`}
          {...formik.getFieldProps("description_mal")}
          value={formik.values.description_mal}
          onChange={handleMalayalamTextChange}
        ></textarea>
        {formik.touched.description_mal && formik.errors.description_mal && (
          <span className="error-text">{formik.errors.description_mal}</span>
        )}
      </div>


      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Category*
        </label>
        <Select
          id="category"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg ${getFieldError("category") && " error"}`}
          classNamePrefix="pro-input"
          options={categoryList}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={categoryList?.filter(
            (m) => formik.values.category?._id === m?._id
          )}
          onBlur={formik.handleBlur("category")}
          onChange={(value) => {
            formik.setFieldValue("category", value || null);
          }}
        />
        {getFieldError("category") && (
          <span className="error-text">{getFieldError("category")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Rj*
        </label>
        <Select
          id="rj"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg ${getFieldError("rj") && " error"}`}
          classNamePrefix="pro-input"
          options={rjList}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={rjList?.filter((m) => formik.values.rj?._id === m?._id)}
          onBlur={formik.handleBlur("rj")}
          onChange={(value) => {
            formik.setFieldValue("rj", value || null);
          }}
        />
        {getFieldError("rj") && (
          <span className="error-text">{getFieldError("rj")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Content Rating*
        </label>
        <Select
          id="content_rating"
          placeholder={"Select"}
          className={`pro-input lg ${
            getFieldError("content_rating") && " error"
          }`}
          classNamePrefix="pro-input"
          // isMulti
          options={ratingList}
          getOptionValue={(option) => option?.code}
          getOptionLabel={(option) => option?.name}
          // value={formik.values.content_rating?.map((selectedOption) => ({
          //   code: selectedOption.code,
          //   name: selectedOption.name,
          // }))}
          // onChange={(value) => {
          //   formik.setFieldValue(
          //     "content_rating",
          //     // Map selected option values to their corresponding objects from ratingList
          //     value.map((selectedOption) =>
          //       ratingList.find((option) => option.code === selectedOption.code)
          //     )
          //   );
          // }}
          value={formik.values.content_rating}
          onChange={(selectedOption) => {
            formik.setFieldValue("content_rating", selectedOption);
          }}
          onBlur={formik.handleBlur("content_rating")}
        />

        {getFieldError("content_rating") && (
          <span className="error-text">{getFieldError("content_rating")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Type*
        </label>
        <Select
          id="type"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg ${getFieldError("type") && " error"}`}
          classNamePrefix="pro-input"
          options={videoType}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={videoType?.filter((m) => formik.values.type?._id === m?._id)}
          onBlur={formik.handleBlur("type")}
          // onChange={(value) => {
          //   formik.setFieldValue("type", value || null);
          // }}
          onChange={(value) => handleChange(value, formik)}
        />
        {getFieldError("type") && (
          <span className="error-text">{getFieldError("type")}</span>
        )}
      </div>
      {formik.values?.type?._id === 2 ? (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Program*
          </label>
          <Select
            id="program_details"
            isClearable={true}
            placeholder={"Select"}
            className={`pro-input lg ${
              getFieldError("program_details") && " error"
            }`}
            classNamePrefix="pro-input"
            options={programList}
            getOptionValue={(option) => option?._id}
            getOptionLabel={(option) => option?.name}
            value={programList?.filter((m) =>
              formik.values.program_details?.program_name
                ? formik.values.program_details?.program_name === m?.name
                : formik.values.program_details?.name === m?.name
            )}
            // value={programList?.filter(
            //   (m) => formik.values.program_details?._id === m?._id
            // )}
            onBlur={formik.handleBlur("program_details")}
            // onChange={(value) => {
            //   formik.setFieldValue("program_details", value || null);
            // }}
            onChange={(value) => handleProgramChange(value)}
          />
          {getFieldError("program_details") && (
            <span className="error-text">
              {getFieldError("program_details")}
            </span>
          )}
        </div>
      ) : (
        ""
      )}

      {formik.values.type?._id === 2 && formik.values.program_details ? (
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Episode Number*
          </label>
          <Select
            id="episode"
            isClearable={true}
            placeholder={"Select"}
            className={`pro-input lg ${getFieldError("episode") && " error"}`}
            classNamePrefix="pro-input"
            options={generateEpisodeOptions(matchedProgramsCount?.[0]?.count)}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            onBlur={formik.handleBlur("episode")}
            onChange={(value) => {
              formik.setFieldValue("episode", value || null);
            }}
            value={
              !isEditData
                // ? generateEpisodeOptions(
                //     formik.values.program_details?.count ||
                //       formik.values.program_details?.episode_number
                //   ).find(
                //     (option) =>
                //       option?.value ===
                //       // formik.values.episode?.value
                //       // ? formik.values.episode?.value
                //       // :
                //       formik.values.program_details?.count
                //   )
                ? formik?.values?.episode
                : 
                // programList?.some(
                //     (m) =>
                //       m?.count ===
                //         formik.values.program_details?.episode_number ||
                //       formik.values.program_details?.count
                //   )
                // ?
                 generateEpisodeOptions(
                  matchedProgramsCount?.[0]?.count
                  )?.filter(
                    (m) =>
                      (formik.values.episode?.value ||
                        formik.values.program_details?.episode_number) ===
                      m?.value
                  )
                // : null
            }
          />
          {getFieldError("episode") && (
            <span className="error-text">{getFieldError("episode")}</span>
          )}
        </div>
      ) : (
        ""
      )}

      <div className="row">
        {isEditData && currentFormData?.is_converted === 1 ? (
          <div className="col-auto">
            <div className="input-wrap pro-mb-4 pro-flex-row pro-items-center">
              <div className="form-check form-switch pro-mb-0">
                <input
                  type="checkbox"
                  className="form-check-input cursor-pointer"
                  id="status"
                  name="status"
                  checked={Boolean(formik.values?.status) ?? false}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "status",
                      !Boolean(formik.values?.status) ?? false
                    );
                  }}
                />
              </div>
              <label
                htmlFor=""
                className="pro-font-sm pro-lh-base pro-fw-medium"
              >
                Status
              </label>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="col-auto">
          <div className="input-wrap pro-mb-4  pro-flex-row pro-items-center">
            <div className="form-check form-switch pro-mb-0">
              <input
                type="checkbox"
                className="form-check-input cursor-pointer"
                id="is_ad_enabled"
                name="is_ad_enabled"
                checked={Boolean(formik.values?.is_ad_enabled) ?? false}
                onChange={(e) => {
                  formik.setFieldValue(
                    "is_ad_enabled",
                    !Boolean(formik.values?.is_ad_enabled) ?? false
                  );
                }}
              />
            </div>
            <label htmlFor="" className="pro-font-sm pro-lh-base pro-fw-medium">
              Ad
            </label>
          </div>
        </div>
        <div className="col-auto">
          <div className="input-wrap pro-mb-4  pro-flex-row pro-items-center">
            <div className="form-check form-switch pro-mb-0">
              <input
                type="checkbox"
                className="form-check-input cursor-pointer"
                id="is_trending"
                name="is_trending"
                checked={Boolean(formik.values?.is_trending) ?? false}
                onChange={(e) => {
                  formik.setFieldValue(
                    "is_trending",
                    !Boolean(formik.values?.is_trending) ?? false
                  );
                }}
              />
            </div>
            <label htmlFor="" className="pro-font-sm pro-lh-base pro-fw-medium">
              Trending
            </label>
          </div>
        </div>
        <div className="col-auto">
          <div className="input-wrap pro-mb-4  pro-flex-row pro-items-center">
            <div className="form-check form-switch pro-mb-0">
              <input
                type="checkbox"
                className="form-check-input cursor-pointer"
                id="show_on_banner"
                name="show_on_banner"
                checked={Boolean(formik.values?.show_on_banner) ?? false}
                onChange={(e) => {
                  formik.setFieldValue(
                    "show_on_banner",
                    !Boolean(formik.values?.show_on_banner) ?? false
                  );
                }}
              />
            </div>
            <label htmlFor="" className="pro-font-sm pro-lh-base pro-fw-medium">
              Banner
            </label>
          </div>
        </div>
      </div>

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
          type="button"
        >
          Cancel
        </Button>

        <Button className={"pro-btn-primary lg pro-mx-3"} onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  );
};
export default Details;
