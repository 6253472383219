import LOGO from "./images/logo.svg";
import USER from "./images/header/avatar.png";
import HEADERLOGO from "./images/header/logo.svg";
import PERSON from "./images/profile.jpg";
import RADIO from "./images/radio.png";
import RADIO_ACTIVE from "./images/radio_active.png";
import PODCAST_ACTIVE from "./images/podcast.png";
import VIDEO_ACTIVE from "./images/video_active.png";
import PROGRAM_ACTIVE from "./images/program_active.png";
import PROGRAM from "./images/program.png";
import PODCAST from "./images/podcast.png";
import VIDEO from "./images/video.png";
import USERS_GROUP from "./images/icon_users.svg";
import ACTIVE_USER from "./images/icon_active_users.svg";
import VIDEOS from "./images/icon_videos.svg";
import PODCASTS from "./images/icon_podcasts.svg";
import SHORTS from "./images/icon_shorts.svg";
import GENDER_IMAGE from "./images/gender_image.png";

const Assets = {
  LOGO,
  USER,
  HEADERLOGO,
  PERSON,
  RADIO,
  RADIO_ACTIVE,
  PODCAST,
  PODCAST_ACTIVE,
  VIDEO,
  VIDEO_ACTIVE,
  PROGRAM_ACTIVE,
  PROGRAM,
  USERS_GROUP,
  ACTIVE_USER,
  VIDEOS,
  PODCASTS,
  SHORTS,
  GENDER_IMAGE
};

export default Assets;
