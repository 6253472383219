import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Dashboard/dashboardSlice";

const useAdPlacementList = ({ dashboardData }) => {
  const adPlacementState = useSelector((state) => state.dashboard);
  const { tableFields } = useSelector((state) => state.global);
  const dispatch = useDispatch();

  const [searchCriteria, setSearchCriteria] = useState("");

  const handleSearchCriteriaChange = (value) => {
    setSearchCriteria(value);
  };

  const filteredData = dashboardData?.filter((item) => {
    const lowerCaseCriteria = searchCriteria.toLowerCase();
    return (
      item.ad_placement_type.toLowerCase().includes(lowerCaseCriteria) ||
      typeof item.advertisement === "string" ||
      item.advertisement?.name.toLowerCase().includes(lowerCaseCriteria) ||
      item.type.toLowerCase().includes(lowerCaseCriteria)
      ||
      item.advertisement?.ad_campaign?.name.toLowerCase().includes(lowerCaseCriteria)
    );
  });

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const tableHeading = {
    advertisement: { label: "Advertisement", sortable: true },
    ad_campaign: { label: "Ad Campaign", sortable: true }, 
    type: { label: "Type", sortable: true },
    ad_placement_type: { label: "Ad Placement Type", sortable: true },
    reach: { label: "Reach", sortable: true },
    impression: { label: "Impression", sortable: true },
    clicks: { label: "Clicks", sortable: true },
    completed: { label: "Completed", sortable: true },
    skipped: { label: "Skipped", sortable: true },
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableHeading;
      })
    );
    // eslint-disable-next-line
  }, []);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleSort = (label) => {
    if (adPlacementState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = adPlacementState.sortOrder === "0" ? "1" : "0";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "0";
        })
      );
    }
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => (
        <p className="pro-mb-0">{data[feild] ? `${data[feild]}` : "-"}</p>
      ),
      advertisement: (feild, data) => (
        <p className="pro-mb-0">
          {data[feild]?.name ? `${data[feild]?.name}` : "-"}
        </p>
      ),
      ad_campaign: (field, data) => (
        <p className="pro-mb-0">
          {data['advertisement']?.ad_campaign?.name ? `${data['advertisement']?.ad_campaign?.name}` : "-"}
        </p>
      ),
    };
    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  return {
    adPlacementState,
    paginationOptions,
    totalRecords: adPlacementState.recordsTotal,
    activeFilter: adPlacementState.currentFilter,
    handlePagination,
    handlePageSize,
    handleSort,
    getRow,
    tableFields,
    handleSearchCriteriaChange,
    filteredData,
    searchCriteria,
  };
};

export default useAdPlacementList;
