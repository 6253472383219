import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  useCategoryDataListQuery,
  useProgramDataListQuery,
  useUpdatePodcastListDataMutation,
} from "../../../store/queries/podcast";
import { toast } from "react-toastify";
import { updateConfig } from "../../../store/slices/Podcast/podcastSlice";
import { createNew, getDataById, contentRatingList } from "./api";
import { useSelector } from "react-redux";
import Details from "./Details";
import Uploads from "./Uploads";

const useUpdatePodcast = ({ activeId, refetch, isEditData }) => {
  const dispatch = useDispatch();
  const { activeTab, currentFormData, fieldName, cropData } = useSelector(
    (state) => state.podcast
  );

  const { data: basicData = {} } = useCategoryDataListQuery();
  const queryParams = isEditData
    ? { media_id: activeId, search: "", type: "2", is_update: "1" }
    : { media_id: "", search: "", type: "2", is_update: "0" };
  const { data: programData = {} } = useProgramDataListQuery(queryParams);
  const [updatePodcast] = useUpdatePodcastListDataMutation();
  const nameRegExp = /^[^\s].*$/;
  const maxLength = 300;

  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [moveToTab, setMoveToTab] = useState(false);
  const [show, setShow] = useState(false);
  const [formikImage, setFormikImage] = useState("");
  const [ratingList, setRatingList] = useState([]);

  const [imageTitleImagePreview, setImageTitleImagePreview] = useState(
    currentFormData !== "" ? currentFormData?.title_image : ""
  );

  const [imageVerticalThumbnailPreview, setImageVerticalThumbnailPreview] = useState(
    currentFormData !== "" ? currentFormData?.vertical_thumbnail : ""
  );
  const [imageBannerPreview, setImageBannerPreview] = useState(
    currentFormData !== "" ? currentFormData?.banner_image : ""
  );
  const [imageThumbnailPreview, setImageThumbnailPreview] = useState(
    currentFormData !== "" ? currentFormData?.thumbnail : ""
  );

  useEffect(() => {
    if (currentFormData !== "" && isEditData) {
      if(currentFormData?.type?._id === 2){
        formik?.setFieldValue("episode", currentFormData?.program_details?.episode_number)
      }
    }
  }, [currentFormData]);
  useEffect(() => {
    if (activeId) {
      getDataById(activeId).then((response) => {
        formik.setErrors("");
        setErrors("");

        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.currentFormData = response?.data?.data;
            })
          );
        } else if (response?.data?.error_code === 403) {
          formik.setErrors(response?.data?.errors);
        }
      });
    }
  }, [activeId]);

  useEffect(() => {
    contentRatingList().then((response) => {
      setRatingList(response?.data?.data);
    });
  }, []);

  const defaultRating = ratingList.find((item) => item.code === "unrated");
  const initialContentRating = defaultRating ? [defaultRating] : [];

  let podcastVal = Yup.object({
    category: Yup.mixed().required("Category is Required"),
    rj: Yup.mixed().required("Rj is Required"),
    content_rating: Yup.mixed().required("Content Rating is Required"),
    type: Yup.mixed().required("Type is Required"),
    thumbnail: Yup.mixed().required("Thumbnail is Required"),
    title_image: Yup.mixed().required("Title Image is Required"),
    vertical_thumbnail: Yup.mixed().required("Vertical Image is Required"),
    banner_image: Yup.mixed().required("Banner Image is Required"),
    audio: Yup.string().required("Audio is Required"),
    title: Yup.string()
      .required("Title is Required")
      .matches(nameRegExp, "Leading space is not allowed"),
    description: Yup.string()
      .required("Description is Required")
      .matches(nameRegExp, "Leading space is not allowed"),
      // description_mal: Yup.string()
      // .required("Description is Required")
      // .matches(nameRegExp, "Leading space is not allowed"),
    // sub_title: Yup.string().required("Required"),
    // video: Yup.string().required("Required"),
  });

  let podacastUpdateVal = Yup.object({
    category: Yup.mixed().required("Category is Required"),
    rj: Yup.mixed().required("Rj is Required"),
    content_rating: Yup.mixed().required("Content Rating is Required"),
    type: Yup.mixed().required("Type is Required"),
    title: Yup.string()
      .required("Title is Required")
      .matches(nameRegExp, "Leading space is not allowed"),
    description: Yup.string()
      .required("Description is Required")
      .matches(nameRegExp, "Leading space is not allowed"),
      // description_mal: Yup.string()
      // .required("Description is Required")
      // .matches(nameRegExp, "Leading space is not allowed"),
    // sub_title: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      _id: activeId,
      title: null,
      description: null,
      description_mal: null,
      // sub_title: null,
      category: null,
      rj: null,
      // content_rating: isEditData ? currentFormData?.content_rating : initialContentRating,
      content_rating: null,
      type: null,
      title_image: null,
      vertical_thumbnail: null,
      banner_image: null,
      thumbnail: null,
      audio: null,
      video: null,
      program_details: null,
      episode: null,
    },
    validationSchema: isEditData ? podacastUpdateVal : podcastVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        let obj = {
          title: values.title,
          description: values.description,
          description_mal: values.description_mal,
          // sub_title: values.sub_title,
          "category[id]": values.category?._id,
          "category[name]": values.category?.name,
          "rj[id]": values.rj?._id,
          "rj[name]": values.rj?.name,
          "content_rating[0][name]": isEditData ? values.content_rating?.[0]?.name || values.content_rating?.name : values.content_rating?.name,
          "content_rating[0][code]": isEditData ? values.content_rating?.[0]?.code || values.content_rating?.code : values.content_rating?.code,
          type: values.type?._id,
          status: +Boolean(values.status),
          is_ad_enabled: +Boolean(values.is_ad_enabled),
          is_trending: +Boolean(values.is_trending),
          show_on_banner: +Boolean(values.show_on_banner),
        };

        if (isEditData) {
          obj._id = values._id;
        }
        if (values?.type?._id === 2) {
          obj["program[id]"] = values.program_details?._id;
          obj["program[name]"] = values.program_details?.name;

          const selectedProgram = programData?.data?.find(
            (program) => program._id === values.program_details?._id
          );
          const episodeCount = selectedProgram?.count;
          // obj["program[episode_number]"] = isEditData
          //   ? currentFormData?.program_details?.episode_number === undefined
          //     ? 0
          //     : currentFormData?.program_details?.episode_number
          //   : episodeCount;
          obj["program[episode_number]"] = isEditData
            ? (currentFormData?.program_details?.episode_number === undefined
              ? values.program_details?.count
              : values.episode?.value ??
                currentFormData?.program_details?.episode_number)
            : values.episode?.value || episodeCount;
        }
        if (
          !isEditData &&
          (values.audio?.[0]?.name || values.audio?.length === 0)
        ) {
          obj.audio = values.audio?.[0];
        }
        if (values.title_image?.name || values.title_image?.length === 0) {
          obj.title_image = values.title_image;
        }
        if (values.vertical_thumbnail?.name || values.vertical_thumbnail?.length === 0) {
          obj.vertical_thumbnail = values.vertical_thumbnail;
        }
        if (values.banner_image?.name || values.banner_image?.length === 0) {
          obj.banner_image = values.banner_image;
        }
        if (values.thumbnail?.name || values.thumbnail?.length === 0) {
          obj.thumbnail = values.thumbnail;
        }
        if (
          !isEditData &&
          (values.video?.[0]?.name || values.video?.length === 0)
        ) {
          obj.video = values.video?.[0];
        }
        if (
          isEditData &&
          currentFormData?.is_converted == 1 &&
          !values.video?.[0]?.name &&
          !values.audio?.[0]?.name
        ) {
          delete obj.video;
          delete obj.audio;
        } else if (isEditData && currentFormData?.is_converted == 0) {
          obj.video = "";
          obj.audio = "";
        } else if (isEditData && currentFormData?.is_converted == 1) {
          if (values.video?.[0]?.name) {
            obj.video = values.video?.[0];
          } else if (values.audio?.[0]?.name) {
            obj.audio = values.audio?.[0];
          }
        }
        // if (values.content_rating) {
        //   values.content_rating.forEach((rating, index) => {
        //     obj[`content_rating[${index}][name]`] = rating.name;
        //     obj[`content_rating[${index}][code]`] = rating.code;
        //   });
        // }

        let formData = new FormData();
        delete obj.category;
        delete obj.rj;
        delete obj.program_details;
        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });
        if (isEditData === true) {
          updatePodcast(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              toast.success("Successfully udpated");
            } else if (response?.data?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.data?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        } else {
          createNew(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              toast.success("Successfully Added");
            } else if (response?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  useEffect(() => {
    if (currentFormData !== "" && isEditData) {
      setImageTitleImagePreview(currentFormData?.title_image ?? "");
      setImageVerticalThumbnailPreview(currentFormData?.vertical_thumbnail ?? "")
      setImageBannerPreview(currentFormData?.banner_image ?? "");
      setImageThumbnailPreview(currentFormData?.thumbnail ?? "");
    }
  }, [currentFormData, formik.values]);

  const handleRemovePhotoImage = (e) => {
    formik.setFieldValue("photo", "");
  };

  useEffect(() => {
    if (isEditData && currentFormData) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
    }
    // }
    // eslint-disable-next-line
  }, [currentFormData, isEditData]);

  const closeModal = () => {
    setShow(false);
  };

  const handleCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Details: 0,
        };
        state.activeTab = "Details";
      })
    );

    setShow((state) => (state = false));
  };

  const handleTitleImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase(); // Get the file extension
      if (extension === "png") {
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "title_image";
            state.cropData = {
              unit: "px",
              width: 305,
              height: 200,
              aspect: 305 / 200,
            };
          })
        );

        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "title_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Please select a PNG file.");
        formik.setFieldError("title_image", "Please select a PNG file.");
      }
    }
  };

  const handleVerticalImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "vertical_thumbnail";
            state.cropData = {
              unit: "px",
              width: 375,
              height: 500,
              aspect: 375 / 500,
            };
          })
        );

        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "vertical_thumbnail",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Please select a PNG file.");
        formik.setFieldError("vertical_thumbnail", "Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  const handleBannerImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "banner_image";
            state.cropData = {
              unit: "px",
              width: 748,
              height: 818,
              aspect: 374 / 409,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  const handleListThumbnailImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "thumbnail";
            state.cropData = {
              unit: "px",
              width: 500,
              height: 500,
              aspect: 1/1,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  // const handleSong = (e) => {
  //   formik.setFieldValue("audio", e?.target?.files);
  // };
  const handleSong = (e) => {
    const selectedFile = e?.target?.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileType = fileName.slice(fileName.lastIndexOf(".") + 1);
      if (
        fileType.toLowerCase() === "mp3" ||
        fileType.toLowerCase() === "wav"
      ) {
        formik.setFieldValue("audio", e?.target?.files);
      } else {
        // Display an error message or handle the case when a file other than MP3 is selected
        // For example:
        toast.error("Please select an MP3 or WAV file.");
        // You might want to clear the input in case of an invalid file selection
        e.target.value = "";
      }
    }
  };

  // const handlevideo = (e) => {
  //   formik.setFieldValue("video", e?.target?.files);
  // };

  const handlevideo = (e) => {
    const selectedFile = e?.target?.files[0];
    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileType = fileName
        .slice(fileName.lastIndexOf(".") + 1)
        .toLowerCase();
      const allowedExtensions = [
        "mp4",
        "ogx",
        "oga",
        "ogv",
        "ogg",
        "webm",
        "mkv",
        "mov",
      ];
      if (allowedExtensions.includes(fileType)) {
        formik.setFieldValue("video", e?.target?.files);
      } else {
        // Display an error message or handle the case when a file with an invalid extension is selected
        // For example:
        toast.error("Please select a valid video file.");
        // You might want to clear the input in case of an invalid file selection
        e.target.value = "";
      }
    }
  };
  const handleTextChange = (e) => {
    const newText = e.target.value;
    if (newText?.length <= 300) {
      formik.setFieldValue("description", newText);
    }
  };

  const handleMalayalamTextChange = (e) => {
    const newText = e.target.value;
    if (newText?.length <= 300) {
      formik.setFieldValue("description_mal", newText);
    }
  };


  const tabs = [
    {
      label: "Details",
      completed: calculateCompletionStatus([
        "title",
        "description",
        "description_mal",
        // "sub_title",
        "category",
        "rj",
        "type",
        // "program_details",
        ...(formik.values.type?.name === "episode" ? ["program_details"] : []),
        // "status",
      ]),
    },
    {
      label: "Uploads",
      completed: calculateCompletionStatus([
        "title_image",
        "vertical_thumbnail",
        "banner_image",
        "thumbnail",
        "audio",
        "video",
      ]),
    },
  ];

  const tabsAndFields = [
    {
      label: "Details",
      fields: [
        "title",
        "description",
        "description_mal",
        "sub_title",
        "category",
        "rj",
        "type",
        "program_details",
        "status",
      ],
    },
    {
      label: "Uploads",
      fields: ["thumbnail", "title_image", "vertical_thumbnail", "banner_image", "audio", "video"],
    },
  ];

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.errors, moveToTab]);

  function calculateCompletionStatus(fields) {
    let finalArray = [];
    finalArray = fields;
    // eslint-disable-next-line array-callback-return
    var completedFields = finalArray?.filter((field) => {
      if (field == "program_details") {
        return formik.values.type?.name === "episode" && !!formik.values[field];
      }
      return !!formik?.values[field];
    });

    return (completedFields.length / fields.length) * 100;
  }

  const handleTabClick = (tab) => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = tab?.label;
      })
    );
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };
  const generateEpisodeOptions = (count) => {
    const options = [];
    for (let i = count; i >= 1; i--) {
      options.push({ value: i, label: i.toString() });
    }
    return options;
  };

  const handleProgramChange = (value) => {
    formik.setFieldValue("program_details", value || null);
    const count = value?.count || value?.episode_number; // Get the count or episode_number of the selected program
    const episodeOptions = generateEpisodeOptions(count);
    formik.setFieldValue("episode", episodeOptions[0]); // Reset the selected episode
    formik.setFieldTouched("episode", false); // Mark episode field as untouched
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Details":
        return (
          <Details
            formik={formik}
            tabs={tabs}
            getFieldError={getFieldError}
            categoryList={basicData?.data?.category}
            rjList={basicData?.data?.rjs}
            podcastType={basicData?.data?.podcast_type}
            programList={programData?.data}
            ratingList={ratingList}
            currentFormData={currentFormData}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
            generateEpisodeOptions={generateEpisodeOptions}
            handleProgramChange={handleProgramChange}
            handleTextChange={handleTextChange}
            handleMalayalamTextChange={handleMalayalamTextChange}
            maxLength={maxLength}
          />
        );
      case "Uploads":
        return (
          <Uploads
            formik={formik}
            tabs={tabs}
            isLoading={isLoading}
            getFieldError={getFieldError}
            imageTitleImagePreview={imageTitleImagePreview}
            imageVerticalThumbnailPreview={imageVerticalThumbnailPreview}
            imageBannerPreview={imageBannerPreview}
            imageThumbnailPreview={imageThumbnailPreview}
            handleTitleImage={handleTitleImage}
            handleVerticalImage={handleVerticalImage}
            handleBannerImage={handleBannerImage}
            handleListThumbnailImage={handleListThumbnailImage}
            handleSong={handleSong}
            handlevideo={handlevideo}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
            show={show}
            setShow={setShow}
            closeModal={closeModal}
            fieldName={fieldName}
            setImageTitleImagePreview={setImageTitleImagePreview}
            setImageVerticalThumbnailPreview={setImageVerticalThumbnailPreview}
            setImageBannerPreview={setImageBannerPreview}
            setImageThumbnailPreview={setImageThumbnailPreview}
            cropData={cropData}
            formikImage={formikImage}
            setFormikImage={setFormikImage}
            currentFormData={currentFormData}
            handleClick={handleClick}
          />
        );
      default:
        return null;
    }
  };

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.activeTab = updatedTab?.label;
      })
    );
  }

  const handleMoveToErrorTab = () => {
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab && firstErrorTab !== activeTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );
      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  return {
    formik,
    errors,
    tabs,
    activeTab,
    currentFormData,
    isLoading,
    handleMoveToErrorTab,
    handleCloseModal,
    handleRemovePhotoImage,
    handleTitleImage,
    handleVerticalImage,
    handleBannerImage,
    handleListThumbnailImage,
    handleSong,
    handleTabClick,
    renderTabContent,
    imageTitleImagePreview,
    imageVerticalThumbnailPreview,
    imageBannerPreview,
    imageThumbnailPreview,
    handlevideo,
  };
};

export default useUpdatePodcast;
