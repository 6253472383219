import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key] === "status") {
        return `${key}=${parseInt(params[key])}&`;
      } else if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    try {
      const response = await api[method](
        `${endpoint}?${getParams("_id")}${getParams("from_date")}${getParams(
          "to_date"
        )}${getParams("status")}${getParams(
          "sort_by"
        )}sort_order=${params?.sort_order || ""}&page_size=${
          params?.page_size || "10"
        }&${getParams("search")}&page=${params?.page || 1}&${getParams(
          "length"
        )}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err.response?.data || err.message,
      };
    }
  };

export const rj = createApi({
  reducerPath: "rjApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Rj"],
  endpoints: (builder) => ({
    getRjListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/rj/list`,
      }),
      provideTags: ["Rj"],
    }),
    getRjFormDataById: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/rj/edit`,
      }),
      provideTags: ["Rj"],
    }),
    updateRjListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/rj/save`,
      }),

      invalidatesTags: ["Rj"],
    }),
    deleteRjData: builder.mutation({
      query: (values) => ({
        method: "delete",
        params: values,
        endpoint: `/admin/rj/delete`,
      }),

      invalidatesTags: ["Rj"],
    }),
    getFilterListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/filters/common-search-filters`,
      }),
    }),
    statusChange: builder.mutation({
      query: (values) => ({
        method: "post",
        params: values,
        endpoint: `/admin/rj/status-change`,
      }),
    }),
  }),
});

export const {
  useGetRjListDataQuery,
  useGetRjFormDataByIdQuery,
  useUpdateRjListDataMutation,
  useDeleteRjDataMutation,
  useGetFilterListDataQuery,
  useStatusChangeMutation,
} = rj;
