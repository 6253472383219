import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { updateConfig } from "../../../store/slices/Campaign/campaignSlice";
import { toast } from "react-toastify";
import {
  getDataById,
  createNew,
  countryList,
  stateList,
  cityList,
} from "./api";
import {
  useClientListQuery,
  usePriorityListQuery,
  useUpdateCampaignListDataMutation,
} from "../../../store/queries/campaign";
import { useSelector } from "react-redux";

const useUpdateCampaign = ({ activeId, refetch, isEditData }) => {
  const dispatch = useDispatch();
  const [updateCampaign] = useUpdateCampaignListDataMutation();
  const { data: clientsList = {} } = useClientListQuery();
  const { data: PriorityLists = {} } = usePriorityListQuery();
  const { currentFormData } = useSelector((state) => state.campaign);
  const nameRegExp = /^[^\s].*$/;

  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [countryLists, setCountryLists] = useState([]);
  const [stateLists, setStateLists] = useState([]);
  const [cityLists, setCityLists] = useState([]);

  useEffect(() => {
    if (activeId) {
      getDataById(activeId).then((response) => {
        formik.setErrors("");
        setErrors("");

        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.currentFormData = response?.data?.data;
            })
          );
        } else if (response?.error_code === 403) {
          formik.setErrors(response?.data?.errors);
        }
      });
    }
  }, []);
  useEffect(() => {
    countryList().then((response) => {
      setCountryLists(response?.data?.data);
    });
  }, []);

  let campaignVal = Yup.object({
    client_id: Yup.mixed().required("Client is Required"),
    name: Yup.string().required("Name is Required").matches(nameRegExp, "Leading space is not allowed"),
    start_date: Yup.string().required("Start Date is Required"),
    end_date: Yup.string().required("End Date Required"),
    priority: Yup.mixed().required("Priority is Required"),
    target_countries: Yup.mixed().required("Country is Required"),
    target_states: Yup.mixed().required("State is Required"),
    target_cities: Yup.mixed().required("City is Required"),
  });

  let campaignUpdateVal = Yup.object({
    client_id: Yup.mixed().required("Client is Required"),
    name: Yup.string().required("Name is Required").matches(nameRegExp, "Leading space is not allowed"),
    start_date: Yup.string().required("Start Date is Required"),
    end_date: Yup.string().required("End Date Required"),
    priority: Yup.mixed().required("Priority is Required"),
    target_countries: Yup.mixed().required("Target Countries is Required"),
    target_states: Yup.mixed().required("Target States is Required"),
    target_cities: Yup.mixed().required("Target Cities is Required"),
  });

  const formik = useFormik({
    initialValues: {
      _id: activeId,
      client_id: null,
      name: null,
      start_date: null,
      end_date: null,
      priority: null,
      ad_budget: null,
      target_countries: null,
      target_states: null,
      target_cities: null,
      status: null,
    },
    validationSchema: isEditData ? campaignUpdateVal : campaignVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        let obj = {
          client: { id: values.client_id?._id, name: values.client_id?.name },
          name: values.name,
          start_date: values.start_date,
          end_date: values.end_date,
          priority: values.priority,
          ad_budget: values.ad_budget,
          target_countries: values.target_countries,
          target_states: values.target_states,
          target_cities: values.target_cities,
          status: +Boolean(values.status),
        };
        if (isEditData) {
          obj._id = values._id;
        }

        if (isEditData === true) {
          updateCampaign(obj).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              toast.success("Successfully udpated");
            } else if (response?.data?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.data?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        } else {
          createNew(obj).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              toast.success("Successfully Added");
            } else if (response?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  const today = new Date();
  today.setDate(today.getDate());
  const minDate = today.toISOString().split("T")[0];

  const reverseDateString = (dateString) => {
    if (dateString) {
      const parts = dateString.split("-");
      const reversedDateString = parts[2] + "-" + parts[1] + "-" + parts[0];
      return reversedDateString;
    }
  };
  let dateStr = reverseDateString(formik.values.start_date);
  let dateStr1 = reverseDateString(formik.values.end_date);

  useEffect(() => {
    if (formik.values.target_countries) {
      stateList(formik.values.target_countries).then((response) => {
        setStateLists(response?.data?.data);
      });
    }
    if (formik.values.target_countries && formik.values.target_states) {
      cityList(formik.values.target_countries,formik.values.target_states).then((response) => {
        setCityLists(response?.data?.data);
      });
    }
  }, [formik.values.target_countries,formik.values.target_states]);

  const handleCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  useEffect(() => {
    if (isEditData && currentFormData) {
      Object.keys(currentFormData || {}).forEach((key) => {
        if(key === "client"){
          formik.setFieldValue("client_id", currentFormData?.[key]);
        }
        else{
        formik.setFieldValue(key, currentFormData?.[key]);
        }
      });
    }
    // eslint-disable-next-line
  }, [currentFormData, isEditData]);

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return {
    formik,
    errors,
    isLoading,
    currentFormData,
    getFieldError,
    handleCloseModal,
    clientsList,
    PriorityLists,
    countryLists,
    stateLists,
    cityLists,
    minDate,
    dateStr,
    dateStr1,
  };
};

export default useUpdateCampaign;
