import {
  HeadingGroup,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
  ConfirmationBox,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import OrderColumn from "../../Global/OrderColumn";
import { FaSort } from "react-icons/fa";
import useUsersList from "./useUsersList";
import UpdateUser from "../UpdateUser";
import Style from "../user.module.scss";
import Filter from "../Filter";

const UsersList = () => {
  const {
    users,
    usersState,
    currentPage,
    activeFilter,
    showCreateModal,
    paginationOptions,
    isFetching,
    isLoading,
    showDeleteModal,
    date,
    showEditModal,
    filterShow,
    setFilterShow,
    updateUserFields,
    closeEditModal,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    refetch,
    handleSort,
    handleSearch,
    handleUserDataEditClick,
    handleClearClick,
    closeModal,
    deleteUserDataClick,
    getRow,
    deleteUser,
    tableFields,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  } = useUsersList();

  return (
   
        <>
          <HeadingGroup title={"Users"} extraClassName={`pro-mb-4 pro-justify-between`} />
          <div className="col-12 pro-pb-6">
            <SearchFilters
              showDateRange={false}
              multiSelect={false}
              activeFilter={activeFilter}
              showFilters={true}
              onSearchInput={handleSearch}
              loading={isLoading}
              showActions={false}
              handleClear={handleClearClick}
              initialDateRange={date}
              searchInputProps={{ value: usersState?.search }}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              extraFilters = {
                <div className="col-auto">
                  <button className={`pro-btn pro-btn-outline pro-h-100 pro-items-center `} onClick={() => setFilterShow(true)} >
                    <span className="material-symbols-outlined">tune</span>
                    <span>Filter</span>
                </button>
                </div>
              }
            />
            <Table
              data={users?.data?.original?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              showCheckBox={
                hasEditPermission || hasDeletePermission ? true : false
              }
              SortIcon={<FaSort />}
              handleEdit={handleUserDataEditClick}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              handleDelete={deleteUserDataClick}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={usersState?.currentPageSize}
              assignable={false}
              multiSelect={false}
              clear={usersState.clearSelection}
              editable={hasEditPermission ? true : false}
              deletable={hasDeletePermission ? true : false}
            />
            {users?.data?.original?.data?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalPageCount={Math.ceil(
                  users?.data?.original?.recordsTotal /
                    usersState?.currentPageSize
                )}
                onPageChange={handlePagination}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
                actionIsSearchable = {false}
              />
            )}
          </div>
            <ModalLayout
              centered
              show={showDeleteModal}
              handleClose={closeDeleteModal}
            >
              <div className="pro-m-5">
                <ConfirmationBox
                  title={`Are you sure you want to delete?`}
                  isRight={true}
                  cancelText={`Cancel`}
                  submitText={`Delete`}
                  cancelAction={closeDeleteModal}
                  submitAction={deleteUser}
                >
                  ConfirmationBox
                </ConfirmationBox>
              </div>
            </ModalLayout>

            <ModalLayout show={showEditModal} handleClose={closeEditModal}>
              <div className="pro-m-5">
                <OrderColumn
                  title={"Choose which columns you see"}
                  refetch={refetch}
                  tableFields={tableFields}
                  moduleId={1}
                  updateData={updateUserFields}
                />
              </div>
            </ModalLayout>

            <OffCanvasLayout
              show={showCreateModal}
              handleClose={closeModal}
              title={"Update User Details"}
              closeIcon={
                <span className="material-symbols-outlined"> close </span>
              }
              backdrop={"static"}
            >
              <UpdateUser activeId={usersState?.activeId} refetch={refetch} />
            </OffCanvasLayout>

            <OffCanvasLayout
              centered={true}
              show={filterShow}
              handleClose={() => {
                setFilterShow(false);
              }}
              title={"Filter"}
              closeIcon={
                <span className="material-symbols-outlined">close</span>
              }
            >
              <Filter setShowform={setFilterShow} refetch={refetch}/>
            </OffCanvasLayout>
          
        </>
     
  );
};

export default UsersList;
