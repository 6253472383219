import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  useUpdateRjListDataMutation,
} from "../../../store/queries/rj";
import { updateConfig } from "../../../store/slices/Rj/rjSlice";
import { toast } from "react-toastify";
import { emailRegExp, phoneRegExp } from "../../../utils/functions/table";
import { getDataById, createNew } from "./api";
import Details from "./Details";
import Uploads from "./Uploads";
import { useSelector } from "react-redux";

const useUpdateRj = ({ activeId, refetch, isEditData }) => {
  const dispatch = useDispatch();
  const { activeTab, currentFormData, fieldName, cropData } = useSelector((state) => state.rj);
 
  const [updateRj] = useUpdateRjListDataMutation();
  const nameRegExp = /^[^\s].*$/;
  const maxLength = 75;

  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [moveToTab, setMoveToTab] = useState(false);
  const [show, setShow] = useState(false);
  const [formikImage, setFormikImage] = useState("");
  const [imagePreview, setImagePreview] = useState(
    currentFormData !== "" ? currentFormData?.image : ""
  );
  const [imageDetailPreview, setImageDetailPreview] = useState(
    currentFormData !== "" ? currentFormData?.detail_image : ""
  );

  useEffect(() => {
    if (currentFormData !== "" && isEditData) {
      setImagePreview(currentFormData?.image ?? "");
      setImageDetailPreview(currentFormData?.detail_image ?? "");
    }
  }, [currentFormData]);

  useEffect(() => {
    if (activeId) {
      getDataById(activeId).then((response) => {
        formik.setErrors("");
        setErrors(""); 

        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.currentFormData = response?.data?.data;
            })
          );
        } else if (response?.data?.error_code === 403) {
          formik.setErrors(response?.data?.errors);
        }
      });
    }
  }, []);


  let rjVal = Yup.object({
    name: Yup.string().required("Name is Required").matches(nameRegExp, "Leading space is not allowed"),
    country_code: Yup.string().required("Country Code is Required"),
    mobile: Yup.string()
      .matches(phoneRegExp, "Enter a valid phone number")
      .min(5, "Mobile number must be greater than 5 digits ")
      .required("Phone number is Required"),
    email: Yup.string()
      .matches(emailRegExp, "Enter a valid email")
      .required("Email is Required"),
    experience: Yup.string()
      .required("Experience is required")
      .max(2, "Experience must be at most 2 digits")
      .matches(/^(0*[1-9]\d*|0+)$/, "Please enter a positive number"),
    // twitter_link: Yup.string().required("Twitter Link is Required").url("Invalid URL format"),
    // instagram_link: Yup.string().required("Instagram Link is Required").url("Invalid URL format"),
    // facebook_link: Yup.string().required("Facebook Link is Required").url("Invalid URL format"),
    image: Yup.string().required("Image is Required"),
    detail_image: Yup.string().required("Detail image is Required"),
    specialization: Yup.string().required("Specialization is Required").matches(nameRegExp, "Leading space is not allowed"),
    description: Yup.string().required("Description is Required").matches(nameRegExp, "Leading space is not allowed"),
    qualification: Yup.string().required("Qualification is Required").matches(nameRegExp, "Leading space is not allowed"),
  });

  let rjUpdateVal = Yup.object({
    name: Yup.string().required("Name is Required").matches(nameRegExp, "Leading space is not allowed"),
    country_code: Yup.string().required("Country Code is Required"),
    mobile: Yup.string()
      .matches(phoneRegExp, "Enter a valid phone number")
      .min(5, "Mobile number must be greater than 5 digits ")
      .required("Phone number is Required"),
    email: Yup.string()
      .matches(emailRegExp, "Enter a valid email")
      .required("Email is Required"),
    experience: Yup.string()
      .required("Experience is required"),
    // twitter_link: Yup.string().required("Twitter Link is Required").url("Invalid URL format"),
    // instagram_link: Yup.string().required("Instagram Link is Required").url("Invalid URL format"),
    // facebook_link: Yup.string().required("Facebook Link is Required").url("Invalid URL format"),
    specialization: Yup.string().required("Specialization is Required").matches(nameRegExp, "Leading space is not allowed"),
    description: Yup.string().required("Description is Required").matches(nameRegExp, "Leading space is not allowed"),
    qualification: Yup.string().required("Qualification is Required").matches(nameRegExp, "Leading space is not allowed"),
  });

  const formik = useFormik({
    initialValues: {
      _id: activeId,
      name: null,
      description: null,
      country_code: null,
      mobile: null,
      email: null,
      experience: null,
      specialization: null,
      image: null,
      qualification: null,
      twitter_link: null,
      instagram_link: null,
      facebook_link: null,
      detail_image: null,
    },
    validationSchema: isEditData ? rjUpdateVal : rjVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        let obj = {
          name: values.name,
          description: values.description,
          country_code: values.country_code,
          mobile: values.mobile,
          email: values.email,
          experience: values.experience,
          specialization: values.specialization,
          qualification: values.qualification,
          // twitter_link: values.twitter_link,
          // instagram_link: values.instagram_link,
          // facebook_link: values.facebook_link,
          status: +Boolean(values.status),
        };
        if (isEditData) {
          obj._id = values._id;
        }
        if (values.image?.name || values.image?.length === 0) {
          obj.image = values.image;
        }
        if (values.detail_image?.name || values.detail_image?.length === 0) {
          obj.detail_image = values.detail_image;
        }
        if (values.twitter_link !== null) {
          obj.twitter_link = values.twitter_link;
        }
        if (values.instagram_link !== null) {
          obj.instagram_link = values.instagram_link;
        }
        if (values.facebook_link !== null) {
          obj.facebook_link = values.facebook_link;
        }

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });
        if (isEditData === true) {
          updateRj(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              toast.success("Successfully udpated");
            } else if (response?.data?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.data?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        } else {
          createNew(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              toast.success("Successfully Added");
            } else if (response?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });
  const handleRemovePhotoImage = (e) => {
    formik.setFieldValue("photo", "");
  };

  useEffect(() => {
    if (isEditData && currentFormData) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
    }
    // eslint-disable-next-line
  }, [currentFormData, isEditData]);

  const closeModal = () => {
    setShow(false);
  };

  const handleCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Details: 0,
        };
        state.activeTab = "Details";
      })
    );
  };

  const handleImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "image";
            state.cropData = {
              unit: "px",
              width: 100,
              height: 100,
              aspect: 1 / 1,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  const handleDetailImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "detail_image";
            state.cropData = {
              unit: "px",
              width: 400,
              height: 300,
              aspect: 4 / 3,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  const tabs = [
    {
      label: "Details",
      completed: calculateCompletionStatus([
        "name",
        "description",
        "country_code",
        "mobile",
        "email",
        "experience",
        "specialization",
        "qualification",
        "twitter_link",
        "instagram_link",
        "facebook_link",
        // "status",
      ]),
    },
    {
      label: "Uploads",
      completed: calculateCompletionStatus(["image", "detail_image"]),
    },
  ];

  const tabsAndFields = [
    {
      label: "Details",
      fields: [
        "name",
        "description",
        "country_code",
        "mobile",
        "email",
        "experience",
        "specialization",
        "qualification",
        "twitter_link",
        "instagram_link",
        "facebook_link",
        "status",
      ],
    },
    {
      label: "Uploads",
      fields: ["image", "detail_image"],
    },
  ];

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.errors, moveToTab]);

  function calculateCompletionStatus(fields) {
    let finalArray = [];
    finalArray = fields;
    // eslint-disable-next-line array-callback-return
    var completedFields = finalArray?.filter((field) => {
      return !!formik?.values[field];
    });

    return (completedFields.length / fields.length) * 100;
  }

  const handleTabClick = (tab) => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = tab?.label;
      })
    );
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };
  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
    };
    const handleTextChange = (e) => {
      const newText = e.target.value;
      if (newText?.length <= 150) {
        formik.setFieldValue("description", newText);
      }
    };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Details":
        return (
          <Details
            formik={formik}
            tabs={tabs}
            getFieldError={getFieldError}
            currentFormData={currentFormData}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
            maxLength={maxLength} 
            handleTextChange={handleTextChange}
          />
        );
      case "Uploads":
        return (
          <Uploads
            formik={formik}
            tabs={tabs}
            isLoading={isLoading}
            getFieldError={getFieldError}
            imagePreview={imagePreview}
            handleImage={handleImage}
            imageDetailPreview={imageDetailPreview}
            handleDetailImage={handleDetailImage}
            setImageDetailPreview={setImageDetailPreview}
            setImagePreview={setImagePreview}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
            cropData={cropData}
            formikImage={formikImage}
            setFormikImage={setFormikImage}
            show={show}
            setShow={setShow}
            closeModal={closeModal}
            fieldName={fieldName}
            handleClick={handleClick}
          />
        );
      default:
        return null;
    }
  };

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.activeTab = updatedTab?.label;
      })
    );
  }

  const handleMoveToErrorTab = () => {
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab && firstErrorTab !== activeTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );
      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  return {
    formik,
    errors,
    handleCloseModal,
    handleRemovePhotoImage,
    tabs,
    activeTab,
    isLoading,
    currentFormData,
    handleMoveToErrorTab,
    handleImage,
    handleDetailImage,
    handleDetailImage,
    handleTabClick,
    renderTabContent,
    imagePreview,
  };
};

export default useUpdateRj;
