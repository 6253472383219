import React from "react";
import Style from "./selectWithIcons.module.scss";
import Select, { components } from "react-select";
import { Image } from "@wac-ui-dashboard/wac_component_library";
import { SlGraph } from "react-icons/sl";

export const SelectWithIcons = ({
  data,
  handleViewTooltip,
  handleHideTooltip
}) => {

let dashboard = false;
  return (
    <div
          className={`pro-d-flex ${Style.avatar_container}  ${Style.LineGraphTooltip} avatar-container`}
          onMouseLeave={!dashboard ? () => handleHideTooltip() : () => {}}
        >
          <div
            className={` pro-overflow-hidden pro-align-self-center ${Style.graph_tooltip_wrap}`}
            onMouseEnter={
              !dashboard ? (e) => handleViewTooltip(e, data) : () => {}
            }
          >
            <span className="pro-d-flex pro-items-center">
              <i className="icon"><SlGraph /></i>{data?.drop_off_time}
            </span>
          </div>
        </div>
  );
};
