import React, { useState } from "react";
import {
  HeadingGroup,
  IconText,
  Image,
  ModalLayout,
  NavGroup,
  Button,
} from "@wac-ui-dashboard/wac_component_library";
import Style from "../BannerSettings.module.scss";
import Assets from "../../../assets/Assets";
import { CiSearch } from "react-icons/ci";
import useBannerList from "./useBannerList";
import CustomNavGroup from "./CustomNavGroup";
import { limitStrLength } from "../../../utils/functions/table";
import { ProfileCard } from "../../Global/ProfileCard";
import TagInput from "./TagInput";
import { all } from "axios";

const BannerList = () => {
  const {
    navigationItems,
    handleNavigationClick,
    resourceTypesDataList,
    handleDragStart,
    handleDragOver,
    handleDrop,
    draggedItems,
    handleRemoveItem,
    isItemDropped,
    handleTitleImage,
    handleBannerImage,
    formikImage,
    setFormikImage,
    setShow,
    show,
    formik,
    getFieldError,
    handleClick,
    closeModal,
    cropData,
    isLoading,
    errors,
    fieldName,
    imageTitleImagePreview,
    setImageTitleImagePreview,
    imageBannerPreview,
    setImageBannerPreview,
    tagsList,
    setTagsList,
    curentClickedItem,
    setCurentClickedItem,
    clickedItems,
    handleItemClick,
    incomingTagsList,
    draggedItemById,
    setDraggedItemById,
    isEdited,
    publishBannerSetting,
    savedDraggedItems,
    publishedItemsId,
    publishedItems,
    handleSearchResourceListChange,
    searchInResourceList,
    handleSearchResourcePOsitionListChange,
    handleDragStartAllItems,
    handleDropAllItems,
    allItems,
    currentDraggedItem,
    handleDragOverTwo,
    clickedIndex,
    handleChangeStatus,
    checkActive,
    hasEditPermission,
  } = useBannerList();

  const [showResourceSearch, setShowResourceSearch] = useState(false);

  const toggleResourceSearch = () => {
    setShowResourceSearch(!showResourceSearch);
    setTimeout(() => {
      document.getElementById("detailsInput").focus()
    }, 5);
  };
  const [showResourceDraggedSearch, setShowResourceDraggedSearch] =
    useState(false);

  const toggleResourceDraggedSearch = () => {
    setShowResourceDraggedSearch(!showResourceDraggedSearch);
    setTimeout(() => {
      document.getElementById("positionInput").focus()
    }, 5);
  };

  // const setIconType = (item) => {
  //   if(item?.is_published) {
  //     switch (item?.media_type) {
  //       case 6 :
  //         return `${Assets?.RADIO_ACTIVE}`
  //     }
  //   }
  // }

  return (
    <div>
      <HeadingGroup
        title={"Banner Settings"}
        buttonTitle={"Publish"}
        extraClassName={`pro-mb-4 pro-justify-between`}
        handleClick={publishBannerSetting}
        buttonProps={{
          disabled: checkActive(),
        }}
      />
      <div className={`${Style.settings_drag_wrap} row`}>
        <div className={`col-4 pro-py-2`}>
          <div className={Style.tab_wrapper}>
            <CustomNavGroup
              navigations={navigationItems}
              onClick={handleNavigationClick}
            />
          </div>
          <div className={`${Style.tab_content} pro-mt-4`}>
            <div className={`${Style.search_wrap} `}>
              <div
                className={`pro-d-flex pro-justify-between pro-items-center ${Style.search_title_wrap}`}
              >
                <h6 className={`pro-ttl pro-mb-0  h6  ${Style.search_title}`}>
                  Resource Details
                </h6>
                <span
                  className={`${Style.search_icon}`}
                  onClick={toggleResourceSearch}
                >
                  <CiSearch  strokeWidth={1} />
                </span>
              </div>
              <div
                className={`input-wrap pro-mt-1 pro-mb-3 ${
                  Style.search_input_wrap
                } ${showResourceSearch ? Style.show : ""}`}
              >
                <input
                  type="text"
                  placeholder="Search"
                  className="pro-input lg icon-r"
                  id="detailsInput"
                  // value={searchInResourceList}
                  onChange={(e) =>
                    handleSearchResourceListChange(e.target.value)
                  }
                  // autoFocus={showResourceSearch}
                />
                <span className="pro-icon">
                  <span className="material-symbols-outlined ">search</span>
                </span>
              </div>

              {false && (
                <div className={`input-wrap pro-mt-1 pro-mb-3`}>
                  <input
                    type="text"
                    className="pro-input icon-l lg"
                    placeholder="Search this table"
                  />
                  <span className="pro-icon">
                    <span className="material-symbols-outlined">search</span>
                  </span>
                </div>
              )}
            </div>

            <div className={`pro-mt-3 ${Style.drag_list_wrap} `}>
              {resourceTypesDataList?.length > 0 ? (
                <>
                  {resourceTypesDataList?.map((data, index) => (
                    <div
                      key={index}
                      className={`pro-d-flex pro-items-center pro-rounded-3 pro-border ${Style.drag_list_item}`}
                      draggable
                      onDragStart={(event) =>
                        handleDragStart(event, data, index)
                      }
                      // onDragOver={handleDragOver}
                      onDrop={(event) => {
                        handleDrop(event, index);
                      }}
                    >
                      <Image
                        src={data?.thumbnail_url}
                        width={48}
                        height={48}
                        propStyle={{ root: Style.img }}
                        alt={"Profile pic"}
                      />
                      <div className={`pro-ps-3 ${Style.dtls}`}>
                        <p className="pro-mb-1 pro-fw-medium">{data?.title}</p>
                        <p
                          className={`pro-font-sm pro-mb-0 ${Style.sub_title}`}
                        >
                          {data?.sub_title}
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                "No Result Found"
              )}
            </div>
          </div>
        </div>

        <div className={`col-4 pro-py-4 ${Style.position_wrap}`}>
          <div className={`${Style.search_wrap} `}>
            <div
              className={`pro-d-flex pro-justify-between pro-items-center ${Style.search_title_wrap}`}
            >
              <h6 className={`pro-ttl pro-mb-0  h6  ${Style.search_title}`}>
                Resource Position
              </h6>
              <span
                className={`${Style.search_icon}`}
                onClick={toggleResourceDraggedSearch}
              >
                <CiSearch strokeWidth={1} />
              </span>
            </div>
            <div
              className={`input-wrap pro-mt-1 pro-mb-3 ${
                Style.search_input_wrap
              } ${showResourceDraggedSearch ? Style.show : ""}`}
            >
              <input
                type="text"
                placeholder="Search"
                id="positionInput"
                className="pro-input lg icon-r"
                // value={searchInResourceList}
                onChange={(e) =>
                  handleSearchResourcePOsitionListChange(e.target.value)
                }
              />
              <span className="pro-icon">
                <span className="material-symbols-outlined ">search</span>
              </span>
            </div>
            {false && (
              <div className={`input-wrap pro-mt-1 pro-mb-3`}>
                <input
                  type="text"
                  className="pro-input icon-l lg"
                  placeholder="Search this table"
                />
                <span className="pro-icon">
                  <span className="material-symbols-outlined">search</span>
                </span>
              </div>
            )}
          </div>

          <div className={`pro-mt-3 ${Style.drag_wrap}`}>
            {allItems?.length > 0 ? (
              <>
                {allItems?.map((draggedItem, index) => (
                  <div
                    key={index}
                    draggable
                    onDragStart={(event) =>
                      handleDragStartAllItems(event, draggedItem, index)
                    }
                    onDragOver={handleDragOverTwo}
                    onDrop={(event) =>
                      handleDropAllItems(event, draggedItem, index)
                    }
                    className="pro-mt-3"
                  >
                    <div className=""></div>
                    <div
                      className={`pro-d-flex pro-items-center pro-justify-between pro-gap-2  pro-rounded-3 pro-border ${
                        Style.drag_list_item
                      } ${index === clickedIndex ? Style.active : ""}`}
                      onClick={() => handleItemClick(index, draggedItem)}
                    >
                      <div className="pro-d-flex pro-items-center pro-flex-grow-1">
                        <div className={`pro-d-flex pro-items-center pro-gap-3 pro-pe-5 ${Style.num_wrap}`}>
                          <span className={Style.icon}></span>
                          <span className={Style.item_no}>{index + 1}</span>
                        </div>
                        <Image
                          src={draggedItem?.banner_image_url}
                          width={48}
                          height={48}
                          propStyle={{ root: Style.img }}
                          alt={"Profile pic"}
                        />
                        <div className={`pro-px-3 ${Style.dtls}`}>
                          <p className="pro-mb-1 pro-fw-medium">
                            {draggedItem?.title}
                          </p>
                          <p
                            className={`pro-font-sm pro-mb-0 ${Style.sub_title}`}
                          >
                            {draggedItem?.sub_title}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`${Style.action_wrap} pro-ms-auto pro-d-flex pro-items-center`}
                      >
                        <span className={Style.platform_icon}>
                          {/* <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_1419_7882"
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="32"
                              height="32"
                            >
                              <rect width="32" height="32" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_1419_7882)">
                              <path
                                d="M10.5 26.3333C10.5 25.9651 10.7686 25.6667 11.1 25.6667H21.9C22.2314 25.6667 22.5 25.9651 22.5 26.3333C22.5 26.7015 22.2314 27 21.9 27H11.1C10.7686 27 10.5 26.7015 10.5 26.3333Z"
                                fill="#858585"
                              />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.59091 7C4.98842 7 4.5 7.54268 4.5 8.21212V21.7879C4.5 22.4573 4.98842 23 5.59091 23H27.4091C28.0116 23 28.5 22.4573 28.5 21.7879V8.21212C28.5 7.54269 28.0116 7 27.4091 7H5.59091ZM14.9261 18.4493C14.5625 18.6916 14.1 18.4007 14.1 17.9296V12.0704C14.1 11.5993 14.5625 11.3083 14.9261 11.5507L19.3205 14.4803C19.6736 14.7157 19.6736 15.2843 19.3205 15.5197L14.9261 18.4493Z"
                                fill="#858585"
                              />
                            </g>
                          </svg> */}
                          {/* <img src={draggedItem?.status ? `${Assets?.RADIO_ACTIVE}` :  `${Assets?.RADIO}`}></img> */}
                          <img
                          alt="img"
                            src={
                              draggedItem?.status
                                ? draggedItem?.media_type === 6 ||
                                  draggedItem?.type === 6
                                  ? `${Assets?.RADIO_ACTIVE}`
                                  : draggedItem?.media_type === 1 ||
                                    draggedItem?.type === 1
                                  ? `${Assets?.VIDEO_ACTIVE}`
                                  : draggedItem?.media_type === 2 ||
                                    draggedItem?.type === 2
                                  ? `${Assets?.PODCAST_ACTIVE}`
                                  : draggedItem?.media_type === 4 ||
                                    draggedItem?.type === 4
                                  ? `${Assets?.PROGRAM_ACTIVE}`
                                  : ""
                                : draggedItem?.media_type === 6 ||
                                  draggedItem?.type === 6
                                ? `${Assets?.RADIO}`
                                : draggedItem?.media_type === 1 ||
                                  draggedItem?.type === 1
                                ? `${Assets?.VIDEO}`
                                : draggedItem?.media_type === 2 ||
                                  draggedItem?.type === 2
                                ? `${Assets?.PODCAST}`
                                : draggedItem?.media_type === 4 ||
                                  draggedItem?.type === 4
                                ? `${Assets?.PROGRAM}`
                                : ""
                            }
                          />
                        </span>

                        <Image
                          src={draggedItem?.banner_image_url}
                          width={48}
                          height={48}
                          propStyle={{ root: Style.banner_img }}
                          alt={"Profile pic"}
                        />

                        <div className="pro-d-flex pro-flex-column pro-items-center">
                          <div className="form-check form-switch ">
                            <input
                              className="form-check-input cursor-pointer"
                              type="checkbox"
                              role="switch"
                              id={`flexSwitchCheckDefault-${index}`}
                              checked={draggedItem?.status === 1}
                              disabled={hasEditPermission ? false : true}
                              onChange={() =>
                                hasEditPermission
                                  ? handleChangeStatus(index)
                                  : ""
                              }
                            />
                          </div>
                          {/* <span className={`${Style.dlt_btn} pro-font-xs`}>Remove</span> */}
                          <span
                            className={`${Style.dlt_btn} pro-font-xs`}
                            disabled={hasEditPermission ? false : true}
                            onClick={() =>
                              hasEditPermission
                                ? handleRemoveItem(draggedItem)
                                : ""
                            }
                          >
                            Remove
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : publishedItems ? (
              "No data Found"
            ) : (
              "No result Found"
            )}
          </div>
        </div>
        <div className={`col-4  ${Style.dragged_item_dtls_wrap}`}>
          <div className={` ${Style.dragged_item_dtls}`}>
            {allItems?.map((draggedItem, index) => (
              <div
                key={index}
                draggable
                onDragStart={(event) =>
                  handleDragStartAllItems(event, draggedItem, index)
                }
                onDragOver={handleDragOver}
                onDrop={(event) =>
                  handleDropAllItems(event, draggedItem, index)
                }
              >
                {isItemDropped && clickedItems[index] && (
                  <div className={`${Style.position_files_wrap}`}>
                    <h6
                      className={`pro-ttl pro-mb-3  h6  ${Style.search_title}`}
                    >
                      Banner Details
                    </h6>
                    <div className={Style.img_wrap}>
                      <Image
                        src={
                          imageTitleImagePreview ??
                          draggedItem?.banner_image_url
                        }
                        width={460}
                        height={488}
                        propStyle={{ root: Style.main_img }}
                      />
                      <Image/>
                    </div>
                    <div className="row gx-4">
                      <div className="col">
                        <div className="input-wrap pro-mb-4">
                          <label
                            htmlFor=""
                            className={`pro-font-sm pro-mb-1 pro-fw-medium`}
                          >
                            Change Logo Title
                          </label>
                          <div className="input-drag">
                            <input
                              type="file"
                              placeholder="placeholder"
                              accept=".png"
                              className={`pro-input lg ${
                                getFieldError("title_image") && " error"
                              }`}
                              id="title_image"
                              name="title_image"
                              onBlur={formik.handleBlur("title_image")}
                              onChange={(e) => handleTitleImage(e)}
                              onClick={handleClick}
                            />
                            <span className="input-drag-box">
                              <IconText
                                title={
                                  formik?.values?.title_image?.name ||
                                  (formik?.values?.title_image?.[0]?.name !==
                                    undefined &&
                                    formik?.values?.title_image?.name) ||
                                  formik?.values?.title_image?.[0]?.name ? (
                                    limitStrLength(
                                      formik?.values?.title_image?.name ||
                                        formik?.values?.title_image?.[0]?.name,
                                      30
                                    )
                                  ) : formik?.values?.title_image ? (
                                    limitStrLength(
                                      formik?.values?.title_image ?? "",
                                      30
                                    )
                                  ) : formik?.values?.title_image?.name ||
                                    formik?.values?.title_image?.[0]?.name ? (
                                    limitStrLength(
                                      (formik?.values?.title_image?.name ||
                                        formik?.values?.title_image?.[0]
                                          ?.name) ??
                                        "",
                                      30
                                    )
                                  ) : (
                                    <>
                                      {(
                                        curentClickedItem?.title_image_url ||
                                        currentDraggedItem?.title_image_url
                                      )?.slice(0, 15)}
                                      <span>
                                        <span className="pro-text-primary">
                                          {" "}
                                          browse
                                        </span>
                                      </span>
                                    </>
                                  )
                                }
                              />
                            </span>
                            {getFieldError("title_image") && (
                              <span className="error-text">
                                {getFieldError("title_image")}
                              </span>
                            )}
                          </div>
                          <p className="pro-mb-0 pro-font-xs pro-mt-1">
                            (file of type: png.)
                          </p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="input-wrap pro-mb-4">
                          <label
                            htmlFor=""
                            className={`pro-font-sm pro-mb-1 pro-fw-medium`}
                          >
                            Change Background
                          </label>
                          <div className="input-drag">
                            <input
                              type="file"
                              placeholder="placeholder"
                              accept=".jpeg, .jpg , .png, .gif"
                              className={`pro-input lg ${
                                getFieldError("banner_image") && " error"
                              }`}
                              id="banner_image"
                              name="banner_image"
                              onBlur={formik.handleBlur("banner_image")}
                              onChange={(e) => handleBannerImage(e)}
                              onClick={handleClick}
                            />
                            <span className="input-drag-box">
                              <IconText
                                title={
                                  curentClickedItem?.banner_image_url ||
                                  currentDraggedItem?.banner_image_url ? (
                                    <>
                                      {(
                                        curentClickedItem?.banner_image_url ||
                                        currentDraggedItem?.banner_image_url
                                      )?.slice(0, 15)}
                                      <span>
                                        <span className="pro-text-primary">
                                          {" "}
                                          browse
                                        </span>
                                      </span>
                                    </>
                                  ) : formik?.values?.banner_image?.name !==
                                      undefined &&
                                    formik?.values?.banner_image?.name ? (
                                    limitStrLength(
                                      formik?.values?.banner_image?.name,
                                      30
                                    )
                                  ) : formik?.values?.banner_image ? (
                                    limitStrLength(
                                      formik?.values?.banner_image ?? "",
                                      30
                                    )
                                  ) : formik?.values?.banner_image?.name ? (
                                    limitStrLength(
                                      formik?.values?.banner_image?.name ?? "",
                                      30
                                    )
                                  ) : (
                                    <span>
                                      <span className="pro-text-primary">
                                        {" "}
                                        browse
                                      </span>
                                    </span>
                                  )
                                }
                              />
                            </span>
                            {getFieldError("banner_image") && (
                              <span className="error-text">
                                {getFieldError("banner_image")}
                              </span>
                            )}
                          </div>
                          <p className="pro-mb-0 pro-font-xs pro-mt-1">
                            (file of type: jpeg, png, jpg, gif.)
                          </p>
                        </div>
                      </div>
                      <TagInput
                        formik={formik}
                        tagsList={tagsList}
                        setTagsList={setTagsList}
                        getFieldError={getFieldError}
                        incomingTagsList={incomingTagsList}
                        draggedItemById={draggedItemById}
                        setDraggedItemById={setDraggedItemById}
                        allItems={allItems}
                      />
                    </div>
                    <div className="pro-d-flex pro-justify-end pro-mt-6 pro-mb-5">
                      <Button
                        className={"pro-btn-primary lg pro-mx-3"}
                        type="button"
                        onClick={formik.handleSubmit}
                        disabled={
                          hasEditPermission
                            ? isLoading ||
                              (!formik.values?.title_image &&
                                !formik.values?.banner_image &&
                                !(
                                  formik.values?.tags &&
                                  formik.values.tags.length > 0
                                ))
                            : true
                        }
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {formikImage && (
          <ModalLayout backdrop="static" show={show} handleClose={closeModal}>
            <ProfileCard
              setShow={setShow}
              fieldName={fieldName}
              formik={formik}
              data={formikImage}
              setImageTitleImagePreview={setImageTitleImagePreview}
              setImageBannerPreview={setImageBannerPreview}
              cropData={cropData}
              setFormikImage={setFormikImage}
              handleClose={closeModal}
            />
          </ModalLayout>
        )}
      </div>
    </div>
  );
};

export default BannerList;
