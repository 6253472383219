import {
    FormTabs,
  } from "@wac-ui-dashboard/wac_component_library";
  import useUpdateCategory from "./useUpdateNewsCategory";
  import Style from "./newscategoryUpdationForm.module.scss";
  
  const UpdateCategory = ({ activeId, refetch, isEditData }) => {
    const {
      tabs,
      activeTab,
      handleTabClick,
      handleMoveToErrorTab,
      renderTabContent,
      formik,
    } = useUpdateCategory({
      activeId,
      refetch,
      isEditData,
    });
  
    return (
      <>
      <FormTabs
        propStyle={{ root: Style.FormTabs_root }}
        tabs={tabs}
        active={activeTab}
        handleClick={handleTabClick}
      />
      <form onSubmit={(e) => {formik.handleSubmit(e); handleMoveToErrorTab();}}>{renderTabContent()}</form>
      </>
    );
  };
  export default UpdateCategory;
  