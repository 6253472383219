import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useUpdateProgrammeListDataMutation,  useTypeListQuery } from "../../../store/queries/programme";
import { updateConfig } from "../../../store/slices/Programme/programmeSlice";
import { createNew, getDataById } from "./api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Details from "./Details";
import Uploads from "./Uploads";

const useUpdateProgramme = ({ activeId, refetch, isEditData }) => {
  const dispatch = useDispatch();
  const { activeTab, currentFormData, cropData, fieldName } = useSelector(
    (state) => state.programme
  );
  const { data: typeData = {} } = useTypeListQuery();
  const [updateCategory] = useUpdateProgrammeListDataMutation();
  const nameRegExp = /^[^\s].*$/;
  const maxLength = 150;

  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [moveToTab, setMoveToTab] = useState(false);
  const [show, setShow] = useState(false);
  const [formikImage, setFormikImage] = useState("");
  const [imagePreview, setImagePreview] = useState(
    currentFormData !== "" ? currentFormData?.image : ""
  );
  const [imageTitleImagePreview, setImageTitleImagePreview] = useState(
    currentFormData !== "" ? currentFormData?.title_image : ""
  );

  const [imageVerticalThumbnailPreview, setImageVerticalThumbnailPreview] = useState(
    currentFormData !== "" ? currentFormData?.vertical_thumbnail : ""
  );
  const [imageBannerPreview, setImageBannerPreview] = useState(
    currentFormData !== "" ? currentFormData?.banner_image : ""
  );
  const [imageCoverPreview, setImageCoverPreview] = useState(
    currentFormData !== "" ? currentFormData?.list_thumbnail : ""
  );

  useEffect(() => {
    if (currentFormData !== "" && isEditData) {
      setImagePreview(currentFormData?.image ?? "");
      setImageVerticalThumbnailPreview(currentFormData?.vertical_thumbnail ?? "")
      setImageTitleImagePreview(currentFormData?.title_image ?? "");
      setImageBannerPreview(currentFormData?.banner_image ?? "");
      setImageCoverPreview(currentFormData?.list_thumbnail ?? "");
    }
  }, [currentFormData]);

  useEffect(() => {
    if (activeId) {
      getDataById(activeId).then((response) => {
        formik.setErrors("");
        setErrors("");

        if (response?.data?.status_code === 200) {
          dispatch(
            updateConfig((state) => {
              state.currentFormData = response?.data?.data;
            })
          );
        } else if (response?.data?.error_code === 403) {
          formik.setErrors(response?.data?.errors);
        }
      });
    }
  }, []);

  let categoryVal = Yup.object({
    name: Yup.string()
      .required("Name is Required")
      .matches(nameRegExp, "Leading space is not allowed")
      .min(3, "Minimum 3 characters"),
    description: Yup.string()
      .required("Description is Required")
      .matches(nameRegExp, "Leading space is not allowed"),
      program_type: Yup.mixed().required("Type is Required"),
    image: Yup.mixed().required("Image is Required"),
    title_image: Yup.string().required("Title Image is Required"),
    // vertical_thumbnail: Yup.mixed().required("Vertical"),
    banner_image: Yup.mixed().required("Banner Image is Required"),
    list_thumbnail: Yup.mixed().required("List Thumbnail is Required"),
  });

  const formik = useFormik({
    initialValues: {
      _id: activeId,
      name: null,
      description: null,
      // description_mal: null,
      program_type: null,
      image: null,
      title_image: null,
      // vertical_thumbnail: null,
      banner_image: null,
      list_thumbnail: null,
    },
    validationSchema: categoryVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      try {
        let obj = {
          name: values.name,
          description: values.description,
          // description_mal: values.description_mal,
          program_type: values.program_type?.id,
          status: +Boolean(values.status),
          show_on_banner: +Boolean(values.show_on_banner),
        };

        if (isEditData) {
          obj._id = values._id;
        }

        if (values.image?.name || values.image?.length === 0) {
          obj.image = values.image;
        }
        if (values.title_image?.name || values.title_image?.length === 0) {
          obj.title_image = values.title_image;
        }
        // if (values.vertical_thumbnail?.name || values.vertical_thumbnail?.length === 0) {
        //   obj.vertical_thumbnail = values.vertical_thumbnail;
        // }
        if (values.banner_image?.name || values.banner_image?.length === 0) {
          obj.banner_image = values.banner_image;
        }
        if (
          values.list_thumbnail?.name ||
          values.list_thumbnail?.length === 0
        ) {
          obj.list_thumbnail = values.list_thumbnail;
        }

        let formData = new FormData();

        Object.keys(obj).forEach((key) => {
          return formData.append(key, obj[key]);
        });
        if (isEditData === true) {
          updateCategory(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              toast.success("Successfully udpated");
            } else if (response?.data?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.data?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        } else {
          createNew(formData).then((response) => {
            formik.setErrors("");
            setErrors("");

            if (response?.data?.status_code === 200) {
              setIsLoading(false);
              refetch();
              resetForm();
              dispatch(
                updateConfig((state) => {
                  state.showCreateModal = false;
                })
              );
              toast.success("Successfully Added");
            } else if (response?.error_code === 403) {
              setIsLoading(false);
              formik.setErrors(response?.errors);
            } else if (response?.status_code === 500) {
              setIsLoading(false);
              toast.error("Something Went Wrong");
            } else {
              setIsLoading(false);
            }
          });
        }
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  const handleRemovePhotoImage = (e) => {
    formik.setFieldValue("photo", "");
  };

  useEffect(() => {
    if (isEditData && currentFormData) {
      Object.keys(currentFormData || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormData?.[key]);
      });
    }
    // eslint-disable-next-line
  }, [currentFormData, isEditData]);

  const closeModal = () => {
    // setFormikImage(null);
    setShow(false);
  };

  const handleCloseModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Details: 0,
        };
        state.activeTab = "Details";
      })
    );
    setShow((state) => (state = false));
  };

  const handleImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        // setFormikImage(null);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "image";
            state.cropData = {
              unit: "px",
              width: 100,
              height: 100,
              aspect: 1 / 1,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  const handleTitleImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase(); // Get the file extension
      if (extension === "png") {
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "title_image";
            state.cropData = {
              unit: "px",
              width: 300,
              height: 200,
              aspect: 3 / 2,
            };
          })
        );

        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "title_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Please select a PNG file.");
        formik.setFieldError("title_image", "Please select a PNG file.");
      }
    }
  };
  const handleBannerImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "banner_image";
            state.cropData = {
              unit: "px",
              width: 748,
              height: 818,
              aspect: 374 / 409,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };
  const handleThumbnail = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        // Proceed with handling the image upload
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "list_thumbnail";
            state.cropData = {
              unit: "px",
              width: 482,
              height: 715,
              aspect: 241 / 359,
            };
          })
        );
        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "banner_image",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Only JPEG, PNG, JPG and GIF files are allowed.");
      }
    }
  };

  const tabs = [
    {
      label: "Details",
      completed: calculateCompletionStatus(["name", "description", "program_type",
        // "description_mal",

      ]),
    },
    {
      label: "Uploads",
      completed: calculateCompletionStatus([
        "image",
        "title_image",
        // "vertical_thumbnail",
        "banner_image",
        "list_thumbnail",
      ]),
    },
  ];

  const tabsAndFields = [
    {
      label: "Details",
      fields: ["name", "description",
        // "description_mal",
        "program_type","status"],
    },
    {
      label: "Uploads",
      fields: ["image", "title_image", 
        // "vertical_thumbnail",
        "banner_image", "list_thumbnail"],
    },
  ];

  useEffect(() => {
    if (moveToTab === true) {
      handleMoveToErrorTab();
    }
    setMoveToTab(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.errors, moveToTab]);

  function calculateCompletionStatus(fields) {
    let finalArray = [];
    finalArray = fields;
    // eslint-disable-next-line array-callback-return
    var completedFields = finalArray?.filter((field) => {
      return !!formik?.values[field];
    });

    return (completedFields.length / fields.length) * 100;
  }

  const handleTabClick = (tab) => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = tab?.label;
      })
    );
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "Details":
        return (
          <Details
            formik={formik}
            tabs={tabs}
            typeList={typeData?.data?.program_type}
            handleMalayalamTextChange={handleMalayalamTextChange}
            getFieldError={getFieldError}
            currentFormData={currentFormData}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
            maxLength={maxLength}
          />
        );
      case "Uploads":
        return (
          <Uploads
            formik={formik}
            tabs={tabs}
            isLoading={isLoading}
            getFieldError={getFieldError}
            imagePreview={imagePreview}
            handleImage={handleImage}
            imageTitleImagePreview={imageTitleImagePreview}
            imageVerticalThumbnailPreview={imageVerticalThumbnailPreview}
            setImageVerticalThumbnailPreview={setImageVerticalThumbnailPreview}
            handleVerticalImage={handleVerticalImage}
            imageBannerPreview={imageBannerPreview}
            handleTitleImage={handleTitleImage}
            handleBannerImage={handleBannerImage}
            setImageTitleImagePreview={setImageTitleImagePreview}
            setImageBannerPreview={setImageBannerPreview}
            isEditData={isEditData}
            activeId={activeId}
            refetch={refetch}
            show={show}
            setShow={setShow}
            closeModal={closeModal}
            fieldName={fieldName}
            setImagePreview={setImagePreview}
            cropData={cropData}
            formikImage={formikImage}
            setFormikImage={setFormikImage}
            handleClick={handleClick}
            handleThumbnail={handleThumbnail}
            imageCoverPreview={imageCoverPreview}
            setImageCoverPreview={setImageCoverPreview}
          />
        );
      default:
        return null;
    }
  };

  function setActiveTabByName(active) {
    setMoveToTab(false);
    const updatedTab = tabs.find((tab) => tab?.label === active);
    dispatch(
      updateConfig((state) => {
        state.activeTab = updatedTab?.label;
      })
    );
  }

  const handleMoveToErrorTab = () => {
    const firstErrorTab = Object.keys(formik.errors)[0];
    if (firstErrorTab && firstErrorTab !== activeTab) {
      const errorTab = tabsAndFields.find((tab) =>
        tab.fields.includes(firstErrorTab)
      );
      setActiveTabByName(errorTab?.label);
    }
    setMoveToTab(true);
  };

  const handleVerticalImage = (e) => {
    const files = e.target.files;
    if (files?.length > 0) {
      const file = files[0];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["jpeg", "png", "jpg", "gif"].includes(extension)) {
        setShow(true);
        dispatch(
          updateConfig((state) => {
            state.fieldName = "vertical_thumbnail";
            state.cropData = {
              unit: "px",
              width: 375,
              height: 500,
              aspect: 375 / 500,
            };
          })
        );

        if (file.size / (1024 * 1024) <= 1) {
          setFormikImage(files);
        } else {
          toast.error("The image must be less than 1MB in size.");
          formik.setFieldError(
            "vertical_thumbnail",
            "The image must be less than 1MB in size."
          );
          closeModal();
        }
      } else {
        toast.error("Please select a PNG file.");
        formik.setFieldError("vertical_thumbnail", "Please select a PNG file.");
      }
    }
  };

  const handleMalayalamTextChange = (e) => {
    const newText = e.target.value;
    if (newText?.length <= 300) {
      formik.setFieldValue("description_mal", newText);
    }
  };

  return {
    formik,
    errors,
    tabs,
    isLoading,
    activeTab,
    currentFormData,
    handleMoveToErrorTab,
    handleCloseModal,
    handleRemovePhotoImage,
    handleImage,
    handleTabClick,
    renderTabContent,
    imagePreview,
    handleTitleImage,
    imageTitleImagePreview,
    handleBannerImage,
    imageBannerPreview,
    imageCoverPreview,
    handleThumbnail,
    imageVerticalThumbnailPreview,
    handleVerticalImage
  };
};

export default useUpdateProgramme;
