import React, { useState, useEffect } from "react";
import Select from "react-select";

function TagInput({
  formik,
  tagsList,
  setTagsList,
  getFieldError,
  incomingTagsList,
  draggedItemById,
  setDraggedItemById
}) {
  const [inputValue, setInputValue] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    // Initialize tagsList with Formik's values or incomingTagsList
    if (incomingTagsList && incomingTagsList?.length > 0) {
      setTagsList(incomingTagsList?.map((tag) => tag?.name));
    } else {
      setTagsList(formik?.values?.tags || []);
    }
  }, [incomingTagsList, formik?.values?.tags, setTagsList]);

  const handleInputChange = (newValue) => {
    // setDraggedItemById([]);
    setInputValue(newValue);  
  };

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setTagsList([inputValue, ...tagsList]);
        setInputValue("");
        // event.preventDefault();
        break;
      default:
        return;
    }
  };

  const handleChange = (newValue, actionMeta) => {
    const newTags = newValue.map((option) => option?.value);
    setTagsList(newTags);
    // Call Formik's handleChange to update form values
    // formik.handleChange({ target: { name: "tags", value: newTags } });
  };

  const options = tagsList
  .filter(tag => tag !== "Null")
  .map(tag => ({ value: tag, label: tag }));


  const handletags = (ids) => {
    const newTags = ids.map((option) => option);
    setTagsList(newTags);
    formik.handleChange({ target: { name: "tags", value: newTags } });
    // dispatch(
    //   updateConfig((state) => {
    //     state.currentTags = ids;
    //   })
    // );
  };

  

  useEffect(() => {
    if(true) {
      setSelectedTags(draggedItemById?.tags);
    }
    
  }, [draggedItemById?._id]);

  // Function to handle changes in selected tags
  const handleTagsChange = (selectedOptions) => {
    setSelectedTags(selectedOptions?.map(option => option.value));
    const newTags = selectedOptions?.map((option) => option.value);
    setTagsList(newTags);
    formik.handleChange({ target: { name: "tags", value: newTags } });
    // dispatch(
    //   updateConfig((state) => {
    //     state.currentTags = selectedOptions.map(option => option.value);
    //   })
    // );
    
  };

  return (
    // <div>
    //   <Select
    //     id="tags"
    //     inputValue={inputValue}
    //     onInputChange={handleInputChange}
    //     onKeyDown={handleKeyDown}
    //     // onChange={handleChange}
    //     onChange={(value) =>
    //       handletags(value.map((v) => v.value))
    //     }
    //     options={options}
    //     isMulti
    //     placeholder="Type and press Enter to add tags"
    //     onBlur={formik.handleBlur("tags")}
    //     // value={draggedItemById?.tags?.map(tag => ({ value: tag, label: tag }))} 
    //     // value={options?.filter((p) =>
    //     //   draggedItemById?.tags?.includes(p.value)
    //     // )}
    //   />
    //   {getFieldError("tags") && (
    //     <span className="error-text">{getFieldError("tags")}</span>
    //   )}
    // </div>
    <div>
      <Select
        id="tags"
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onChange={handleTagsChange}
        options={options}
        isClearable={false}
        isMulti
        placeholder="Type and press Enter to add tags"
        onBlur={formik.handleBlur("tags")}
        // value={selectedTags?.length > 0 ? selectedTags?.map(tag => ({ value: tag, label: tag })) : draggedItemById?.tags?.map(tag => ({ value: tag, label: tag }))} 
        value={selectedTags?.map(tag => ({ value: tag, label: tag }))}
      />
      {getFieldError("tags") && (
        <span className="error-text">{getFieldError("tags")}</span>
      )}
    </div>

  );
}

export default TagInput;
