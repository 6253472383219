import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key] === "status") {
        return `${key}=${parseInt(params[key])}&`;
      } else if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    try {
      const response = await api[method](
        `${endpoint}?${getParams("_id")}${getParams("from_date")}${getParams(
          "to_date"
        )}${getParams("status")}${getParams("category_id")}${getParams(
          "rj_id"
        )}${getParams("type")}${getParams("programe_id")}${getParams(
          "sort_by"
        )}sort_order=${params?.sort_order || ""}&${getParams("search")}page=${
          params?.page || 1
        }&${getParams("length")}${getParams("media_id")}${getParams("search")}${getParams("is_update")}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err.response?.data || err.message,
      };
    }
  };

export const podcast = createApi({
  reducerPath: "podcastApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Podcast"],
  endpoints: (builder) => ({
    getPodcastListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/podcast/list`,
      }),
      provideTags: ["Podcast"],
    }),
    getPodcastFormDataById: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/podcast/edit`,
      }),
    }),
    updatePodcastListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/podcast/update`,
      }),

      invalidatesTags: ["Podcast"],
    }),
    deletePodcastData: builder.mutation({
      query: (values) => ({
        method: "delete",
        params: values,
        endpoint: `/admin/podcast/delete`,
      }),

      invalidatesTags: ["Podcast"],
    }),
    categoryDataList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/podcast/basic-data`,
      }),
    }),
    programDataList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/programme/list`,
      }),
    }),
    statusChange: builder.mutation({
      query: (values) => ({
        method: "post",
        params: values,
        endpoint: `/admin/podcast/status-change`,
      }),
    }),
  }),
});

export const {
  useGetPodcastListDataQuery,
  useGetPodcastFormDataByIdQuery,
  useUpdatePodcastListDataMutation,
  useDeletePodcastDataMutation,
  useCategoryDataListQuery,
  useProgramDataListQuery,
  useStatusChangeMutation,
} = podcast;
