import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useValidations from "../../../utils/hooks/useValidations";
import { emailOrPhone } from "../api";
import { toast } from "react-toastify";

const useEmailOrPhone = (emailRef,countryCodeRef,otpExpiryRef) => {
  const { validateEmail, onlyNumbers } = useValidations();
  const navigate = useNavigate();

  const [inputvalue, setInputvalue] = useState("");
  const [code, setCode] = useState("+91");
  const [errors, setErrors] = useState("");
  const [flag, setFlag] = useState(false);

  const [status, setStatus] = useState("idle");
  const [info, setInfo] = useState({});

  useEffect(() => {
    if (status === "success") {
      navigate("otp");
    } else if (status === "failed") {
      setErrors(info?.inputvalue);
    }
    // eslint-disable-next-line
  }, [status]);

  const handleBlur = () => {
    setErrors("");
  };

  const handleChange = (e) => {
    setErrors("");
    if (onlyNumbers(e.target.value)) {
      setInputvalue(e.target.value);
      setFlag(true);
    } else {
      setInputvalue(e.target.value);
      setFlag(false);
    }
  };

  
  const handleChangeOne = (e) => {
    setErrors("");
    setCode(e.dial_code);
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleSubmit = (e) => {
    e?.preventDefault();

    if (inputvalue) {
      setStatus("pending");
      const isValidPhoneNumber = /^[0-9]{5,15}$/.test(inputvalue);
      emailRef.current = inputvalue;
      countryCodeRef.current = code;
      if (flag === false ? !validateEmail(inputvalue): !isValidPhoneNumber) {
        setErrors(flag === false ? "Invalid Email" : "Phone number must be 4 to 16 digits long.");
        setStatus("failed");
      } else {
        emailOrPhone(inputvalue, code).then((response) => {
          if (response?.status === 200) {
            setStatus("success");
            toast.success(response?.data?.message);
            otpExpiryRef.current = response?.data?.data?.otp_expiry_time;
          } else if (response?.status_code === 400) {
            setInfo({
              inputvalue: response?.message?.value?.[0],
            });
            setStatus("failed");
            // } else if (response?.error_code === 403) {
            //   setInfo({
            //     inputvalue: response?.message?.value?.[0],
            //   });
            //   setStatus("failed");
          } else if (response?.error_code === 403) {
            setInfo({
              inputvalue: response?.errors?.country_code,
            });
            setStatus("failed");
            setTimeout(()=>{
              setErrors(response?.errors?.user_name);
            },100)

          }else setStatus("An error occured");
        });
      }
    } 
    else {
      setErrors("*Cannot be empty");
    }
  };
  return {
    code,
    inputvalue,
    setInputvalue,
    errors,
    status,
    handleChange,
    handleChangeOne,
    handleSubmit,
    handleBlur,
    handleLoginClick,
    flag,
  };
};

export default useEmailOrPhone;
