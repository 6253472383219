import { getAxiosInstance } from "../../../api";

  export const getDataById = async (id) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/user/edit?_id=${id}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  
 