import { Input, Button } from "@wac-ui-dashboard/wac_component_library";
import Select from "react-select";
import useDetails from "./useDetails";
import { CountryFlag } from "../../../../CountryFlag";

const Details = ({ formik, getFieldError, maxLength,
  handleTextChange }) => {
  const { handleCloseModal, handleNext } = useDetails({
    formik,
  });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name (Max 15 Char)*"} 
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
        onKeyDown={(e) => {
          const regex = /^[A-Za-z\s]*$/;
          if (!regex.test(e.key)) {
            e.preventDefault();
          }
        }}
        maxLength={15}
      />

      {/* <Input
        label={"Description"}
        type="text"
        id="description"
        name="description"
        className={`pro-input lg ${getFieldError("description") && " error"}`}
        {...formik.getFieldProps("description")}
        error={getFieldError("description")}
        errorMessage={getFieldError("description")}
      /> */}
      <div className="input-wrap pro-mb-4">
      <div className="pro-d-flex pro-justify-between pro-mb-1 pro-w-100">
        <label htmlFor="" className="pro-font-sm pro-fw-medium">
          Description*
        </label>
          <span className="pro-font-xs pro-opacity-60">
            {formik.values.description !== null ? <>{formik.values.description?.length}/{maxLength}</> : <>{0}/{maxLength}</>}
          </span>
        </div>
        <textarea
          id="description"
          rows={4}
          name="description"
          className={`pro-input lg ${
            formik.touched.description && formik.errors.description && " error"
          }`}
          {...formik.getFieldProps("description")}
          value={formik.values.description}
          onChange={handleTextChange}
        ></textarea>
        {formik.touched.description && formik.errors.description && (
          <span className="error-text">{formik.errors.description}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4 ">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Country Code*
        </label>
        <Select
          id="country_code"
          options={CountryFlag}
          getOptionLabel={(option) => `${option.dial_code}`}
          getOptionValue={(option) => `${option.dial_code}`}
          className={`pro-input lg ${
            getFieldError("country_code") && " error"
          }`}
          value={CountryFlag?.filter(
            (p) =>
              p.dial_code?.toString() === formik.values.country_code?.toString()
          )}
          classNamePrefix="pro-input"
          onBlur={() => formik.handleBlur("country_code")}
          onChange={(value) =>
            formik.setFieldValue("country_code", value?.dial_code)
          }
        ></Select>
        {getFieldError("country_code") && (
          <span className="error-text">{getFieldError("country_code")}</span>
        )}
      </div>

      <Input
        label={"Mobile Number*"}
        type="text"
        id="mobile"
        name="mobile"
        className={`pro-input lg ${getFieldError("mobile") && " error"}`}
        {...formik.getFieldProps("mobile")}
        error={getFieldError("mobile")}
        errorMessage={getFieldError("mobile")}
        onKeyDown={(e) => {
          // Allow backspace (keyCode 8)
          if (e.keyCode === 8) {
            return;
          }

          const regex = /^[0-9]*$/; // Only allows digits
          if (!regex.test(e.key)) {
            e.preventDefault();
          }
        }}
        maxLength={15}
      />

      <Input
        label={"Email*"}
        type="text"
        id="email"
        name="email"
        className={`pro-input lg ${getFieldError("email") && " error"}`}
        {...formik.getFieldProps("email")}
        error={getFieldError("email")}
        errorMessage={getFieldError("email")}
      />

      <Input
        label={"Experience*"}
        type="text"
        id="experience"
        name="experience"
        className={`pro-input lg ${getFieldError("experience") && " error"}`}
        {...formik.getFieldProps("experience")}
        error={getFieldError("experience")}
        errorMessage={getFieldError("experience")}
        onKeyDown={(e) => {
          // Allow backspace (keyCode 8)
          if (e.keyCode === 8) {
            return;
          }

          const regex = /^[0-9]*$/; // Only allows digits
          if (!regex.test(e.key)) {
            e.preventDefault();
          }
        }}
        maxLength={2}
      />

      <Input
        label={"Specialization*"}
        type="text"
        id="specialization"
        name="specialization"
        className={`pro-input lg ${
          getFieldError("specialization") && " error"
        }`}
        {...formik.getFieldProps("specialization")}
        error={getFieldError("specialization")}
        errorMessage={getFieldError("specialization")}
        onKeyDown={(e) => {
          const regex = /^[A-Za-z\s]*$/;
          if (!regex.test(e.key)) {
            e.preventDefault();
          }
        }}
        maxLength={32}
      />

      <Input
        label={"Qualification*"}
        type="text"
        id="qualification"
        name="qualification"
        className={`pro-input lg ${getFieldError("qualification") && " error"}`}
        {...formik.getFieldProps("qualification")}
        error={getFieldError("qualification")}
        errorMessage={getFieldError("qualification")}
        onKeyDown={(e) => {
          const regex = /^[A-Za-z\s]*$/;
          if (!regex.test(e.key)) {
            e.preventDefault();
          }
        }}
        maxLength={32}
      />

      <Input
        label={"Twitter Link"}
        type="text"
        id="twitter_link"
        name="twitter_link"
        className={`pro-input lg ${getFieldError("twitter_link") && " error"}`}
        {...formik.getFieldProps("twitter_link")}
        error={getFieldError("twitter_link")}
        errorMessage={getFieldError("twitter_link")}
      />

      <Input
        label={"Instagram Link"}
        type="text"
        id="instagram_link"
        name="instagram_link"
        className={`pro-input lg ${
          getFieldError("instagram_link") && " error"
        }`}
        {...formik.getFieldProps("instagram_link")}
        error={getFieldError("instagram_link")}
        errorMessage={getFieldError("instagram_link")}
      />

      <Input
        label={"Facebook Link"}
        type="text"
        id="facebook_link"
        name="facebook_link"
        className={`pro-input lg ${getFieldError("facebook_link") && " error"}`}
        {...formik.getFieldProps("facebook_link")}
        error={getFieldError("facebook_link")}
        errorMessage={getFieldError("facebook_link")}
      />

      <div className="input-wrap pro-mb-4 pro-flex-row pro-items-center">
        
        <div className="form-check form-switch  pro-mb-0">
          <input
            type="checkbox"
            className="form-check-input cursor-pointer "
            id="status"
            name="status"
            checked={Boolean(formik.values?.status) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "status",
                !Boolean(formik.values?.status) ?? false
              );
            }}
          />
        </div>
        <label htmlFor="" className="pro-font-sm pro-lh-base pro-fw-medium">
          Status
        </label>
      </div>

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          onClick={() => handleNext()}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
export default Details;
