import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  sortBy: "",
  sortOrder: "",
  search: "",
  showCreateModal: false,
  activeId: null,
  currentFormData: [null],
  activeTab: "Details",
  primary_radio_file: "",
  completedTabs: {
    Details: false,
    Uploads: false,
  },
  CompletedPercentage: {
    Details: 0,
    Uploads: 0,
  },
  isEditData: false, 
  status : "",
  category_id: [],
  rj_id: [],
  type: "",
  programe_id: [],
  from_date: "",
  to_date: "",
  clearSelection: false,
  selectedUpdateRows: [],
  imageData:"",
  videoData: "",
  dataTitle: "",
  dataId:"",
  currentFormData:{},
  fieldName:"",
  cropData: "",
  titleImageData:"",
};

const mediavideoSlice = createSlice({
  name: "mediavideo",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.status= action.payload.status;
      state.category_id= action.payload.category_id;
      state.rj_id= action.payload.rj_id;
      state.type= action.payload.type;
      state.programe_id= action.payload.programe_id;
      state.from_date= action.payload.from_date;
      state.to_date= action.payload.to_date;
    },
    resetExtraFilters: (state, action) => {
      state.status= '';
      state.category_id= '';
      state.rj_id= '';
      state.type= '';
      state.programe_id= '';
      state.from_date= '';
      state.to_date= '';
    },
  },
  
});

export const { updateConfig, setStoreFormValues,resetExtraFilters } = mediavideoSlice.actions;

export default mediavideoSlice.reducer;
