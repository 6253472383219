import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteCampaignDataMutation,
  useGetCampaignListDataQuery,
  useStatusChangeMutation,
} from "../../../store/queries/campaign";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { updateConfig } from "../../../store/slices/Campaign/campaignSlice";
import { toast } from "react-toastify";
import { current } from "@reduxjs/toolkit";
import { campaign as campaignQuery } from "../../../store/queries/campaign";

const useCampaignList = () => {
  const dispatch = useDispatch();
  const campaignState = useSelector((state) => state.campaign);
  const { showCreateModal, isEditData, activeId, selectedUpdateRows } =
    useSelector((state) => state.campaign);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [campaignDelete] = useDeleteCampaignDataMutation();
  const [statusChange] = useStatusChangeMutation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [filterShow, setFilterShow] = useState(false);

  let paginationOptions = [
    { label: "10 per page", value: 10 },
    { label: "20 per page", value: 20 },
    { label: "30 per page", value: 30 },
  ];

  const {
    data: campaign = {},
    isFetching,
    isLoading,
    isSuccess,
    refetch,
  } = useGetCampaignListDataQuery({
    length: campaignState.currentPageSize,
    page: campaignState.currentPage,
    search: campaignState.search,
    sort_by: campaignState.sortBy,
    sort_order: campaignState.sortOrder,
    status: campaignState.status,
    start_date: campaignState.start_date,
    end_date: campaignState.end_date,
  });

  // const { data: formData, isSuccess: isUpdateDataSuccess } =
  //   useGetCampaignListDataQuery({ skip: isEditData === false });

  const hasCreatePermission = useMemo(() => {
    let permission = campaign?.data?.original?.permissions?.create_permission;
    return permission ?? false;
  }, [campaign]);

  const hasEditPermission = useMemo(() => {
    let permission = campaign?.data?.original?.permissions?.edit_permission;
    return permission ?? false;
  }, [campaign]);
  const hasViewPermission = useMemo(() => {
    let permission = campaign?.data?.original?.permissions?.list_permission;
    return permission ?? false;
  }, [campaign]);
  const hasDeletePermission = useMemo(() => {
    let permission = campaign?.data?.original?.permissions?.delete_permission;
    return permission ?? false;
  }, [campaign]);

  const tableHeading = {
    name: { label: "Name", sortable: true },
    formatted_start_date: { label: "Start Date", sortable: true },
    formatted_end_date: { label: "End Date", sortable: true },
    priority_label: { label: "Priority", sortable: true },
    status_label: { label: "Status", sortable: true },
  };

  useEffect(() => {
    dispatch(
      globalUpdateConfig((state) => {
        state.tableFields = tableHeading;
      })
    );
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   refetch();
  // }, [campaignState.status]);

  const deleteCampaignDataClick = (item) => {
    setShowDeleteModal(true);
    dispatch(
      updateConfig((state) => {
        state.selectedUpdateRows = item;
      })
    );
  };

  useEffect(() => {
    if (campaignState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [campaignState.clearSelection]);

  const deleteCampaign = () => {
    campaignDelete({
      _id: selectedUpdateRows,
    }).then((response) => {
      if (response?.data?.status_code === 200) {
        refetch();
        dispatch(
          updateConfig((state) => {
            state.clearSelection = true;
          })
        );
        setShowDeleteModal(false);
        toast.success("Successfully Deleted");
      } else if (response?.error?.status === 422) {
        toast.success("Something went wrong");
      }
    });
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  // useEffect(() => {
  //   if (isEditData && isUpdateDataSuccess) {
  //     dispatch(
  //       updateConfig((state) => {
  //         state.currentFormData = {
  //           ...formData?.data?.data,
  //         };
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line
  // }, [isEditData, isUpdateDataSuccess, formData]);

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  const handleClearClick = () => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentFilter = null;
        state.currentPageSize = 10;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
      })
    );
  };

  const handleSort = (label) => {
    if (campaignState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortOrder = campaignState.sortOrder === "0" ? "1" : "0";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.currentPage = 1;
          state.sortBy = label;
          state.sortOrder = "0";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = ""
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
  };
  const handleEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const handleCampaignDataEditClick = (id) => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.activeId = id[0];
      })
    );
    dispatch(
      updateConfig((state) => {
        state.currentFormData = campaign?.data?.original?.data?.id;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.isEditData = true;
        state.activeId = id?.[0];
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
    if (isEditData) {
      dispatch(
        updateConfig((state) => {
          state.currentFormData = {};
          state.isEditData = false;
        })
      );
    }
  };

  const closeEditModal = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };

  const handleToggle = (e, data) => {
    let params = {
      _id: data?._id,
    };
    statusChange(params);
    dispatch(
      campaignQuery.util.updateQueryData(
        "getCampaignListData",
        {
          length: campaignState.currentPageSize,
          page: campaignState.currentPage,
          search: campaignState.search,
          sort_by: campaignState.sortBy,
          sort_order: campaignState.sortOrder,
          status: campaignState.status,
          start_date: campaignState.start_date,
          end_date: campaignState.end_date,
        },
        (cachedData) => {
          let updatedCachedData = { ...current(cachedData) };
          let newResult = updatedCachedData?.data?.original?.data?.map((res) =>
            res?._id === data?._id
              ? {
                  ...res,
                  status: data?.status === 0 ? 1 : 0,
                }
              : res
          );
          updatedCachedData = {
            ...updatedCachedData,
            data: {
              ...updatedCachedData.data,
              original: {
                ...updatedCachedData.data.original,
                data: newResult,
              },
            },
          };
          return updatedCachedData;
        }
      )
    );
  };

  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => (
        <p className="pro-mb-0">{data[field] ? `${data[field]}` : "-"}</p>
      ),
      status_label: (field, data) => {
        const isChecked = data?.status === 1;
        return (
          <>
            {hasEditPermission ? (
              <div className="form-check form-switch ">
                <input
                  className="form-check-input cursor-pointer"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={isChecked}
                  onChange={(e) => handleToggle(e, data)}
                />
              </div>
            ) : (
              data?.status_label?.name
            )}
          </>
        );
      },
      formatted_start_date: (field, data) => (
        <p className="pro-mb-0">{data[field] ? `${data[field]}` : "-"}</p>
      ),
      formatted_end_date: (field, data) => (
        <p className="pro-mb-0">{data[field] ? `${data[field]}` : "-"}</p>
      ),
      priority_label: (field, data) => (
        <p className="pro-mb-0">
          {data[field]?.name ? `${data[field]?.name}` : "-"}
        </p>
      ),
    };
    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };

  return {
    campaign,
    activeId,
    campaignState,
    currentPage: campaignState.currentPage,
    activeFilter: campaignState.currentFilter,
    showCreateModal,
    showEditModal,
    paginationOptions,
    isFetching,
    isLoading,
    isSuccess,
    totalRecords: campaignState.recordsTotal,
    showDeleteModal,
    // isUpdateDataSuccess,
    isEditData,
    filterShow,
    setFilterShow,
    handleClearClick,
    handleEditModal,
    handleCreateClick,
    deleteCampaignDataClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    handleSort,
    handleSearch,
    handleCampaignDataEditClick,
    closeEditModal,
    closeModal,
    getRow,
    deleteCampaign,
    refetch,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  };
};

export default useCampaignList;
