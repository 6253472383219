import {
  HeadingGroup,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
  ConfirmationBox,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import useMediaVideoList from "./useMediaVideoList";
import UpdateMediaVideo from "../UpdateMediaVideo";
import { ImageCard } from "../../Global/ImageCard";
import Player from "../../Global/Player";
import Filter from "../Filter";
import Style from "../mediavideo.module.scss";

const MediaVideoList = () => {
  const {
    mediavideo,
    mediavideoState,
    activeId,
    currentPage,
    activeFilter,
    showCreateModal,
    paginationOptions,
    isFetching,
    isLoading,
    showDeleteModal,
    imageData,
    showImageModal,
    videoData,
    showVideoModal,
    isEditData,
    filterShow,
    dataFileIndex,
    setDataFileIndex,
    dataTitle,
    dataId,
    titleImageData,
    showTitleImageModal,
    setFilterShow,
    handleEditModal,
    handleCreateClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    refetch,
    handleSort,
    handleSearch,
    handleMediavideoDataEditClick,
    closeModal,
    deleteMediavideoDataClick,
    getRow,
    deleteMediavideo,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  } = useMediaVideoList();


  return (
    <>
      <HeadingGroup
        title={"Video"}
        buttonTitle={hasCreatePermission ?? false ? "Add New" : ""}
        extraClassName={`pro-mb-4`}
        handleClick={handleCreateClick}
      />
      <div className="col-12 pro-pb-6">
        <SearchFilters
          showDateRange={false}
          activeFilter={activeFilter}
          onSearchInput={handleSearch}
          loading={isLoading}
          showActions={false}
          handleActionClick={handleEditModal}
          searchInputProps={{ value: mediavideoState?.search }}
          SearchIcon={
            <span className="material-symbols-outlined"> search </span>
          }
          extraFilters={
            <div className="col-auto">
              <button
                className={`pro-btn pro-btn-outline pro-items-center pro-h-100`}
                onClick={() => setFilterShow(true)}
              >
                <span className="material-symbols-outlined">tune</span>
                <span>Filter</span>
              </button>
            </div>
          }
        />
        <Table
          data={mediavideo?.data?.original?.data || []}
          uniqueID={"_id"}
          fields={tableFields}
          showCheckBox={hasEditPermission || hasDeletePermission ? true : false}
          SortIcon={<FaSort />}
          handleEdit={handleMediavideoDataEditClick}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          handleDelete={deleteMediavideoDataClick}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          handleSort={handleSort}
          getRow={getRow}
          loading={isFetching}
          perpage={mediavideoState.currentPageSize}
          assignable={false}
          multiSelect={false}
          clear={mediavideoState.clearSelection}
          editable={hasEditPermission ? true : false}
          deletable={hasDeletePermission ? true : false}
        />
        {mediavideo?.data?.original?.data?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalPageCount={Math.ceil(
              mediavideo?.data?.original?.recordsTotal /
                mediavideoState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
      </div>
      <ModalLayout
        centered
        show={showDeleteModal}
        handleClose={closeDeleteModal}
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={`Are you sure you want to delete?`}
            isRight={true}
            cancelText={`Cancel`}
            submitText={`Delete`}
            cancelAction={closeDeleteModal}
            submitAction={deleteMediavideo}
          >
            ConfirmationBox
          </ConfirmationBox>
        </div>
      </ModalLayout>

      <OffCanvasLayout
        show={showCreateModal}
        handleClose={closeModal}
        title={`${isEditData ? "Update Video Details" : "Create Video"}`}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
        backdrop={"static"}
      >
        <UpdateMediaVideo
          activeId={activeId}
          refetch={refetch}
          isEditData={isEditData}
        />
      </OffCanvasLayout>

      <OffCanvasLayout
        centered={true}
        show={filterShow}
        handleClose={() => {
          setFilterShow(false);
        }}
        title={"Filter"}
        closeIcon={<span className="material-symbols-outlined">close</span>}
      >
        <Filter setShowform={setFilterShow} refetch={refetch} />
      </OffCanvasLayout>

      <ModalLayout show={showImageModal} handleClose={closeModal}>
        <ImageCard data={imageData} handleClose={closeModal} />
      </ModalLayout>
      <ModalLayout show={showTitleImageModal} handleClose={closeModal}>
        <ImageCard data={titleImageData} handleClose={closeModal} />
      </ModalLayout>
      <ModalLayout
        show={showVideoModal}
        handleClose={closeModal}
        centered
        propStyle={{ root: Style.modal_root }}
      >
        <Player
          data={videoData}
          dataFileIndex={dataFileIndex}
          setDataFileIndex={setDataFileIndex}
          title={dataTitle}
          dataList={mediavideo?.data?.original?.data}
          dataId={dataId}
          handleClose={closeModal}
        />
      </ModalLayout>
    </>
  );
};

export default MediaVideoList;
