import Style from "./tooltipCard.module.scss";
import Charts from "../Charts";

const TooltipCard = ({ graphData }) => {

  const lineData = {
    labels: graphData?.label,
    datasets: [
      {
        label: "",
        data: graphData?.data,
        fill: true,
        backgroundColor:"rgba(245, 158, 11, 0.2)",
        borderColor: "rgba(245, 158, 11, 1)",
        tension: 0.1,
      },
    ],
  };
  
  const lineOptions = {
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          boxWidth: 8,
          boxHeight: 8,
          borderColor: "transparent",
          itemMargin: 5,
          font: {
            size: 12,
          },
        },
      },
      layout: {
        padding: {
          left: 0,
          right: 10,
          top: 0,
          bottom: 50,
        },
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
        },
        grid: {
          color: "#D2D1D4",
        },
        border: {
          display: false,
          dash: [4, 4],
        },
        display: true,
        title: {
          display: true,
          text: "Views",
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };
  
  return (
    <div className={`pro-d-flex pro-flex-column pro-p-5 ${Style.root}`}>
      <Charts
      type="line"
      labels={lineData?.labels}
      datasets={lineData?.datasets}
      options={lineOptions}
      ></Charts>
    </div>
  );
};

export default TooltipCard;
