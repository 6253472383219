import { useDispatch } from "react-redux";
import { updateConfig } from "../../../../store/slices/NewsCategory/newscategorySlice";

const useDetails = () => {
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleNext = () => {
    dispatch(
      updateConfig((state) => {
        state.activeTab = "Uploads";
      })
    );
  };
  return { handleCloseModal, handleNext };
};

export default useDetails;
