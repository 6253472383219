import {
  HeadingGroup,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
  ConfirmationBox,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import { FaSort } from "react-icons/fa";
import useAdvertisementList from "./useAdvertisementList";
import { ImageCard } from "../../Global/ImageCard";
import UpdateAdvertisement from "../UpdateAdvertisement";
import Player from "../../Global/Player";
import Filter from "../Filter";
import Style from "../advertisement.module.scss";

const AdvertisementList = () => {
  const {
    advertisement,
    advertisementState,
    activeId,
    currentPage,
    activeFilter,
    paginationOptions,
    isFetching,
    isLoading,
    showDeleteModal,
    imageData,
    showImageModal,
    videoData,
    showVideoModal,
    isEditData,
    typeData,
    audioData,
    audioDataTitle,
    audioDataId,
    showAudioModal,
    filterShow,
    dataFileIndex,
    dataTitle,
    dataId,
    setDataFileIndex,
    setFilterShow,
    handleEditModal,
    handleCreateClick,
    closeDeleteModal,
    handlePagination,
    handlePageSize,
    refetch,
    handleSort,
    handleSearch,
    handleAdvertisementDataEditClick,
    closeModal,
    deleteAdvertisementDataClick,
    getRow,
    deleteAdvertisement,
    tableFields,
    hasCreatePermission,
    hasEditPermission,
    hasViewPermission,
    hasDeletePermission,
  } = useAdvertisementList();

  return (
        <>
          <HeadingGroup
            title={"Advertisement"}
            buttonTitle={hasCreatePermission ?? false ? "Add New" : ""}
             extraClassName={`pro-mb-4`}
            handleClick={handleCreateClick}
          />
            <div className="col-12 pro-pb-6">
              <SearchFilters
                showDateRange={false}
                activeFilter={activeFilter}
                onSearchInput={handleSearch}
                loading={isLoading}
                showActions={false}
                handleActionClick={handleEditModal}
                showFilters={true}
                SearchIcon={
                  <span className="material-symbols-outlined"> search </span>
                }
                extraFilters={
                  <div className="col-auto">
                    <button
                    className={`pro-btn pro-btn-outline pro-items-center pro-h-100`}
                    onClick={() => setFilterShow(true)}
                    >
                      <span className="material-symbols-outlined">tune</span>
                      <span>Filter</span>
                    </button>
                  </div>
                }
              />
            <Table
              data={advertisement?.data?.original?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              showCheckBox={
                hasEditPermission || hasDeletePermission ? true : false
              }
              SortIcon={<FaSort />}
              handleEdit={handleAdvertisementDataEditClick}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              handleDelete={deleteAdvertisementDataClick}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handleSort}
              getRow={getRow}
              loading={isFetching}
              perpage={advertisementState.currentPageSize}
              assignable={false}
              multiSelect={false}
              clear={advertisementState.clearSelection}
              editable={hasEditPermission ? true : false}
              deletable={hasDeletePermission ? true : false}
            />
            {advertisement?.data?.original?.data?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalPageCount={Math.ceil(
                  advertisement?.data?.original?.recordsTotal /
                    advertisementState.currentPageSize
                )}
                onPageChange={handlePagination}
                options={paginationOptions}
                onActionChange={handlePageSize}
                center
              />
            )}
            </div>
            <ModalLayout
              centered
              show={showDeleteModal}
              handleClose={closeDeleteModal}
            >
              <div className="pro-m-5">
                <ConfirmationBox
                  title={`Are you sure you want to delete?`}
                  isRight={true}
                  cancelText={`Cancel`}
                  submitText={`Delete`}
                  cancelAction={closeDeleteModal}
                  submitAction={deleteAdvertisement}
                >
                  ConfirmationBox
                </ConfirmationBox>
              </div>
            </ModalLayout>

            <OffCanvasLayout
              show={advertisementState.showCreateModal}
              handleClose={closeModal}
              title={`${
                isEditData
                  ? "Update Advertisement Details"
                  : "Create Advertisement"
              }`}
              closeIcon={
                <span className="material-symbols-outlined"> close </span>
              }
              backdrop={"static"}
            >
              <UpdateAdvertisement
                activeId={activeId}
                refetch={refetch}
                isEditData={isEditData}
              />
            </OffCanvasLayout>

            <OffCanvasLayout
              centered={true}
              show={filterShow}
              handleClose={() => {
                setFilterShow(false);
              }}
              title={"Filter"}
              closeIcon={
                <span className="material-symbols-outlined">close</span>
              }
            >
              <Filter setShowform={setFilterShow} refetch={refetch}/>
            </OffCanvasLayout>

            <ModalLayout show={showImageModal} handleClose={closeModal}>
              <ImageCard data={imageData} handleClose={closeModal} />
            </ModalLayout>

            <ModalLayout show={showVideoModal} handleClose={closeModal}>
              <Player
                data={videoData}
                dataFileIndex={dataFileIndex}
                setDataFileIndex={setDataFileIndex}
                dataList={advertisement?.data?.original?.data}
                title={dataTitle}
                dataId={dataId}
                handleClose={closeModal}
              />
            </ModalLayout>

            {showAudioModal && (
              <div className={Style.audio_popup}>
                <Player
                  data={audioData}
                  dataFileIndex={dataFileIndex}
                  setDataFileIndex={setDataFileIndex}
                  typeData={typeData}
                  dataList={advertisement?.data?.original?.data}
                  title={audioDataTitle}
                  audioDataId={audioDataId}
                  handleClose={closeModal}
                />
              </div>
            )}
        </>
  );
};

export default AdvertisementList;
