import {
    HeadingGroup,
    ModalLayout,
    OffCanvasLayout,
    SearchFilters,
    Table,
  } from "@wac-ui-dashboard/wac_component_library";
  import React from "react";
  import { FaSort } from "react-icons/fa";
  import usePrivacyList from "./usePrivacyList";
  import TermsAndConditions from "./TermsAndConditions";
  import Style from "./contentList.module.scss";
  import UpdatePrivacy from "./UpdatePrivacy";
  
  const PrivacyList = () => {
    const {
      privacyState,
      isLoading,
      isFetching,
      privacyPolicyList,
      handleCreateClick,
      handleCloseModal,
      closeModal,
      handleEditAction,
      handlePrivacyDataEditClick,
      handleClearClick,
      handleSearch,
      handleSort,
      refetch,
      getRow,
      tableFields,
    } = usePrivacyList();

    return (
      <>
        <HeadingGroup
          title="Settings"
          className={`pro-mb-4 pro-justify-between`}
          handleClick={handleCreateClick}
        />
        <div className="col-auto pro-pt-3 pro-pb-6">
          <SearchFilters
            onSearchInput={handleSearch}
            loading={isLoading}
            SearchIcon={
              <span className="material-symbols-outlined"> search </span>
            }
            searchInputProps={{ value: privacyState?.search }}
            showActions={false}
          />
          
            <Table
              multiSelect={false}
              data={privacyPolicyList?.data?.original?.data || []}
              uniqueID={"_id"}
              fields={tableFields}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              handleSort={handleSort}
              handleEdit={(e) => {
                privacyPolicyList?.data?.original?.data.forEach(item => {
                  if (item?._id === e[0]) {
                      if (item?.input_type === "editor") {
                          handleEditAction(e);
                      } else {
                          handlePrivacyDataEditClick(e);
                      }
                  }
              });
              }}
              getRow={getRow}
              loading={isFetching}
              clear={privacyState?.clearSelection}
              perpage={privacyState?.currentPageSize}
              assignable={false}
              deletable={false}
              editable={privacyPolicyList?.data?.original?.permissions?.edit_permission}
              showCheckBox={privacyPolicyList?.data?.original?.permissions?.edit_permission}
              propStyle={{ proTableWrp: Style.pro_table_wrap }}
            />
         
          <ModalLayout
            show={privacyState.showCreateModal}
            handleClose={handleCloseModal}
            title={`Settings`}
            closeIcon={<span className="material-symbols-outlined x4"> close </span>}
            backdrop="static"
            propStyle={{ root: Style.modal_root }}
          >
            <TermsAndConditions
              handleCloseModal={handleCloseModal}
              refetch={refetch}
            />
          </ModalLayout>
          <OffCanvasLayout
              show={privacyState.showEditNumberTypeModal}
              handleClose={closeModal}
              title={"Update Settings"}
              closeIcon={
                <span className="material-symbols-outlined"> close </span>
              }
            >
              <UpdatePrivacy activeId={privacyState?.selectedId} refetch={refetch} />
            </OffCanvasLayout>
        </div>
      </>
    );
  };
  
  export default PrivacyList;
  