import { getAxiosInstance } from "../../../api";

  export const createNew = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/schedule/save",data);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const getDataById = async (id) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/schedule/edit?_id=${id}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const statusChange = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/admin/schedule/status-change`,data);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
 