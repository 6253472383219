import { getAxiosInstance } from "../../../api";

  export const createNew = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/podcast/save",data);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const getDataById = async (id) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/podcast/edit?_id=${id}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const programDataList = async (queryParams) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("admin/programme/list?", {
        params: queryParams ,
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const contentRatingList = async () => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get("admin/vimeo/get-content-ratings");
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  
 