import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentPage: 1,
  currentFilter: "all",
  currentPageSize: 10,
  startDate: null,
  endDate: null,
  sortBy: "",
  sortOrder: "",
  search: "",
  showCreateModal: false,
  activeId: null,
  currentFormData: [null],
  activeTab: "Details",
  primary_radio_file: "",
  completedTabs: {
    Details: false,
    Uploads: false, 
  },
  CompletedPercentage: {
    Details: 0,
    Uploads: 0,
  },
  isEditData: false, 
  status : "",
  clearSelection: false,
  selectedUpdateRows : [],
  setCurrentFormData : [null],
  fieldName: "",
  cropData: ""
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
    setStoreFormValues: (state, action) => {
      state.status= action.payload.status;
    },
    resetExtraFilters: (state, action) => {
      state.status= '';
    },
  },
});

export const { updateConfig, setStoreFormValues,resetExtraFilters } = categorySlice.actions;

export default categorySlice.reducer;
