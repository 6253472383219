import React from "react";
import useRouteUtils from "../../utils/hooks/useRouteUtils";

const useMediaNews = () => {
  const { checkIfActiveRoute } = useRouteUtils();

  const drawerMenu = [
    {
      title: "Podcast",
      link: "/media",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/media", true),
    },
    {
      title: "Video",
      link: "/media/video",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/media/video", true),
    },
    {
      title: "Shorts",
      link: "/media/shorts",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/media/shorts", true),
    },
    {
      title: "News",
      link: "/media/news",
      icon: <span className="material-symbols-outlined x4"> group </span>,
      active: checkIfActiveRoute("/media/news", true),
    },
  ];

  return {
    drawerMenu,
  };
};

export default useMediaNews;
