import { Header, MenuDrawer } from "@wac-ui-dashboard/wac_component_library";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Assets from "../../../assets/Assets";
import ProtectRoute from "../../../utils/components/ProtectRoute";
import HeaderActions from "./HeaderActions";
import useCommonLayout from "./useCommonLayout";
import Style from './commonLayout.module.scss';

const CommonLayout = ({ children, drawerMenu }) => {
  const { navigations, menuDrawer, handleGoBack } = useCommonLayout();
  return (
    <ProtectRoute>
      <Header
        navigations={navigations}
        Link={Link}
        logo={Assets.HEADERLOGO}
        children={<HeaderActions />}
        propStyle={{ header: Style.header }}
      />
      <div className={`pro-w-100 container-fluid pt-custom`}>
        <div className={`row`}>
          <div className={`col-auto wrapper pro-ps-0 ${Style.MenuDrawer_container}`}>
          {drawerMenu?.length > 0 ? (
            <MenuDrawer
              menus={drawerMenu}
              Link={Link}
              type={`type-2`}
              Nav={Nav}
              icon={
                <span className="material-symbols-outlined ">
                  chevron_right
                </span>
              }
              isGoBack={menuDrawer?.show}
              goBackTitle={menuDrawer?.text}
              goBackIcon={
                <span className="material-symbols-outlined">chevron_left</span>
              }
              handleGoBack={handleGoBack}
            />
            ):""}
          </div>
          <div className={`wrapper-table pro-pt-4 pro-pe-5 pro-ps-5`}>
            {children}
          </div>
        </div>
      </div>
    </ProtectRoute>
  );
};

export default CommonLayout;
