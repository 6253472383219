import { getAxiosInstance } from "../../../api";

export const clientList = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/advertisement/client-list?search`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const campaignList = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/advertisement/campaign-list?client_id=${data}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const exportAllData = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(
        `/admin/advertisement/analytics/export-report`,
        {
          from_date: data?.from_date,
          to_date: data?.to_date,
          type: data?.type,
          ad_campaign_id: data?.ad_campaign_id,
        },
        {
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  