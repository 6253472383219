import { Input, Button } from "@wac-ui-dashboard/wac_component_library";
import { CountryFlag } from "../../../CountryFlag";
import Select from "react-select";
import useUpdateSubAdmin from "./useUpdateSubAdmin";

const UpdateSubAdmin = ({ activeId, refetch, isEditData }) => {
  const { handleCloseModal, formik, isLoading, getFieldError, subAdminList } =
    useUpdateSubAdmin({
      activeId,
      refetch,
      isEditData,
    });

  return (
    <div className={`pro-w-100`}>
      <Input
        label={"Name*"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
        onKeyDown={(e) => {
          const regex = /^[A-Za-z\s]*$/;
          if (!regex.test(e.key)) {
            e.preventDefault();
          }
        }}
        maxLength={32}
      />

      <Input
        label={"Password*"}
        type="text"
        id="password"
        name="password"
        className={`pro-input lg ${getFieldError("password") && " error"}`}
        {...formik.getFieldProps("password")}
        error={getFieldError("password")}
        errorMessage={getFieldError("password")}
      />

      {/* {formik.values.password && ( */}
        <Input
          label={"Confirm Password*"}
          type="text"
          id="confirm_password"
          name="confirm_password"
          className={`pro-input lg ${
            getFieldError("confirm_password") && " error"
          }`}
          {...formik.getFieldProps("confirm_password")}
          error={getFieldError("confirm_password")}
          errorMessage={getFieldError("confirm_password")}
        />
      {/* )} */}

      <div className="input-wrap pro-mb-4 ">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          {`Country Code`}
        </label>
        <Select
          id="country_code"
          options={CountryFlag}
          getOptionLabel={(option) => `${option.dial_code}`}
          getOptionValue={(option) => `${option.dial_code}`}
          className={`pro-input lg ${
            getFieldError("country_code") && " error"
          }`}
          value={CountryFlag?.filter(
            (p) =>
              p.dial_code?.toString() === formik.values.country_code?.toString()
          )}
          classNamePrefix="pro-input"
          onBlur={() => formik.handleBlur("country_code")}
          onChange={(value) =>
            formik.setFieldValue("country_code", value?.dial_code)
          }
        ></Select>
        {getFieldError("country_code") && (
          <span className="error-text">{getFieldError("country_code")}</span>
        )}
      </div>

      <Input
        label={`Mobile Number`}
        type="text"
        id="mobile"
        name="mobile"
        className={`pro-input lg ${getFieldError("mobile") && " error"}`}
        {...formik.getFieldProps("mobile")}
        error={getFieldError("mobile")}
        errorMessage={getFieldError("mobile")}
        onKeyDown={(e) => {
          // Allow backspace (keyCode 8)
          if (e.keyCode === 8) {
            return;
          }

          const regex = /^[0-9]*$/; // Only allows digits
          if (!regex.test(e.key)) {
            e.preventDefault();
          }
        }}
        maxLength={15}
        disabled={formik?.values?.country_code !== "" ? false : true}
      />

      <Input
        label={"Email*"}
        type="text"
        id="email"
        name="email"
        className={`pro-input lg ${getFieldError("email") && " error"}`}
        {...formik.getFieldProps("email")}
        error={getFieldError("email")}
        errorMessage={getFieldError("email")}
      />

<div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Role*
        </label>
        <Select
          id="role_id"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg ${getFieldError("role_id") && " error"}`}
          classNamePrefix="pro-input"
          options={subAdminList}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={subAdminList?.filter(
            (m) => formik.values.role_id?._id === m?._id
          )}
          onBlur={formik.handleBlur("role_id")}
          onChange={(value) => {
            formik.setFieldValue("role_id", value || "");
          }}
        />
        {getFieldError("role_id") && (
          <span className="error-text">{getFieldError("role_id")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4 pro-flex-row pro-items-center">
        <div className="form-check form-switch pro-mb-0">
          <input
            type="checkbox"
            className="form-check-input cursor-pointer"
            id="status"
            name="status"
            checked={Boolean(formik.values?.status) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "status",
                !Boolean(formik.values?.status) ?? false
              );
            }}
          />
        </div>
        <label htmlFor="" className="pro-font-sm pro-lh-base pro-fw-medium">
          Status
        </label>
      </div>

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          disabled={isLoading}
          onClick={formik.handleSubmit}
        >
          {isEditData ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};
export default UpdateSubAdmin;
