import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const Charts = ({ type, labels, datasets, options }) => {

  const chartRef = useRef(null);
  useEffect(() => {
    // Create the chart
    const ctx = chartRef.current.getContext("2d");
    const myChart = new Chart(ctx, {
      type: type,
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: options,
    });

    // Clean up the chart when the component is unmounted
    return () => {
      myChart.destroy();
    };
  }, [type, labels, datasets, options, chartRef]);

  return (
    <div className="pro-w-100">
      <canvas  ref={chartRef}></canvas>
    </div>
  );
};

export default Charts;
