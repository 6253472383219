import { useState } from "react";

const useBarChart = ({ mostActiveTimes }) => {
  const [isSelectedHours, setIsSelectedHours] = useState(true);
  const [isSelectedDays, setIsSelectedDays] = useState(false);

  const mainData = {
    labels: isSelectedHours
      ? mostActiveTimes?.data?.mostActiveTimes?.hours?.labels
      : isSelectedDays
      ? mostActiveTimes?.data?.mostActiveTimes?.days?.labels
      : null,
    datasets: [
      {
        label: "",
        data: isSelectedHours
        ? mostActiveTimes?.data?.mostActiveTimes?.hours?.data
        : isSelectedDays
        ? mostActiveTimes?.data?.mostActiveTimes?.days?.data
        : null,
        backgroundColor: [
          "rgba(245, 158, 11, 1)",
          "rgba(217, 119, 6, 1)",
          "rgba(253, 186, 116, 1)",
        ],
        borderWidth: 0,
        barThickness: 20,
      },
    ],
  };
  const performanceOptions = {
    indexAxis: "x",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderWidth: 1
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
        grid: {
          color: "#D2D1D4",
        },
        border: {
          display: false,
          dash: [4, 4],
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    width: 300,
    height: 300,
    aspectRatio: 1,
  };



  return {
    mainData,
    performanceOptions,
    isSelectedDays,
    isSelectedHours,
    setIsSelectedDays,
    setIsSelectedHours
  };
};

export default useBarChart;
