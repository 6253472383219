import { Button, Input } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useTermsAndConditions from "./useTermsAndConditions";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Style from "./termsConditions.module.scss";

const TermsAndConditions = ({ handleCloseModal, refetch }) => {
  const {
    formik,
    editPrivacyPolicyData,
    handleContentChange,
    getFieldError,
  } = useTermsAndConditions({refetch, handleCloseModal});
  return (
    <div className="pro-p-5">
      <div className="row d-flex">
        <div className="col-12">
          <Input
            label={"Name"}
            type="text"
            id="name"
            name="name"
            readOnly
            className={`pro-input lg ${getFieldError("name") && " error"}`}
            {...formik.getFieldProps("name")}
            error={getFieldError("name")}
            errorMessage={getFieldError("name")}
          />
          <div className={Style.editor_container}>
            <Editor
              wrapperClassName={Style.pro_editor_wrapper}
              editorClassName={Style.pro_editor_main}
              toolbarClassName={Style.pro_editor_toolbar}
              editorState={formik?.values?.value}
              readOnly={editPrivacyPolicyData?.value === "" ? true : false}
              onEditorStateChange={handleContentChange}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                ],
                blockType: {
                  inDropdown: true,
                  className: Style.dropdown_wrapper,
                  dropdownClassName: Style.dropdown_menu,
                },
                fontFamily: {
                  options: ["Vinyl OT", "Neutraface Text Book"],
                  default: "sans-serif", // Set default font family
                },
              }}
              customStyleMap={{
                fontFamily: {
                  fontFamily: "Neutraface Text Book",
                },
              }}
            />
            {formik?.touched?.value && formik?.errors?.value && (
              <span className={Style.error_text}>
                {formik?.errors?.value}
              </span>
            )}{" "}
          </div>
        </div>
      </div>
      <div className="pro-d-flex pro-justify-end pro-pt-4">
        <button
          className={"pro-btn-link lg "}
          onClick={() => handleCloseModal()}
          type="button"
        >
          Cancel
        </button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default TermsAndConditions;
