import { getAxiosInstance } from "../../../api";

export const categoryDataList = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get("admin/video/basic-data");
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const createNew = async (data) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/video/save", data);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const getDataById = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`admin/video/edit?_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};
export const programDataList = async () => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get("admin/programme/list", {
      params: { type: "1" },
    });
    return response;
  } catch (error) {
    return error.response.data;
  }
};


