import {
  Button,
  IconText,
  Image,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import useUploads from "./useUploads";
import { limitStrLength } from "../../../../utils/functions/table";
import Style from "../mediaVideoForm.module.scss";
import { ProfileCard } from "../../../Global/ProfileCard";

const Uploads = ({
  getFieldError,
  isEditData,
  isLoading,
  formik,
  profilefileInputRef,
  handleListThumbnailImage,
  handleTitleImage,
  handleBannerImage,
  handleBasicThumnailImage,
  imageCoverPreview,
  imageTitleImagePreview,
  imageBannerPreview,
  basicThumbnailImageCoverPreview,
  handlevideo,
  show,
  closeModal,
  fieldName,
  setShow,
  setImageCoverPreview,
  setImageTitleImagePreview,
  setImageBannerPreview,
  setBasicThumbnailImageCoverPreview,
  cropData,
  formikImage,
  setFormikImage,
  currentFormData,
  handleClick,
  setImageVerticalThumbnailPreview,
  handleVerticalImage,
  imageVerticalThumbnailPreview
}) => {
  const { handleCloseModal } = useUploads({
    formik,
  });

  return (
    <div className={`pro-w-100`}>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Title Image [3:2]*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".png"
            ref={profilefileInputRef}
            className={`pro-input lg ${
              getFieldError("title_image") && " error"
            }`}
            id="title_image"
            name="title_image"
            onBlur={formik.handleBlur("title_image")}
            onChange={(e) => handleTitleImage(e)}
            onClick={handleClick}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.title_image?.name !== undefined &&
                isEditData &&
                formik?.values?.title_image?.name
                  ? limitStrLength(formik?.values?.title_image?.name, 30)
                  : isEditData && formik?.values?.title_image
                  ? limitStrLength(formik?.values?.title_image ?? "", 30)
                  : !isEditData && formik?.values?.title_image?.name
                  ? limitStrLength(formik?.values?.title_image?.name ?? "", 30)
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {getFieldError("title_image") && (
            <span className="error-text">{getFieldError("title_image")}</span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs pro-mt-1">(file of type: png.)</p>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageTitleImagePreview}
            alt={`title_image`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Banner Image [4:5]*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input lg ${
              getFieldError("banner_image") && " error"
            }`}
            id="banner_image"
            name="banner_image"
            onBlur={formik.handleBlur("banner_image")}
            onChange={(e) => handleBannerImage(e)}
            onClick={handleClick}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.banner_image?.name !== undefined &&
                isEditData &&
                formik?.values?.banner_image?.name
                  ? limitStrLength(formik?.values?.banner_image?.name, 30)
                  : isEditData && formik?.values?.banner_image
                  ? limitStrLength(formik?.values?.banner_image ?? "", 30)
                  : !isEditData && formik?.values?.banner_image?.name
                  ? limitStrLength(formik?.values?.banner_image?.name ?? "", 30)
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {getFieldError("banner_image") && (
            <span className="error-text">{getFieldError("banner_image")}</span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs pro-mt-1">
          (file of type: jpeg, png, jpg, gif.)
        </p>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageBannerPreview}
            alt={`banner_image`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          List Thumbnail [16:9]*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input lg ${
              getFieldError("list_thumbnail") && " error"
            }`}
            id="list_thumbnail"
            name="list_thumbnail"
            onBlur={formik.handleBlur("list_thumbnail")}
            onChange={(e) => handleListThumbnailImage(e)}
            onClick={handleClick}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.list_thumbnail?.name !== undefined &&
                isEditData &&
                formik?.values?.list_thumbnail?.name
                  ? limitStrLength(formik?.values?.list_thumbnail?.name, 30)
                  : isEditData && formik?.values?.list_thumbnail
                  ? limitStrLength(formik?.values?.list_thumbnail ?? "", 30)
                  : !isEditData && formik?.values?.list_thumbnail?.name
                  ? limitStrLength(
                      formik?.values?.list_thumbnail?.name ?? "",
                      30
                    )
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {getFieldError("list_thumbnail") && (
            <span className="error-text">
              {getFieldError("list_thumbnail")}
            </span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs pro-mt-1">
          (file of type: jpeg, png, jpg, gif.)
        </p>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageCoverPreview}
            alt={`list_thumbnail`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Basic Thumbnail [1:1]*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input lg ${
              getFieldError("basic_thumbnail") && " error"
            }`}
            id="basic_thumbnail"
            name="basic_thumbnail"
            onBlur={formik.handleBlur("basic_thumbnail")}
            onChange={(e) => handleBasicThumnailImage(e)}
            onClick={handleClick}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.basic_thumbnail?.name !== undefined &&
                isEditData &&
                formik?.values?.basic_thumbnail?.name
                  ? limitStrLength(formik?.values?.basic_thumbnail?.name, 30)
                  : isEditData && formik?.values?.basic_thumbnail
                  ? limitStrLength(formik?.values?.basic_thumbnail ?? "", 30)
                  : !isEditData && formik?.values?.basic_thumbnail?.name
                  ? limitStrLength(
                      formik?.values?.basic_thumbnail?.name ?? "",
                      30
                    )
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {getFieldError("basic_thumbnail") && (
            <span className="error-text">
              {getFieldError("basic_thumbnail")}
            </span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs pro-mt-1">
          (file of type: jpeg, png, jpg, gif.)
        </p>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={basicThumbnailImageCoverPreview}
            alt={`basic_thumbnail`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Vertical Thumbnail [3:4]*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            ref={profilefileInputRef}
            className={`pro-input lg ${
              getFieldError("vertical_thumbnail") && " error"
            }`}
            id="vertical_thumbnail"
            name="vertical_thumbnail"
            onBlur={formik.handleBlur("vertical_thumbnail")}
            onChange={(e) => handleVerticalImage(e)}
            onClick={handleClick}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.vertical_thumbnail?.name ||
                (formik?.values?.vertical_thumbnail?.[0]?.name !== undefined &&
                  isEditData &&
                  formik?.values?.vertical_thumbnail?.name) ||
                formik?.values?.vertical_thumbnail?.[0]?.name
                  ? limitStrLength(
                      formik?.values?.vertical_thumbnail?.name ||
                        formik?.values?.vertical_thumbnail?.[0]?.name,
                      30
                    )
                  : isEditData && formik?.values?.vertical_thumbnail
                  ? limitStrLength(formik?.values?.vertical_thumbnail ?? "", 30)
                  : (!isEditData && formik?.values?.vertical_thumbnail?.name) ||
                    formik?.values?.vertical_thumbnail?.[0]?.name
                  ? limitStrLength(
                      (formik?.values?.vertical_thumbnail?.name ||
                        formik?.values?.vertical_thumbnail?.[0]?.name) ??
                        "",
                      30
                    )
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {getFieldError("vertical_thumbnail") && (
            <span className="error-text">{getFieldError("vertical_thumbnail")}</span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs pro-mt-1">(file of type: jpeg, png, jpg, gif.)</p>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageVerticalThumbnailPreview}
            alt={`vertical_thumbnail`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Video*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".mp4"
            ref={profilefileInputRef}
            className={`pro-input ${
              formik.errors.video && formik.touched.video && " error"
            }`}
            id="video"
            name="video"
            onBlur={formik.handleBlur("video")}
            onChange={(e) => handlevideo(e)}
          />
          <span className="input-drag-box">
            {/* <IconText
              title={
                formik?.values?.video?.[0]?.name !== undefined &&
                isEditData &&
                formik?.values?.video?.[0]?.name
                  ? limitStrLength(
                      formik?.values?.video?.[0]?.name,
                      30
                    )
                  : isEditData && formik?.values?.video
                  ? limitStrLength(formik?.values?.video ?? "", 30)
                  : !isEditData && formik?.values?.video?.[0]?.name
                  ? limitStrLength(
                      formik?.values?.video?.[0]?.name ?? "",
                      30
                    )
                  : `Drop files to attach or browse`
              }
            /> */}
            <IconText
              title={
                isEditData && currentFormData?.is_converted !== 1 ? (
                  <span className="pro-text-primary">Processing</span>
                ) : formik?.values?.video?.[0]?.name !== undefined &&
                  isEditData &&
                  formik?.values?.video?.[0]?.name ? (
                  limitStrLength(formik?.values?.video?.[0]?.name, 30)
                ) : isEditData && formik?.values?.video ? (
                  limitStrLength(formik?.values?.video ?? "", 30)
                ) : !isEditData && formik?.values?.video?.[0]?.name ? (
                  limitStrLength(formik?.values?.video?.[0]?.name ?? "", 30)
                ) : (
                  "Drop files to attach or browse"
                )
              }
            />
          </span>
          {formik.touched.video && formik.errors.video && (
            <span className="error-text">{formik.errors.video}</span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs pro-mt-1">
          (file of type: mp4, ogx, oga, ogv, ogg, webm, mkv, mov.)
        </p>
      </div>

      {formikImage && (
        <ModalLayout backdrop="static" show={show} handleClose={closeModal}>
          <ProfileCard
            setShow={setShow}
            fieldName={fieldName}
            formik={formik}
            data={formikImage}
            handleClose={closeModal}
            setImageCoverPreview={setImageCoverPreview}
            setImageTitleImagePreview={setImageTitleImagePreview}
            setImageVerticalThumbnailPreview={setImageVerticalThumbnailPreview}
            setImageBannerPreview={setImageBannerPreview}
            setBasicThumbnailImageCoverPreview={
              setBasicThumbnailImageCoverPreview
            }
            cropData={cropData}
            setFormikImage={setFormikImage}
          />
        </ModalLayout>
      )}

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          type="submit"
          disabled={isLoading}
        >
          {isEditData ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};
export default Uploads;
