import Charts from "../../Global/Charts";
import Style from "../HomeDashboardListing/dashboardListing.module.scss";
import useBarChart from "./useBarChart";

const BarChart = ({ dashboardData }) => {
  const {
    performanceOptions,
    mainData,
    isSelectedDays,
    isSelectedHours,
    setIsSelectedDays,
    setIsSelectedHours
  } = useBarChart({ dashboardData });

  return (
    <>
      <div className="pro-d-flex pro-gap-2 pro-justify-between pro-items-center">
        <h6 className="pro-ttl h6">Most Active Times</h6>
        <ul className="pro-d-flex pro-gap-5 pro-mb-3">
          <li
            className={`${Style.tab_btn} ${isSelectedHours && Style.active}`}
            onClick={() => {
              setIsSelectedHours(true);
              setIsSelectedDays(false);
            }}
          >
            Hours
          </li>
          <li
            className={`${Style.tab_btn} ${isSelectedDays && Style.active}`}
            onClick={() => {
              setIsSelectedDays(true);
              setIsSelectedHours(false);
            }}
          >
            Days
          </li>
        </ul>
      </div>
      {isSelectedHours && (
        <div className={Style.bar_chart_wrap}>
          <Charts
            type="bar"
            labels={mainData?.labels}
            datasets={mainData?.datasets}
            options={performanceOptions}
          />
        </div>
      )}
      {isSelectedDays && (
        <div className={Style.bar_chart_wrap}>
          <Charts
            type="bar"
            labels={mainData?.labels}
            datasets={mainData?.datasets}
            options={performanceOptions}
          />
        </div>
      )}
    </>
  );
};

export default BarChart;