import { useFormik } from "formik";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import draftToHtml from "draftjs-to-html";
import DOMPurify from "dompurify";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import { updateConfig } from "../../../../store/slices/Privacy/privacySlice";
import { updatePrivacySettings } from "../api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import htmlToDraft from "html-to-draftjs";


const useTermsAndConditions = ({refetch, handleCloseModal}) => {
  const { editPrivacyPolicyData, isEdit, selectedId } = useSelector(
    (state) => state.privacy
  );
  const dispatch = useDispatch();

  const validation = Yup.object({
    name: Yup.string().trim().required("Enter Name"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      value:"",
    },
    validationSchema: validation,
    enableReinitialize: true,
    validate: (values) => {
      const errors = {};

      // Convert editor content to plain text
      const plainText = values?.value?.getCurrentContent().getPlainText();

      // Check if the content is empty or only contains whitespace
      if (!plainText?.trim()) {
        errors.value = "*value cannot be empty";
      }

      return errors;
    },
    onSubmit: (values) => {
      const valueContentState = values?.value?.getCurrentContent();
      const valueContentRaw = convertToRaw(valueContentState ?? "");
      const valuePlainText = draftToHtml(valueContentRaw ?? "");

      let obj = {
        name: values?.name,
        value: valuePlainText,
      };

      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });
      if (isEdit) {
        formData.append("_method", "PUT");
        updatePrivacySettings({ _id: selectedId, ...obj }).then(
          (response) => {
            if (response?.data?.status_code === 200) {
              toast.success(`Policies and terms updated successfully`);
              refetch();
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              handleCloseModal();
            } else {
              toast.error(`Something went wrong!`);
              dispatch(
                updateConfig((state) => {
                  state.clearSelection = true;
                })
              );
              handleCloseModal();
            }
          }
        );
      }
    },
  });
  
  useEffect(() => {
    if (editPrivacyPolicyData && isEdit) {
      
          const htmlContent = editPrivacyPolicyData?.value?.replace(/\\/g, "");
          const sanitizedHtml = DOMPurify.sanitize(htmlContent);

          // Convert HTML to ContentState using htmlToDraft
          const contentBlock = htmlToDraft(sanitizedHtml);
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );

          // Create an EditorState with the converted ContentState
          const editorState = EditorState.createWithContent(contentState);
          formik.setFieldValue("value", editorState);
          formik.setFieldValue("name",editPrivacyPolicyData?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPrivacyPolicyData]);

  const handleContentChange = (editorsState) => {
    formik.setFieldValue("value", editorsState);
  };

  const getFieldError = (fieldName) => {
    if (formik.touched[fieldName] && formik.errors[fieldName]) {
      return formik.errors[fieldName];
    }
    return "";
  };

  return {
    formik,
    editPrivacyPolicyData,
    handleContentChange,
    getFieldError,
  };
};

export default useTermsAndConditions;
