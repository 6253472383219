import { FormTabs } from "@wac-ui-dashboard/wac_component_library";
import useUpdatePodcast from "./useUpdatePodcast";
import Style from "./podcastUpdationForm.module.scss";

const UpdatePodcast = ({ activeId, refetch, isEditData }) => {
  const {
    tabs,
    activeTab,
    handleTabClick,
    handleMoveToErrorTab,
    renderTabContent,
    formik,
  } = useUpdatePodcast({
    activeId,
    refetch,
    isEditData,
  });

  return (
    <>
      <FormTabs
        propStyle={{ root: Style.FormTabs_root }}
        tabs={tabs}
        active={activeTab}
        handleClick={handleTabClick}
      />
      <form
        onSubmit={(e) => {
          formik.handleSubmit(e);
          handleMoveToErrorTab();
        }}
      >
        {renderTabContent()}
      </form>
    </>
  );
};
export default UpdatePodcast;
