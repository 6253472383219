import moment from "moment";

export const getFormatedDate = (date) => {
  return moment(new Date(date)).format("DD-MM-YYYY");
};

export const phoneRegExp = /^(0*[1-9][0-9]*(\.[0-9]*)?|0*\.[0-9]*[1-9][0-9]*)$/;

export const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  export const limitStrLength = (text, max_length) => {
    if (text.length > max_length - 3) {
      return text.substring(0, max_length).trimEnd() + "...";
    } else {
      return text;
    }
  };

  export const getFormatedDateRange = (date) => {
    return moment(new Date(date)).format("DD MMM YYYY");
  };
  