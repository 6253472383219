import { getAxiosInstance } from "../../api";

export const login = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("admin/login", params);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const forgotPassword = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/forgot-password", params);

    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const resetPassword = async (params, username) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/reset-password", {
      password: params.password,
      confirm_password: params.confirm_password,
      user_name: username,
    });
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const validateOTP = async (params,code, otp) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/submit-otp", {
      user_name: params,
      country_code: code,
      otp: otp,
    });
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const emailOrPhone = async (params, code) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/forgot-password", {
      user_name: params,
      country_code: code,
    });
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const resendOtp = async (params, code) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post("/admin/resend-otp", {
      user_name: params,
      country_code: code,
    });
    return response;
  } catch (error) {
    return error.response.data;
  }
};
