import { HeadingGroup, Input } from "@wac-ui-dashboard/wac_component_library";
import useAddRole from "./useAddRole";
import Style from "./addRole.module.scss";
import { Permissions } from "../../Global/Permissions";
const AddRole = () => {
  const {
    state,
    role,
    formik,
    getFieldError,
    handlePermissionChange,
    handleSingleMenuChange,
  } = useAddRole();

  return (
    <>
      <HeadingGroup
        title={"Role Management"}
        buttonTitle={"Save"}
        handleClick={formik?.handleSubmit}
        extraClassName={`pro-justify-between pro-pb-4`}
        buttonProps={{ type: "submit" }}
      />
      <div className={`row`}>
        <div className="col">
          <Input
            label={"Name"}
            type="text"
            id="name"
            name="name"
            className={`pro-input lg ${getFieldError("name") && " error"}`}
            {...formik.getFieldProps("name")}
            error={getFieldError("name")}
            errorMessage={getFieldError("name")}
          />
        </div>
      </div>
      <div className="pro-pb-6">
        {(role ? state.rolePermission?.[role] : true) &&
          state?.allPermissions?.menu_permission?.map?.(
            (permission, index) => (
              <div className={Style.root} key={index}>
                <Permissions
                  title={permission?.menu_name}
                  permissions={permission?.submenu_permission}
                  defaultCheckedList={
                    state.rolePermission?.[role]?.sub_menu_permissions
                  }
                  menu_id={permission.menu_id}
                  key={index}
                  uniqueID={"sub_menu_id"}
                  singleID={permission?._id}
                  handleChange={(item) =>
                    handlePermissionChange(item, permission?.menu_id)
                  }
                  handleSingleChange={(item) =>
                    handleSingleMenuChange(item, permission?.menu_id)
                  }
                />
              </div>
            )
          )}
      </div>
    </>
  );
};

export default AddRole;
