import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  resetExtraFilters,
  setStoreFormValues,
} from "../../../store/slices/Advertisement/advertisementSlice";
import AdvertisementFilterForm from "./AdvertisementFilterForm";
import { categoryDataList } from "../../Advertisement/UpdateAdvertisement/api";

const useFilter = ({ setShowform, refetch }) => {
  const dispatch = useDispatch();
  const [addType, setAddType] = useState([]);
  const state = useSelector((state) => state.advertisement);

  const validationSchema = Yup.object({
    status: Yup.string().nullable(),
    category_id: Yup.array().nullable(),
    type: Yup.string().nullable(),
  });

  const initialValues = {
    status: state.status,
    category_id: state.category_id,
    type: state.type,
  };

  useEffect(() => {
    categoryDataList().then((response) => {
      setAddType(response?.data?.data?.type);
    });
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { status, category_id, type } = values;
      if (status === "" && category_id?.length === 0 && type === "") {
        toast.error("All fields are empty!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setShowform(false);
      }
      let data = {
        status,
        category_id,
        type,
      };
      if (values) {
        dispatch(setStoreFormValues(values));
      }
    },
  });

  useEffect(() => {
    // Check if status is not empty before refetching
    if (formik.values.status !== "") {
      refetch();
    }
  }, [formik.values.status, refetch]);

  const renderTabContent = () => {
    return (
      <AdvertisementFilterForm
        formik={formik}
        handleReset={handleReset}
        addType={addType}
      />
    );
  };

  const handleReset = () => {
    dispatch(resetExtraFilters());
    formik.resetForm();
    setShowform(false);
  };

  return {
    renderTabContent,
  };
};

export default useFilter;
