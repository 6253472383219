import { getAxiosInstance } from "../../../api";

  export const createNew = async (data) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("admin/category/save",data);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
  export const getDataById = async (id) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`admin/category/edit?_id=${id}`);
      return response;
    } catch (error) {
      return error.response.data;
    }
  };
 
  