import {
  Button,
  IconText,
  Image,
  ModalLayout,
} from "@wac-ui-dashboard/wac_component_library";
import useUploads from "./useUploads";
import { limitStrLength } from "../../../../utils/functions/table";
import Style from "../mediaNewsForm.module.scss";
import { ProfileCard } from "../../../Global/ProfileCard";

const Uploads = ({
  getFieldError,
  isEditData,
  formik,
  isLoading,
  profilefileInputRef,
  imageCoverPreview,
  handleCoverImage,
  handleBasicThumbnail,
  basicThumbnailImageCoverPreview,
  handleSong,
  show,
  closeModal,
  fieldName,
  setShow,
  setImageCoverPreview,
  setBasicThumbnailImageCoverPreview,
  cropData,
  formikImage,
  setFormikImage,
  currentFormData,
  handleClick,
}) => {
  const { handleCloseModal } = useUploads({
    formik,
  });

  return (
    <div className={`pro-w-100`}>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          List Thumbnail [16:9]*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input lg ${
              getFieldError("list_thumbnail") && " error"
            }`}
            id="list_thumbnail"
            name="list_thumbnail"
            onBlur={formik.handleBlur("list_thumbnail")}
            onChange={(e) => handleCoverImage(e)}
            onClick={handleClick}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.list_thumbnail?.name !== undefined &&
                isEditData &&
                formik?.values?.list_thumbnail?.name
                  ? limitStrLength(
                      formik?.values?.list_thumbnail?.name,
                      30
                    )
                  : isEditData
                  ? limitStrLength(formik?.values?.list_thumbnail ?? "", 30)
                  : !isEditData && formik?.values?.list_thumbnail?.name
                  ? limitStrLength(
                      formik?.values?.list_thumbnail?.name ?? "",
                      30
                    )
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {getFieldError("list_thumbnail") && (
            <span className="error-text">
              {getFieldError("list_thumbnail")}
            </span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs  pro-mt-1">(file of type: jpeg, png, jpg, gif.)</p>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={imageCoverPreview}
            alt={`list_thumbnail`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Basic Thumbnail [1:1]*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".jpeg, .jpg , .png"
            ref={profilefileInputRef}
            className={`pro-input lg ${
              getFieldError("basic_thumbnail") && " error"
            }`}
            id="basic_thumbnail"
            name="basic_thumbnail"
            onBlur={formik.handleBlur("basic_thumbnail")}
            onChange={(e) => handleBasicThumbnail(e)}
            onClick={handleClick}
          />
          <span className="input-drag-box">
            <IconText
              title={
                formik?.values?.basic_thumbnail?.name !== undefined &&
                isEditData &&
                formik?.values?.basic_thumbnail?.name
                  ? limitStrLength(
                      formik?.values?.basic_thumbnail?.name,
                      30
                    )
                  : isEditData
                  ? limitStrLength(formik?.values?.basic_thumbnail ?? "", 30)
                  : !isEditData && formik?.values?.basic_thumbnail?.name
                  ? limitStrLength(
                      formik?.values?.basic_thumbnail?.name ?? "",
                      30
                    )
                  : `Drop files to attach or browse`
              }
            />
          </span>
          {getFieldError("basic_thumbnail") && (
            <span className="error-text">
              {getFieldError("basic_thumbnail")}
            </span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs  pro-mt-1">(file of type: jpeg, png, jpg, gif.)</p>
      </div>
      <div className={`col-2 pro-my-2`}>
        <div className={`${Style.root_image_inner}`}>
          <Image
            width={100}
            height={100}
            src={basicThumbnailImageCoverPreview}
            alt={`basic_thumbnail`}
          />
        </div>
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className={`pro-font-sm pro-mb-1 pro-fw-medium`}>
          Audio*
        </label>
        <div className="input-drag">
          <input
            type="file"
            placeholder="placeholder"
            accept=".mp3, .wav"
            ref={profilefileInputRef}
            className={`pro-input lg ${getFieldError("audio") && " error"}`}
            id="audio"
            name="audio"
            onBlur={formik.handleBlur("audio")}
            onChange={(e) => handleSong(e)}
          />
          <span className="input-drag-box">
            {/* <IconText
              title={
                formik?.values?.audio?.[0]?.name !== undefined &&
                isEditData && formik?.values?.audio?.[0]?.name 
                  ? limitStrLength(formik?.values?.audio?.[0]?.name, 30)
                  : isEditData ? limitStrLength(formik?.values?.audio ?? "", 30): 
                    !isEditData && formik?.values?.audio?.[0]?.name
                  ? limitStrLength(formik?.values?.audio?.[0]?.name ?? "", 30)
                  : `Drop files to attach or browse`
              }
            /> */}
            <IconText
              title={
                isEditData && currentFormData?.is_converted !== 1
                  ? <span className="pro-text-primary">Processing</span>
                  : formik?.values?.audio?.[0]?.name !== undefined &&
                    isEditData &&
                    formik?.values?.audio?.[0]?.name
                  ? limitStrLength(formik?.values?.audio?.[0]?.name, 30)
                  : isEditData && formik?.values?.audio
                  ? limitStrLength(formik?.values?.audio ?? "", 30)
                  : !isEditData && formik?.values?.audio?.[0]?.name
                  ? limitStrLength(formik?.values?.audio?.[0]?.name ?? "", 30)
                  : "Drop files to attach or browse"
              }
            />
          </span>
          {getFieldError("audio") && (
            <span className="error-text">
              {getFieldError("audio")}
            </span>
          )}
        </div>
        <p className="pro-mb-0 pro-font-xs  pro-mt-1">(file of type: mp3, wav)</p>
      </div>

      {formikImage && (
        <ModalLayout backdrop="static" show={show} handleClose={closeModal}>
          <ProfileCard
            setShow={setShow}
            fieldName={fieldName}
            formik={formik}
            data={formikImage}
            handleClose={closeModal}
            setImageCoverPreview={setImageCoverPreview}
            setBasicThumbnailImageCoverPreview={setBasicThumbnailImageCoverPreview}
            cropData={cropData}
            setFormikImage={setFormikImage}
          />
        </ModalLayout>
      )}

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
          type="button"
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          type="submit"
          disabled={isLoading}
        >
          {isEditData ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};
export default Uploads;
