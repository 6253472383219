import { Input, Button } from "@wac-ui-dashboard/wac_component_library";
import useUpdateCampaign from "./useUpdateCampaign";
import Select from "react-select";
import { format } from "date-fns";

const UpdateCampaign = ({ activeId, refetch, isEditData }) => {
  const {
    handleCloseModal,
    formik,
    isLoading,
    getFieldError,
    clientsList,
    PriorityLists,
    countryLists,
    stateLists,
    cityLists,
    minDate,
    dateStr,
    dateStr1,
  } = useUpdateCampaign({
    activeId,
    refetch,
    isEditData,
  });

  return (
    <div className={`pro-w-100`}>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Client*
        </label>
        <Select
          id="client_id"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg ${getFieldError("client_id") && " error"}`}
          classNamePrefix="pro-input"
          options={clientsList?.data}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={clientsList?.data?.filter(
            (m) => formik.values.client_id?._id === m?._id
          )}
          onBlur={formik.handleBlur("client_id")}
          onChange={(value) => {
            formik.setFieldValue("client_id", value || null);
          }}
        />
        {getFieldError("client_id") && (
          <span className="error-text">{getFieldError("client_id")}</span>
        )}
      </div>

      <Input
        label={"Name*"}
        type="text"
        id="name"
        name="name"
        className={`pro-input lg ${getFieldError("name") && " error"}`}
        {...formik.getFieldProps("name")}
        error={getFieldError("name")}
        errorMessage={getFieldError("name")}
        onKeyDown={(e) => {
          const regex = /^[A-Za-z\s]*$/;
          if (!regex.test(e.key)) {
            e.preventDefault();
          }
        }}
        maxLength={32}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Start date*
        </label>
        <Input
          // className={`pro-input lg`}
          type="date"
          name="start_date"
          className={`pro-input lg ${getFieldError("start_date") && " error"}`}
          min={minDate}
          value={formik.values.start_date === "" ? "dd-MM-yyyy" : dateStr}
          onChange={(e) =>
            formik.setFieldValue(
              "start_date",
              format(new Date(e.target.value), "dd-MM-yyyy")
            )
          }
          onBlur={formik.handleBlur("start_date")}
          error={getFieldError("start_date")}
          errorMessage={getFieldError("start_date")}
        />
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          End date*
        </label>
        <Input
          // className={`pro-input lg`}
          type="date"
          name="end_date"
          className={`pro-input lg ${getFieldError("end_date") && " error"}`}
          min={minDate}
          value={formik.values.end_date === "" ? "dd-MM-yyyy" : dateStr1}
          onChange={(e) =>
            formik.setFieldValue(
              "end_date",
              format(new Date(e.target.value), "dd-MM-yyyy")
            )
          }
          onBlur={formik.handleBlur("end_date")}
          error={getFieldError("end_date")}
          errorMessage={getFieldError("end_date")}
        />
      </div>

      <Input
        label={"Ad Budget"}
        type="text"
        id="ad_budget"
        name="ad_budget"
        className={`pro-input lg ${getFieldError("ad_budget") && " error"}`}
        {...formik.getFieldProps("ad_budget")}
        error={getFieldError("ad_budget")}
        errorMessage={getFieldError("ad_budget")}
        onKeyDown={(e) => {
          // Allow backspace (keyCode 8)
          if (e.keyCode === 8) {
            return;
          }

          const regex = /^[0-9]*$/; // Only allows digits
          if (!regex.test(e.key)) {
            e.preventDefault();
          }
        }}
        maxLength={6}
      />

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Country*
        </label>
        <Select
          id="target_countries"
          placeholder={"Select"}
          // className={"pro-input lg"}
          className={`pro-input lg ${
            getFieldError("target_countries") && " error"
          }`}
          classNamePrefix="pro-input"
          name="target_countries"
          options={countryLists}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={countryLists?.find((m) =>
            formik.values.target_countries?.includes(m?.iso2)
          )}
          onChange={(value) => {
            formik.setFieldValue("target_countries", value ? [value.iso2] : []);
          }}
        />
        {getFieldError("target_countries") && (
          <span className="error-text">
            {getFieldError("target_countries")}
          </span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          State*
        </label>
        <Select
          id="target_states"
          placeholder={"Select"}
          // className={"pro-input lg"}
          className={`pro-input lg ${
            getFieldError("target_states") && " error"
          }`}
          classNamePrefix="pro-input"
          name="target_states"
          options={stateLists}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          // value={stateLists?.filter(
          //   (m) => formik.values.target_states[0] === m?.state_code
          // )}
          value={
            stateLists
              ? stateLists.filter(
                  (m) => formik.values.target_states?.[0] === m?.state_code
                )
              : []
          }
          onChange={(value) => {
            formik.setFieldValue("target_states", [value?.state_code] || null);
          }}
        />
        {getFieldError("target_states") && (
          <span className="error-text">{getFieldError("target_states")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          City*
        </label>
        <Select
          id="target_cities"
          placeholder={"Select"}
          className={`pro-input lg ${
            getFieldError("target_cities") && " error"
          }`}
          classNamePrefix="pro-input"
          isMulti
          name="target_cities"
          options={cityLists}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={cityLists?.filter((m) =>
            formik.values.target_cities?.includes?.(m?.name)
          )}
          onChange={(value) =>
            formik.setFieldValue(
              "target_cities",
              value.map((v) => v?.name)
            )
          }
        />

        {getFieldError("target_cities") && (
          <span className="error-text">{getFieldError("target_cities")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Priority*
        </label>
        <Select
          id="priority"
          isClearable={true}
          placeholder={"Select"}
          className={`pro-input lg ${getFieldError("priority") && " error"}`}
          classNamePrefix="pro-input"
          options={PriorityLists?.data?.ad_priority_types}
          getOptionValue={(option) => option?._id}
          getOptionLabel={(option) => option?.name}
          value={PriorityLists?.data?.ad_priority_types?.filter(
            (m) => formik.values.priority === m?._id
          )}
          onBlur={formik.handleBlur("priority")}
          onChange={(value) => {
            formik.setFieldValue("priority", value?._id || null);
          }}
        />
        {getFieldError("priority") && (
          <span className="error-text">{getFieldError("priority")}</span>
        )}
      </div>

      <div className="input-wrap pro-mb-4 pro-flex-row pro-items-center">
        <div className="form-check form-switch pro-mb-0">
          <input
            type="checkbox"
            className="form-check-input cursor-pointer"
            id="status"
            name="status"
            checked={Boolean(formik.values?.status) ?? false}
            onChange={(e) => {
              formik.setFieldValue(
                "status",
                !Boolean(formik.values?.status) ?? false
              );
            }}
          />
        </div>
        <label htmlFor="" className="pro-font-sm pro-fw-medium pro-lh-base">
          Status
        </label>
      </div>

      <div className="col-12 pro-mt-4 pro-d-flex pro-justify-end">
        <Button
          className={"pro-btn-link lg pro-px-5"}
          onClick={() => handleCloseModal()}
        >
          Cancel
        </Button>

        <Button
          className={"pro-btn-primary lg pro-mx-3"}
          disabled={isLoading}
          onClick={formik.handleSubmit}
        >
          {isEditData ? "Update" : "Create"}
        </Button>
      </div>
    </div>
  );
};
export default UpdateCampaign;
