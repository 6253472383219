import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key] === "status") {
        return `${key}=${parseInt(params[key])}&`;
      } else if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    try {
      const response = await api[method](
        `${endpoint}?${getParams("_id")}${getParams("from_date")}${getParams(
          "to_date"
        )}${getParams("status")}${getParams("category_id")}${getParams(
          "sort_by"
        )}sort_order=${params?.sort_order || ""}&page_size=${
          params?.page_size || "10"
        }&${getParams("search")}&page=${params?.page || 1}&${getParams(
          "length"
        )}`,
        body
      );

      return { data: response.data };
    } catch (axiosError) {
      let err = axiosError;

      return {
        data: err.response?.data || err.message,
      };
    }
  };

export const medianews = createApi({
  reducerPath: "medianewsApi",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["Medianews"],
  endpoints: (builder) => ({
    getMediaNewsListData: builder.query({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/news/list`,
      }),
      provideTags: ["Medianews"],
    }),
    getMediaNewsFormDataById: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/news/edit`,
      }),
    }),
    updateMediaNewsListData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/admin/news/update`,
      }),

      invalidatesTags: ["Medianews"],
    }),
    deleteMediaNewsData: builder.mutation({
      query: (values) => ({
        method: "delete",
        params: values,
        endpoint: `/admin/news/delete`,
      }),

      invalidatesTags: ["Medianews"],
    }),
    categoryDataList: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/news/basic-data`,
      }),
    }),
    statusChange: builder.mutation({
      query: (values) => ({
        method: "post",
        params: values,
        endpoint: `/admin/news/status-change`,
      }),
    }),
  }),
});

export const {
  useGetMediaNewsListDataQuery,
  useGetMediaNewsFormDataByIdQuery,
  useUpdateMediaNewsListDataMutation,
  useDeleteMediaNewsDataMutation,
  useCategoryDataListQuery,
  useStatusChangeMutation,
} = medianews;
