import { createApi } from "@reduxjs/toolkit/query/react";
import { getAxiosInstance } from "../../../api";

const axiosBaseQuery =
  () =>
  async ({ params, endpoint, method, body }) => {
    const api = await getAxiosInstance();
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };
    if (method === "get") {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("start")}${getParams("end")}filter=${
            params?.filter || "all"
          }&${getParams("sort_by")}sort_order=${
            params?.sort_order || "dec"
          }&per_page=${params?.page_size || "20"}&${getParams("search")}page=${
            params?.page || ""
          }`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    } else {
      try {
        const response = await api[method](
          `${endpoint}?${getParams("_id")}${getParams("start")}${getParams("end")}${getParams(
            "sort_by"
          )}${getParams("search")}`,
          body
        );
        return { data: response.data };
      } catch (axiosError) {
        let err = axiosError;
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    }
  };

export const role = createApi({
  reducerPath: "roleApi",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getRoleListData: builder.query({
      query: (values) => ({
        method: "get",
        params: values,
        endpoint: `/admin/role/list`,
      }),
      providesTags: ["Role"],
    }),
    // }),
    updateRoleTableHeadData: builder.mutation({
      query: (body) => ({
        method: "post",
        body,
        endpoint: `/user-fields/save`,
      }),

      invalidatesTags: ["Role"],
    }),
    deleteRoleData: builder.mutation({
      query: (values) => ({
        method: "delete",
        params: values,
        endpoint: `/admin/role/delete`,
      }),
      invalidatesTags: ["Role"],
    }),
  }),
});

export const {
  useGetRoleListDataQuery,
  useUpdateRoleTableHeadDataMutation,
  useDeleteRoleDataMutation,
} = role;
