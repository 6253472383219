import { getAxiosInstance } from "../../../api";

export const updatePrivacySettings = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.put(`/admin/settings/app/update`, params);
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getDataById = async (id) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(`admin/site-settings/edit?_id=${id}`);
    return response;
  } catch (error) {
    return error.response.data;
  }
};

